import React from 'react';
import {List, Pagination} from 'react-admin';

const HNPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 100]} {...props} />
);

const HNList: React.FC = ({children, ...props}: any) => (
  <List perPage={100} pagination={<HNPagination />} {...props}>
    {children}
  </List>
);

export default HNList;
