import React from 'react';
import {
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  FormDataConsumer,
  NumberInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { Parameter_Key_Enum } from '../generated/graphql';
import { validatePosition } from '../utils/validation';
import parameterKeys from '../constants/parameterKeys';

interface ParameterKeyFieldRecord {
  key: Parameter_Key_Enum;
}

const ParameterKeyField: React.FC<any> = () => {
  const record = useRecordContext<ParameterKeyFieldRecord>();
  return <span>{record?.key && parameterKeys[record.key]?.name}</span>;
};

const ParameterValueField: React.FC<any> = () => {
  const record = useRecordContext<any>();
  return <span>{record?.not_used ? 'Not set' : record?.value}</span>;
};

const ParameterForm: React.FC<any> = (props) => (
  <HNForm {...props}>
    <FormDataConsumer>
      {({ formData }: { formData: any }) => {
        switch (formData.key) {
          // case Parameter_Key_Enum.CardColor:
          //   return (
          //     <ColorInput
          //       label="Colour"
          //       source="value"
          //       picker="Sketch"
          //       validate={validateColor}
          //     />
          //   );
          case Parameter_Key_Enum.HeaderFontSize:
            return (
              <NumberInput
                source="value"
                parse={(v: number) => `${v}`}
                format={(v: string) => +v}
                validate={validatePosition}
              />
            );
          case Parameter_Key_Enum.CodeVereficationText:
          case Parameter_Key_Enum.SubscriptionReminderText:
          case Parameter_Key_Enum.MyCollectionRemoveDescription:
          case Parameter_Key_Enum.PlayerIntroDescription:
          case Parameter_Key_Enum.FreeTitlesPopupMarkdown:
          case Parameter_Key_Enum.IosSubscriptionMarkdown:
          case Parameter_Key_Enum.AndroidSubscriptionMarkdown:
          case Parameter_Key_Enum.WebsiteSubscriptionAllTitlesText:
          case Parameter_Key_Enum.WebsiteSubscriptionAllTitlesTitle:
          case Parameter_Key_Enum.WebsiteSubscriptionUpgradeText:
          case Parameter_Key_Enum.WebsiteSubscriptionUpgradeTitle:
          case Parameter_Key_Enum.IapSubscriptionUpgradeText:
          case Parameter_Key_Enum.IapSubscriptionUpgradeTitle:
          case Parameter_Key_Enum.LoginAfterIapHeader:
          case Parameter_Key_Enum.LoginAfterIapDescription:
          case Parameter_Key_Enum.DeleteAccountTitle:
          case Parameter_Key_Enum.DeleteAccountDescription:
            return <TextInput source="value" multiline fullWidth />;
          default:
            return <TextInput source="value" fullWidth />;
        }
      }}
    </FormDataConsumer>
    <BooleanInput source="not_used" />
  </HNForm>
);

const ParameterTitle: React.FC = () => {
  const record = useRecordContext<ParameterKeyFieldRecord>();
  return <span>{record && record.key ? `Parameter "${parameterKeys[record.key].name}"` : 'Create Parameter'}</span>;
};

export const ParameterList: React.FC<any> = (props) => (
  <HNList {...props} bulkActionButtons={false}>
    <Datagrid>
      <ParameterKeyField source="key" />
      <ParameterValueField source="value" />
      <EditButton />
    </Datagrid>
  </HNList>
);

export const ParameterEdit: React.FC<any> = (props) => (
  <Edit title={<ParameterTitle />} {...props}>
    <ParameterForm />
  </Edit>
);
