import { Parameter_Key_Enum } from '../generated/graphql';

const parameterKeys: Record<Parameter_Key_Enum, { id: Parameter_Key_Enum; name: string }> = {
  [Parameter_Key_Enum.CardColor]: {
    id: Parameter_Key_Enum.CardColor,
    name: 'My Collection color',
  },
  [Parameter_Key_Enum.LoginConfirmationText]: {
    id: Parameter_Key_Enum.LoginConfirmationText,
    name: 'Login confirmation text',
  },
  [Parameter_Key_Enum.ContactInfoText]: {
    id: Parameter_Key_Enum.ContactInfoText,
    name: 'Contact info text',
  },
  [Parameter_Key_Enum.OnboardingTextSlide_1]: {
    id: Parameter_Key_Enum.OnboardingTextSlide_1,
    name: 'Onboarding text slide 1',
  },
  [Parameter_Key_Enum.OnboardingTextSlide_2]: {
    id: Parameter_Key_Enum.OnboardingTextSlide_2,
    name: 'Onboarding text slide 2',
  },
  [Parameter_Key_Enum.OnboardingTextSlide_3]: {
    id: Parameter_Key_Enum.OnboardingTextSlide_3,
    name: 'Onboarding text slide 3',
  },
  [Parameter_Key_Enum.OnboardingTextSlide_4]: {
    id: Parameter_Key_Enum.OnboardingTextSlide_4,
    name: 'Onboarding text slide 4',
  },
  [Parameter_Key_Enum.OnboardingTextSlide_5]: {
    id: Parameter_Key_Enum.OnboardingTextSlide_5,
    name: 'Onboarding text slide 5',
  },
  [Parameter_Key_Enum.OnboardingDescriptionSlide_1]: {
    id: Parameter_Key_Enum.OnboardingDescriptionSlide_1,
    name: 'Onboarding description slide 1',
  },
  [Parameter_Key_Enum.OnboardingDescriptionSlide_2]: {
    id: Parameter_Key_Enum.OnboardingDescriptionSlide_2,
    name: 'Onboarding description slide 2',
  },
  [Parameter_Key_Enum.OnboardingDescriptionSlide_3]: {
    id: Parameter_Key_Enum.OnboardingDescriptionSlide_3,
    name: 'Onboarding description slide 3',
  },
  [Parameter_Key_Enum.OnboardingDescriptionSlide_4]: {
    id: Parameter_Key_Enum.OnboardingDescriptionSlide_4,
    name: 'Onboarding description slide 4',
  },
  [Parameter_Key_Enum.OnboardingDescriptionSlide_5]: {
    id: Parameter_Key_Enum.OnboardingDescriptionSlide_5,
    name: 'Onboarding description slide 5',
  },
  [Parameter_Key_Enum.HeaderFontSize]: {
    id: Parameter_Key_Enum.HeaderFontSize,
    name: 'Header font size',
  },
  [Parameter_Key_Enum.HeaderText]: {
    id: Parameter_Key_Enum.HeaderText,
    name: 'Header text',
  },
  [Parameter_Key_Enum.MyCollectionRemoveDescription]: {
    id: Parameter_Key_Enum.MyCollectionRemoveDescription,
    name: 'Remove from my collection description',
  },
  [Parameter_Key_Enum.MyCollectionRemoveHeader]: {
    id: Parameter_Key_Enum.MyCollectionRemoveHeader,
    name: 'Remove from my collection header',
  },
  [Parameter_Key_Enum.FeedbackEmail]: {
    id: Parameter_Key_Enum.FeedbackEmail,
    name: 'Email to send feedback to',
  },
  [Parameter_Key_Enum.PurchaseText]: {
    id: Parameter_Key_Enum.PurchaseText,
    name: 'Purchase text',
  },

  [Parameter_Key_Enum.PlayerIntroHeader]: {
    id: Parameter_Key_Enum.PlayerIntroHeader,
    name: 'Player intro header',
  },
  [Parameter_Key_Enum.PlayerIntroDescription]: {
    id: Parameter_Key_Enum.PlayerIntroDescription,
    name: 'Player intro description',
  },
  [Parameter_Key_Enum.ShowPromo]: {
    id: Parameter_Key_Enum.ShowPromo,
    name: 'Show Promo',
  },
  [Parameter_Key_Enum.LoginText]: {
    id: Parameter_Key_Enum.LoginText,
    name: 'Login Text',
  },
  [Parameter_Key_Enum.PadlockIcon]: {
    id: Parameter_Key_Enum.PadlockIcon,
    name: 'Show Padlock Icon',
  },

  [Parameter_Key_Enum.FreeTitlesPopupTitle]: {
    id: Parameter_Key_Enum.FreeTitlesPopupTitle,
    name: 'Free titles title',
  },
  [Parameter_Key_Enum.FreeTitlesPopupMarkdown]: {
    id: Parameter_Key_Enum.FreeTitlesPopupMarkdown,
    name: 'Free titles content',
  },
  [Parameter_Key_Enum.FreeTitlesPopupDuration]: {
    id: Parameter_Key_Enum.FreeTitlesPopupDuration,
    name: 'Delay in sec before showing free titles popup',
  },
  [Parameter_Key_Enum.AndroidSubscriptionMarkdown]: {
    id: Parameter_Key_Enum.AndroidSubscriptionMarkdown,
    name: 'Android Subscription Markdown',
  },
  [Parameter_Key_Enum.IosSubscriptionMarkdown]: {
    id: Parameter_Key_Enum.IosSubscriptionMarkdown,
    name: 'IOS Subscription Markdown',
  },
  [Parameter_Key_Enum.DaysAfterLastDiscount]: {
    id: Parameter_Key_Enum.DaysAfterLastDiscount,
    name: 'Days after previous upgrade popup',
  },
  [Parameter_Key_Enum.SubscriptionReminderText]: {
    id: Parameter_Key_Enum.SubscriptionReminderText,
    name: 'Subscription notification email body($subscription$ is where subscription name will be inserted. $name$ is where username will be inserted)',
  },
  [Parameter_Key_Enum.SubscriptionReminderTitle]: {
    id: Parameter_Key_Enum.SubscriptionReminderTitle,
    name: 'Subscription notification email subject',
  },
  [Parameter_Key_Enum.IapSubscriptionUpgradeText]: {
    id: Parameter_Key_Enum.IapSubscriptionUpgradeText,
    name: 'Subscription upgrade email body($subscription$ is where subscription name will be inserted. $name$ is where username will be inserted)',
  },
  [Parameter_Key_Enum.IapSubscriptionUpgradeTitle]: {
    id: Parameter_Key_Enum.IapSubscriptionUpgradeTitle,
    name: 'Subscription upgrade email subject',
  },
  [Parameter_Key_Enum.WebsiteSubscriptionAllTitlesText]: {
    id: Parameter_Key_Enum.WebsiteSubscriptionAllTitlesText,
    name: 'Website subscription all titles email body',
  },
  [Parameter_Key_Enum.WebsiteSubscriptionAllTitlesTitle]: {
    id: Parameter_Key_Enum.WebsiteSubscriptionAllTitlesTitle,
    name: 'Website subscription all titles email subject',
  },
  [Parameter_Key_Enum.WebsiteSubscriptionUpgradeText]: {
    id: Parameter_Key_Enum.WebsiteSubscriptionUpgradeText,
    name: 'Website subscription non-all titles upgrade email body',
  },
  [Parameter_Key_Enum.WebsiteSubscriptionUpgradeTitle]: {
    id: Parameter_Key_Enum.WebsiteSubscriptionUpgradeTitle,
    name: 'Website subscription non-all titles upgrade email subject',
  },
  [Parameter_Key_Enum.EnableWebsiteSubscriptionEmail]: {
    id: Parameter_Key_Enum.EnableWebsiteSubscriptionEmail,
    name: 'Enable website subscription emails(Y/N)',
  },
  [Parameter_Key_Enum.LoginAfterIapHeader]: {
    id: Parameter_Key_Enum.LoginAfterIapHeader,
    name: 'Login after IAP Header',
  },
  [Parameter_Key_Enum.LoginAfterIapDescription]: {
    id: Parameter_Key_Enum.LoginAfterIapDescription,
    name: 'Login after IAP Description',
  },
  [Parameter_Key_Enum.CodeVereficationText]: {
    id: Parameter_Key_Enum.CodeVereficationText,
    name: 'Code Verification Text',
  },
  [Parameter_Key_Enum.ShowTutorial]: {
    id: Parameter_Key_Enum.ShowTutorial,
    name: 'Show tutorial(Y/N)',
  },
  [Parameter_Key_Enum.ManageSubscriptionsText]: {
    id: Parameter_Key_Enum.ManageSubscriptionsText,
    name: 'Manage subscriptions text',
  },
  [Parameter_Key_Enum.DeleteAccountButton]: {
    id: Parameter_Key_Enum.DeleteAccountButton,
    name: 'Delete account button',
  },
  [Parameter_Key_Enum.DeleteAccountTitle]: {
    id: Parameter_Key_Enum.DeleteAccountTitle,
    name: 'Delete account header',
  },
  [Parameter_Key_Enum.DeleteAccountDescription]: {
    id: Parameter_Key_Enum.DeleteAccountDescription,
    name: 'Delete account description',
  },
};

export default parameterKeys;
