import React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FileInput,
  NumberInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { validateFile, validateName, validatePosition } from '../utils/validation';
import AudioFileField from '../components/AudioFileField';

const SoundForm: React.FC<any> = (props) => {
  return (
    <HNForm {...props}>
      <TextInput source="name" validate={validateName} />
      <NumberInput source="position" validate={validatePosition} />
      <FileInput source="file" multiple={false} validate={validateFile}>
        <AudioFileField />
      </FileInput>
    </HNForm>
  );
};

const SoundTitle: React.FC<any> = ({ record }) => {
  return <span>{record && record.name ? `Sound "${record.name}"` : 'Create Sound'}</span>;
};

export const SoundList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'position', order: 'ASC' }}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="position" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

export const SoundCreate: React.FC<any> = (props) => (
  <Create title={<SoundTitle />} {...props}>
    <SoundForm />
  </Create>
);

export const SoundEdit: React.FC<any> = (props) => (
  <Edit title={<SoundTitle />} {...props}>
    <SoundForm />
  </Edit>
);
