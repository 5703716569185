import { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import buildHasuraProvider, {
  BuildFields,
  FetchType,
  buildFields as raBuildFields,
  buildVariables as raBuildVariables,
  BuildVariables,
} from 'ra-data-hasura';
import client from '../utils/client';
import extendDataProvider from '../utils/extendDataProvider';
import { DocumentNode } from '@apollo/client';
import {
  CollectionListFragmentDoc,
  CollectionOneFragmentDoc,
  TitleListFragmentDoc,
  SoundOneFragmentDoc,
  TrackListFragmentDoc,
  TrackOneFragmentDoc,
  Update_Collection_OverrideDocument,
  Update_Title_OverrideDocument,
  TitleOneFragmentDoc,
  AccessListFragmentDoc,
  ActivationManyFragmentDoc,
  AccessOneFragmentDoc,
  Update_Access_OverrideDocument,
  ActivationListFragmentDoc,
} from '../generated/graphql';

export const FIELDS_OVERRIDE: {
  [resource: string]: Partial<{
    [fetchType in FetchType]: DocumentNode;
  }>;
} = {
  collection: {
    [FetchType.GET_LIST]: CollectionListFragmentDoc,
    [FetchType.GET_ONE]: CollectionOneFragmentDoc,
  },
  title: {
    [FetchType.GET_LIST]: TitleListFragmentDoc,
    [FetchType.GET_ONE]: TitleOneFragmentDoc,
  },
  sound: { [FetchType.GET_ONE]: SoundOneFragmentDoc },
  track: {
    [FetchType.GET_LIST]: TrackListFragmentDoc,
    [FetchType.GET_ONE]: TrackOneFragmentDoc,
  },
  access: {
    [FetchType.GET_LIST]: AccessListFragmentDoc,
    [FetchType.GET_ONE]: AccessOneFragmentDoc,
  },
  activation: {
    [FetchType.GET_LIST]: ActivationListFragmentDoc,
    [FetchType.GET_MANY]: ActivationManyFragmentDoc,
  },
};

export const VARIABLES_OVERRIDE: {
  [resource: string]: { mutation: DocumentNode; fields: [{ name: string; relation: string; ref: 'A' | 'B' }] };
} = {
  collection: {
    mutation: Update_Collection_OverrideDocument,
    fields: [{ name: 'titlesIds', relation: 'collection_title', ref: 'B' }],
  },
  title: {
    mutation: Update_Title_OverrideDocument,
    fields: [{ name: 'collectionsIds', relation: 'collection_title', ref: 'A' }],
  },
  access: {
    mutation: Update_Access_OverrideDocument,
    fields: [{ name: 'titlesIds', relation: 'access_title', ref: 'B' }],
  },
};

const buildFields: BuildFields = (type, fetchType) => {
  const resourceName = type.name;
  const defaultFields = raBuildFields(type, fetchType);

  const overrided =
    fetchType &&
    R.pathOr(null, [resourceName, fetchType, 'definitions', 0, 'selectionSet', 'selections'], FIELDS_OVERRIDE);

  return overrided || defaultFields;
};

const buildVariables: BuildVariables = (introspectionResults) => (resource, aorFetchType, params, queryType) => {
  const resourceName = resource.type.name;

  // Extend buildVariables for specified resources if fetch type is UPDATE
  if (R.keys(VARIABLES_OVERRIDE).includes(resourceName) && aorFetchType === 'UPDATE') {
    const defaultVariables = raBuildVariables(introspectionResults)(resource, aorFetchType, params, queryType);

    const variables = VARIABLES_OVERRIDE[resourceName].fields.reduce((acc, field) => {
      const lhs = params.previousData[field.name] ?? [];
      const rhs = params.data[field.name] ?? [];

      return {
        ...acc,
        [`${field.relation}_insert_inputs`]: R.difference(rhs, lhs).map((refId) => ({
          [field.ref]: refId,
          [field.ref === 'A' ? 'B' : 'A']: params.id,
        })),
        [`${field.relation}_delete_bool_exp`]: {
          [field.ref]: { _in: R.difference(lhs, rhs) },
          [field.ref === 'A' ? 'B' : 'A']: { _eq: params.id },
        },
      };
    }, {});

    return R.mergeAll([
      defaultVariables,
      variables,
      // Avoid RA error for empty _set
      R.isEmpty(defaultVariables._set) ? { _set: { id: params.id } } : {},
    ]);
  }

  // Custom search implementation for activation
  if (resourceName === 'activation' && aorFetchType === FetchType.GET_LIST && 'filter' in params) {
    const defaultVariables = raBuildVariables(introspectionResults)(resource, aorFetchType, params, queryType);

    const searchIndex = defaultVariables?.where?._and?.findIndex?.((cond: any) => 'search' in cond) ?? -1;

    if (searchIndex !== -1) {
      const search = defaultVariables.where._and?.[searchIndex]?.['search']?._eq ?? '';

      const newAnd = R.update(
        searchIndex,
        {
          _or: [
            { email: { _ilike: `%${search}%` } },
            { profile: { first_name: { _ilike: `%${search}%` } } },
            { profile: { last_name: { _ilike: `%${search}%` } } },
            { profile: { email: { _ilike: `%${search}%` } } },
          ],
        },
        defaultVariables.where._and,
      );

      return { ...defaultVariables, where: { _and: newAnd } };
    }
  }

  return raBuildVariables(introspectionResults)(resource, aorFetchType, params, queryType);
};

const useDataProvider = () => {
  const [dataProvider, setDataProvider] = useState<Awaited<ReturnType<typeof buildHasuraProvider>> | null>(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        {
          client,
          mutation: (resource, raFetchMethod) => {
            const overrideField = VARIABLES_OVERRIDE[resource];
            if (raFetchMethod === FetchType.UPDATE && overrideField) {
              return { mutation: overrideField.mutation };
            }
            return {};
          },
        },
        { buildFields },
        buildVariables,
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  return useMemo(() => (dataProvider ? extendDataProvider(dataProvider, client) : undefined), [dataProvider]);
};

export default useDataProvider;
