import { useWatch } from 'react-hook-form';

interface FormConditionProps {
  name: string;
  shouldShow: (value: any) => boolean;
}

const FormCondition: React.FC<React.PropsWithChildren<FormConditionProps>> = ({ children, name, shouldShow }) => {
  const value = useWatch<any>({ name });

  return shouldShow(value) ? <>{children}</> : null;
};

export default FormCondition;
