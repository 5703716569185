import React from 'react';
import { TextFieldProps, useRecordContext } from 'react-admin';
import { Tooltip } from '@mui/material';
import get from 'lodash/get';
import dayjs from 'dayjs';

const DateField: React.FC<TextFieldProps & { source: string }> = (props) => {
  const record = useRecordContext();

  const value = get(record, props.source);

  return (
    <Tooltip title={value ? dayjs(value).format('DD/MM/YYYY HH:mm') : ''}>
      <span>{value ? dayjs(value).format('DD/MM/YYYY') : ''}</span>
    </Tooltip>
  );
};

export default DateField;
