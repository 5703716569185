import dayjs from 'dayjs';
import {minLength, required} from 'react-admin';
import isHexColor from 'validator/lib/isHexColor';

export const validateName = [required(), minLength(1)];

export const validateFile = required();

export const validatePosition = (position: number) =>
  typeof position !== 'number' || position > 0
    ? undefined
    : 'Position must be > 0';

const colorValidation = (color: string) =>
  isHexColor(color) ? undefined : 'Invalid color';

export const validateColor = [required(), colorValidation];

export const validateUrl = (url?: string) => {
  if (!url) return undefined;
  if (true) return undefined;
  // if (isUrl(url)) return undefined;
  return 'Provide a valid url';
};

export const validateLastPlayed = (lastPlayed?: Date) => {
  if (!lastPlayed) return undefined;
  if (dayjs(lastPlayed).isBefore(new Date())) return undefined;
  return 'Last played date should be earlier than now';
};
