import React from 'react';
import {
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FileInput,
  Filter,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  ReferenceInput,
  useRecordContext,
  ChipField,
} from 'react-admin';
import AudioFileField from '../components/AudioFileField';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { validateFile, validateName, validatePosition } from '../utils/validation';

/*
  TODO:
  1. Track actions
  2. Fix filters
*/

const TrackFilter: React.FC<any> = (props) => (
  <Filter {...props}>
    <ReferenceInput perPage={10000} source="title_id" reference="title" alwaysOn>
      <SelectInput optionText="name" resettable />
    </ReferenceInput>
  </Filter>
);

const TrackTitle: React.FC = () => {
  const record = useRecordContext<any>();
  return <span>{record && record.name ? `Track "${record.name}"` : 'Create Track'}</span>;
};

const TrackForm: React.FC<any> = (props) => (
  <HNForm {...props}>
    <ReferenceInput source="title_id" label="Title" reference="title" perPage={10000}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" validate={validateName} />
    <FileInput source="file" multiple={false} accept="audio/*" validate={validateFile}>
      <AudioFileField />
    </FileInput>
    <NumberInput source="position" label="Position" validate={validatePosition} />
    <TextInput
      source="positives"
      multiline
      label="Positive statements"
      parse={(positives?: string) => positives?.split('\n')}
      format={(positives?: string[]) => positives?.join('\n')}
    />
    <BooleanInput source="sound_muted" />
    <NumberInput source="silence_start" label="Fade in duration" />
    <NumberInput source="silence_end" label="Fade out duration" />
  </HNForm>
);

export const TrackList: React.FC<any> = (props) => (
  <HNList {...props} filters={<TrackFilter />} sort={{ field: 'position', order: 'ASC' }}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="position" />
      <ChipField source="title.name" label="Title" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

export const TrackCreate: React.FC<any> = (props) => (
  <Create title={<TrackTitle />} {...props}>
    <TrackForm />
  </Create>
);

export const TrackEdit: React.FC<any> = (props) => (
  <Edit title={<TrackTitle />} {...props}>
    <TrackForm />
  </Edit>
);
