import React from 'react';
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';

export const IAPActivationList: React.FC<any> = (props) => (
  <HNList {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField reference="in_app_purchase" source="in_app_purchase_id" label="In-App Purchase">
        <ChipField source="name" />
      </ReferenceField>
      <BooleanField source="will_renew" />
      <BooleanField source="should_cancel" />
      <TextField source="purchase_id" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const IAPActivationTitle: React.FC<any> = () => {
  const record = useRecordContext();
  return <span>{record && `In-App Purchase Activation "${record.id}"`}</span>;
};

export const IAPActivationForm: React.FC<any> = (props) => (
  <HNForm {...props}>
    <ReferenceInput source="in_app_purchase_id" reference="in_app_purchase" required perPage={10000}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="will_renew" isRequired defaultValue={false} />
    <BooleanInput source="should_cancel" isRequired defaultValue={false} />
    <TextInput source="purchase_id" />
  </HNForm>
);

export const IAPActivationEdit: React.FC<any> = (props) => (
  <Edit title={<IAPActivationTitle />} {...props}>
    <IAPActivationForm />
  </Edit>
);
