import React from 'react';
import * as R from 'ramda';
import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';

const ActivationFilter: React.FC<any> = (props) => (
  <Filter {...props}>
    <TextInput style={{ minWidth: '300px' }} source="search" label="Search" alwaysOn />

    <ReferenceInput source="access_id" reference="access" label="Access" perPage={10000} alwaysOn>
      <SelectInput optionText="name" resettable />
    </ReferenceInput>
  </Filter>
);

const rowStyle = (record: any) => (!!record.in_app_purchase_activation ? { backgroundColor: '#643DB326' } : undefined);

export const ActivationList: React.FC<any> = (props) => (
  <HNList {...props} filters={<ActivationFilter />} sort={{ field: 'activated_at', order: 'DESC' }}>
    <Datagrid rowStyle={rowStyle}>
      <TextField source="profile.email" label="Profile email" />
      <TextField source="profile.first_name" label="First name" />
      <TextField source="profile.last_name" label="Last name" />
      <TextField source="email" label="Activation email" />
      <DateField source="activated_at" />
      <DateField source="expiration" />
      <ReferenceField reference="access" source="access.id" label="Access">
        <ChipField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateEmail = required();

const ActivationTitle: React.FC<any> = ({ record }) => (
  <span>
    {record && record.access && record.access.name ? `Subscription "${record.access.name}"` : 'Create Subscription'}
  </span>
);

export const ActivationCreate: React.FC<any> = (props) => (
  <Create title={<ActivationTitle />} {...props}>
    <HNForm {...props}>
      <TextInput source="email" validate={validateEmail} />
      <ReferenceInput source="access_id" reference="access" label="Access" perPage={10000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </HNForm>
  </Create>
);

export const ActivationEdit: React.FC<any> = (props) => (
    <Edit title={<ActivationTitle />} {...props}>
      <HNForm {...props}>
        <ReferenceInput
          label="Access"
          source="access_id"
          reference="access"
          perPage={300}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Profile"
          source="profile_id"
          reference="profile"
          perPage={300}>
          <SelectInput optionText="email" />
        </ReferenceInput>
        <TextInput source="email" />
        <DateTimeInput source="expiration" />
      </HNForm>
    </Edit>
);
