import { SketchPicker } from 'react-color';
import get from 'lodash/get';
import { Validator, useRecordContext, useInput, FieldTitle } from 'react-admin';
import React, { useState } from 'react';
import { TextField } from '@mui/material';

interface ColorFieldProps {
  source: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
  className?: string;
}

export const ColorField = ({ source }: ColorFieldProps) => {
  const record = useRecordContext();

  return (
    <div
      style={{ width: '20px', height: '20px', background: get(record, source), margin: 'auto', borderRadius: '4px' }}
    />
  );
};

interface ColorInputProps {
  resource?: string;
  label?: string;
  options?: {
    disableAlpha: true;
  };
  source: string;
  helperText?: string;
  className?: string;
  isRequired?: boolean;
  validate?: Validator | Validator[] | undefined;
  variant?: 'filled' | 'outlined' | 'standard';
}

const ColorInput: React.FC<ColorInputProps> = (props) => {
  const [show, setShow] = useState(false);

  const { label, source, className, options, helperText, validate, resource } = props;

  const {
    field,
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({
    ...props,
    validate,
  });

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleChange = ({ hex }: any) => {
    field.onChange(hex);
  };

  const Label = <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />;

  return (
    <div>
      <TextField
        {...field}
        margin="normal"
        onFocus={handleOpen}
        onFocusCapture={handleOpen}
        label={Label}
        error={!!(isTouched && error)}
        helperText={helperText}
        className={className}
      />
      {show ? (
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }} onClick={handleClose} />
          <SketchPicker {...options} color={field.value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorInput;
