import React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { Question_Screen_Enum } from '../generated/graphql';
import { validateName, validatePosition } from '../utils/validation';

const questionKeys = [
  { id: Question_Screen_Enum.More, name: Question_Screen_Enum.More },
  { id: Question_Screen_Enum.Story, name: Question_Screen_Enum.Story },
];

const QuestionForm: React.FC<any> = (props) => (
  <HNForm {...props}>
    <TextInput source="body" validate={validateName} multiline fullWidth />
    <NumberInput source="position" validate={validatePosition} />
    <ReferenceInput source="parent" reference="question" perPage={10000} allowEmpty>
      <SelectInput optionText="body" />
    </ReferenceInput>
    <SelectInput source="screen" choices={questionKeys} />
  </HNForm>
);

const QuestionTitle: React.FC<any> = () => {
  const record = useRecordContext<any>();
  return <span>{record && record.body ? `Question "${record.body}"` : 'Create Question'}</span>;
};

export const QuestionList: React.FC<any> = (props) => (
  <HNList {...props}>
    <Datagrid>
      <TextField source="body" />
      <TextField source="position" />
      <ReferenceField source="parent_id" label="Parent" reference="question">
        <TextField source="body" />
      </ReferenceField>
      <TextField source="screen" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

export const QuestionCreate: React.FC<any> = (props) => (
  <Create title={<QuestionTitle />} {...props}>
    <QuestionForm />
  </Create>
);

export const QuestionEdit: React.FC<any> = (props) => (
  <Edit title={<QuestionTitle />} {...props}>
    <QuestionForm />
  </Edit>
);
