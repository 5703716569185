import React from 'react';
import { Datagrid, DeleteButton, TextField } from 'react-admin';
import HNList from '../components/HNList';
import DateField from '../components/DateField';

export const FeedbackList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'date', order: 'DESC' }}>
    <Datagrid>
      <TextField label="Email" source="email" />
      <TextField label="Phone" source="phone" />
      <DateField source="date" />
      <TextField label="Message" source="message" />
      <DeleteButton />
    </Datagrid>
  </HNList>
);
