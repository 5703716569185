import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';

import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { validateColor, validateName, validatePosition } from '../utils/validation';
import ColorInput, { ColorField } from '../components/ColorInput';
import CollectionPreview from '../components/CollectionPreview';
import { styled } from '@mui/material';

const StyledFormContainer = styled('div')({
  margin: '16px 0 0 16px',
});

const CollectionForm: React.FC<any> = ({ mode, ...props }) => (
  <HNForm {...props} component={StyledFormContainer}>
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
        <TextInput label="Collection Name" source="name" validate={validateName} />
        <ColorInput label="Color" source="color" validate={validateColor} />
        {mode === 'update' && (
          <ReferenceArrayInput label="Titles" source="titlesIds" reference="title" perPage={300}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        )}
        <NumberInput source="position" label="Position" validate={validatePosition} />
      </div>
      <CollectionPreview />
    </div>
  </HNForm>
);

const CollectionTitle: React.FC<any> = ({ record }) => (
  <span>{record && record.name ? `Collection "${record.name}"` : 'Create Collection'}</span>
);

export const CollectionList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'position', order: 'ASC' }}>
    <Datagrid>
      <TextField label="Collection Name" source="name" />
      <ColorField label="Color" source="color" />(
      <ReferenceArrayField label="Titles" source="titlesIds" reference="title">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="position" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

export const CollectionCreate: React.FC<any> = (props) => (
  <Create title={<CollectionTitle />} {...props}>
    <CollectionForm />
  </Create>
);

export const CollectionEdit: React.FC<any> = (props) => (
  <Edit title={<CollectionTitle />} {...props}>
    <CollectionForm mode="update" />
  </Edit>
);
