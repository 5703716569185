import { ResourceProps } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import PeopleIcon from '@mui/icons-material/People';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { ParameterList, ParameterEdit } from './parameter';
import { QuestionList, QuestionCreate, QuestionEdit } from './question';
import { CollectionList, CollectionCreate, CollectionEdit } from './collections';
import { ProfileList } from './profile';
import { SoundCreate, SoundEdit, SoundList } from './sound';
import { TitleCreate, TitleEdit, TitleList } from './titles';
import { TrackCreate, TrackEdit, TrackList } from './tracks';
import { FeedbackList } from './feedback';
import { AccessCreate, AccessEdit, AccessList } from './access';
import { ActivationCreate, ActivationEdit, ActivationList } from './activation';
import { IAPCreate, IAPEdit, IAPList } from './iap';
import { IAPActivationEdit, IAPActivationList } from './iapActivation';

const resources: ResourceProps[] = [
  { name: 'access', icon: LockIcon, list: AccessList, create: AccessCreate, edit: AccessEdit },
  { name: 'activation', icon: LockOpenIcon, list: ActivationList, create: ActivationCreate, edit: ActivationEdit },
  {
    name: 'in_app_purchase',
    options: { label: 'IAPs' },
    icon: LocalOfferIcon,
    list: IAPList,
    create: IAPCreate,
    edit: IAPEdit,
  },
  {
    name: 'in_app_purchase_activation',
    options: { label: 'IAP Activations' },
    icon: ShoppingBagIcon,
    list: IAPActivationList,
    edit: IAPActivationEdit,
  },
  { name: 'profile', icon: PeopleIcon, list: ProfileList },
  { name: 'title', icon: QueueMusicIcon, list: TitleList, create: TitleCreate, edit: TitleEdit },
  { name: 'track', icon: AudiotrackIcon, list: TrackList, create: TrackCreate, edit: TrackEdit },
  { name: 'sound', icon: GraphicEqIcon, list: SoundList, create: SoundCreate, edit: SoundEdit },
  { name: 'collection', icon: LibraryMusicIcon, list: CollectionList, create: CollectionCreate, edit: CollectionEdit },
  { name: 'feedback', icon: FeedbackIcon, list: FeedbackList },
  { name: 'parameter', icon: SettingsIcon, list: ParameterList, edit: ParameterEdit },
  { name: 'question', icon: HelpIcon, list: QuestionList, create: QuestionCreate, edit: QuestionEdit },
];

export default resources;
