import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';

export const IAPList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <TextField source="name" />
      <ReferenceField reference="access" source="access_id" label="Access">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="rc_plan_id" label="RC entitlement ID" />
      <TextField source="remind_before_resubscription" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const IAPTitle: React.FC<any> = ({ record }) => (
  <span>
    {record && record.access && record.name ? `In-App Purchase "${record.access.name}"` : 'Create In-App Purchase'}
  </span>
);

export const IAPForm: React.FC<any> = (props: any) => (
  <HNForm {...props}>
    <TextInput source="name" required />
    <ReferenceInput source="access_id" reference="access" required perPage={10000}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="rc_plan_id" label="RC entitlement ID" required />
    <NumberInput source="remind_before_resubscription" label="Remind before resubscription(days)" />
  </HNForm>
);

export const IAPCreate: React.FC<any> = (props: any) => (
  <Create title={<IAPTitle />} {...props}>
    <IAPForm />
  </Create>
);

export const IAPEdit: React.FC<any> = (props: any) => (
  <Edit title={<IAPTitle />} {...props}>
    <IAPForm />
  </Edit>
);
