import {ApolloClient} from '@apollo/client';
import {DataProvider} from 'ra-core';
import {CreateParams, UpdateParams} from 'react-admin';
import {
  UploadDocument,
  UploadMutation,
  UploadMutationVariables,
} from '../generated/graphql';
import * as R from 'ramda';

const getVideoDuration = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      }
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

async function convertFileToRemote(file: File, client: ApolloClient<any>) {
  const duration = (await getVideoDuration(file)) as number;

  const {data} = await client.mutate<UploadMutation, UploadMutationVariables>({
    mutation: UploadDocument,
    variables: {name: file.name, contentType: file.type, duration},
  });

  if (!data?.upload) {
    throw new Error('uploadFile mutation has failed');
  }

  await fetch(data.upload.url, {
    method: 'PUT',
    body: file,
  });

  return data.upload.file.id;
}

async function extendParamsWithFile<Params extends CreateParams | UpdateParams>(
  params: Params,
  client: ApolloClient<any>,
) {
  const filePairs = R.toPairs<any>(params.data).filter(
    ([, value]) => value && value.rawFile instanceof File,
  );
  const fileKeys = R.map(([key]) => key, filePairs);
  const remoteFiles = await Promise.all(
    filePairs.map(([, value]) => convertFileToRemote(value.rawFile, client)),
  );

  const dataWithFiles = R.fromPairs(
    R.zip(
      fileKeys.map((value) => `${value}_id`),
      remoteFiles,
    ),
  );

  return {
    ...params,
    // @ts-ignore
    data: R.o(R.omit(fileKeys), R.mergeRight(params.data), dataWithFiles),
  };
}

const extendDataProvider = (
  dataProvider: DataProvider,
  client: ApolloClient<any>,
): DataProvider => ({
  ...dataProvider,
  create: async (resource: string, params: any) =>
    await dataProvider.create(
      resource,
      await extendParamsWithFile(params, client),
    ),
  update: async (resource: string, params: any) =>
    await dataProvider.update(
      resource,
      await extendParamsWithFile(params, client),
    ),
});

export default extendDataProvider;
