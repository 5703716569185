import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import ReactAudioPlayer from 'react-audio-player';

export const AudioFileField: React.FC<any> = () => {
  const record = useRecordContext();

  const file = useMemo(() => {
    if ('src' in record) {
      return { title: record.title, url: record.src };
    }

    return { title: record.name, url: record.url };
  }, [record]);

  return (
    <>
      <span>{file.title}</span>
      <br />
      <ReactAudioPlayer title={file.title} src={file.url} controls />
    </>
  );
};

export default AudioFileField;
