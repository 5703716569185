import React from 'react';
import { SimpleForm } from 'react-admin';
import { styled } from '@mui/material';
import { history } from '../utils/client';

const StyledForm = styled(SimpleForm)({
  '&>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '70rem',
  },
});

const HNForm: React.FC<any> = ({ children, ...props }: any) => (
  <StyledForm {...props} redirect={history.back}>
    {children}
  </StyledForm>
);

export default HNForm;
