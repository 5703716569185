import React, { useCallback, useState } from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { Modal, Paper, styled } from '@mui/material';
import * as R from 'ramda';
import HNList from '../components/HNList';
import DateField from '../components/DateField';
import HNForm from '../components/HNForm';
import FormCondition from '../components/FormCondition';

const ModalContent = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: '50vh',
  backgourndColor: 'white',
  padding: '20px',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  display: 'flex',
  zIndex: 1,
  '&>div>span': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  '&>div>span>.MuiStack-root': {
    overflow: 'auto',
  },
});

const AccessActivationsList: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const record = useRecordContext();

  if (!record['activationsIds']?.length) {
    return null;
  }

  return (
    <>
      <Button label="Activations" onClick={() => setOpen(true)} />
      {open && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalContent>
            <ReferenceArrayField
              label="Subscriptions"
              source="activationsIds"
              reference="activation"
              pagination={<Pagination />}
              perPage={25}>
              <SingleFieldList>
                <FunctionField
                  render={(record: any) => <ChipField source={record.email ? 'email' : 'profile.email'} />}
                />
              </SingleFieldList>
            </ReferenceArrayField>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const AccessTitlesList: React.FC = () => {
  const [expandedList, setExpandedList] = useState<string[]>([]);

  const render = useCallback<(record: any) => React.ReactNode>(
    (record) => {
      const onOpen = () => setExpandedList(R.append(record.id));

      if (record.use_all_titles) {
        return 'All titles';
      }

      return expandedList.includes(record.id) ? (
        <ReferenceArrayField source="titlesIds" reference="title">
          <SingleFieldList flexDirection="column">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      ) : (
        <Button sx={{ textWrap: 'nowrap' }} label={`Titles (${record.titlesIds?.length ?? 0})`} onClick={onOpen} />
      );
    },
    [expandedList],
  );

  return <FunctionField label="Titles" source="titlesIds" render={render} />;
};

export const AccessList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid style={{ tableLayout: 'inherit' }}>
      <TextField source="name" />
      <TextField source="description" />
      <TextField label="Magenta subscription" source="subscription.name" />
      <TextField label="In-App Purchase" source="in_app_purchase.name" />
      <TextField label="Promo" source="promo.name" />
      <DateField source="use_by" />
      <TextField source="expiration" />
      <DateField source="expiration_date" />
      <AccessTitlesList />
      <AccessActivationsList />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const AccessTitle: React.FC<any> = ({ record }) => <span>{record ? `Access "${record.id}"` : 'Create Access'}</span>;

export const AccessEdit: React.FC<any> = (props) => (
  <Edit title={<AccessTitle />} {...props}>
    <HNForm {...props}>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <FormCondition name="use_all_titles" shouldShow={R.not}>
        <ReferenceArrayInput label="Titles" source="titlesIds" reference="title" perPage={300}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </FormCondition>
      <BooleanInput source="use_all_titles" />
      <BooleanInput source="show_in_my_collection" />
      <TextInput source="remind_after_subscription" label="Remind after subscription(1 5 10 30 100)" />
      <NumberInput source="expiration" label="Duration (days)" />
    </HNForm>
  </Edit>
);

export const AccessCreate: React.FC<any> = (props) => (
  <Create title={<AccessTitle />} {...props}>
    <HNForm {...props}>
      <TextInput source="name" />
      <TextInput source="description" />
      <DateInput source="use_by" />
      <DateInput source="expiration_date" />
      <BooleanInput source="use_all_titles" />
      <BooleanInput source="show_in_my_collection" />
      <NumberInput source="expiration" label="Duration (days)" />
    </HNForm>
  </Create>
);
