import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  in_app_purchase_platform_prisma: { input: any; output: any; }
  numeric: { input: any; output: any; }
  parameter_key_prisma: { input: any; output: any; }
  question_screen_prisma: { input: any; output: any; }
  relative_position_enum_prisma: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  uuid: { input: any; output: any; }
  verification_type_prisma: { input: any; output: any; }
};

export type ActivationPayload = {
  __typename?: 'ActivationPayload';
  access_id?: Maybe<Scalars['String']['output']>;
  activated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  code_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  profile_id?: Maybe<Scalars['String']['output']>;
  promo_id?: Maybe<Scalars['String']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminFile = {
  __typename?: 'AdminFile';
  duration?: Maybe<Scalars['Float']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  short_id: Scalars['String']['output'];
};

export type AdminLoginPayload = {
  __typename?: 'AdminLoginPayload';
  token: Scalars['String']['output'];
  user: AdminUser;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SendVerificationResponse = {
  __typename?: 'SendVerificationResponse';
  expirationDate: Scalars['DateTimeISO']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFilePayload = {
  __typename?: 'UploadFilePayload';
  file: AdminFile;
  url: Scalars['String']['output'];
};

/** columns and relationships of "access" */
export type Access = {
  __typename?: 'access';
  /** An array relationship */
  activations: Array<Activation>;
  /** An aggregate relationship */
  activations_aggregate: Activation_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  disabling_discounts?: Maybe<Disabled_Access_Discounts_View>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  in_app_purchase?: Maybe<In_App_Purchase>;
  name: Scalars['String']['output'];
  /** An object relationship */
  promo?: Maybe<Promo>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  requiring_discounts: Array<Required_Access_Discounts_View>;
  /** An aggregate relationship */
  requiring_discounts_aggregate: Required_Access_Discounts_View_Aggregate;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  subscription?: Maybe<Magenta_Subscription>;
  /** An array relationship */
  titles: Array<Access_Titles_View>;
  /** An aggregate relationship */
  titles_aggregate: Access_Titles_View_Aggregate;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "access" */
export type AccessActivationsArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "access" */
export type AccessActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "access" */
export type AccessRequiring_DiscountsArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


/** columns and relationships of "access" */
export type AccessRequiring_Discounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


/** columns and relationships of "access" */
export type AccessTitlesArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


/** columns and relationships of "access" */
export type AccessTitles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};

/** aggregated selection of "access" */
export type Access_Aggregate = {
  __typename?: 'access_aggregate';
  aggregate?: Maybe<Access_Aggregate_Fields>;
  nodes: Array<Access>;
};

/** aggregate fields of "access" */
export type Access_Aggregate_Fields = {
  __typename?: 'access_aggregate_fields';
  avg?: Maybe<Access_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Access_Max_Fields>;
  min?: Maybe<Access_Min_Fields>;
  stddev?: Maybe<Access_Stddev_Fields>;
  stddev_pop?: Maybe<Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Access_Stddev_Samp_Fields>;
  sum?: Maybe<Access_Sum_Fields>;
  var_pop?: Maybe<Access_Var_Pop_Fields>;
  var_samp?: Maybe<Access_Var_Samp_Fields>;
  variance?: Maybe<Access_Variance_Fields>;
};


/** aggregate fields of "access" */
export type Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Access_Avg_Fields = {
  __typename?: 'access_avg_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "access". All fields are combined with a logical 'AND'. */
export type Access_Bool_Exp = {
  _and?: InputMaybe<Array<Access_Bool_Exp>>;
  _not?: InputMaybe<Access_Bool_Exp>;
  _or?: InputMaybe<Array<Access_Bool_Exp>>;
  activations?: InputMaybe<Activation_Bool_Exp>;
  activations_aggregate?: InputMaybe<Activation_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disabling_discounts?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  promo?: InputMaybe<Promo_Bool_Exp>;
  remind_after_subscription?: InputMaybe<String_Comparison_Exp>;
  requiring_discounts?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
  requiring_discounts_aggregate?: InputMaybe<Required_Access_Discounts_View_Aggregate_Bool_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  subscription?: InputMaybe<Magenta_Subscription_Bool_Exp>;
  titles?: InputMaybe<Access_Titles_View_Bool_Exp>;
  titles_aggregate?: InputMaybe<Access_Titles_View_Aggregate_Bool_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "access" */
export enum Access_Constraint {
  /** unique or primary key constraint on columns "name" */
  AccessNameUnique = 'access_name_unique',
  /** unique or primary key constraint on columns "id" */
  AccessPkey = 'access_pkey'
}

/** input type for incrementing numeric columns in table "access" */
export type Access_Inc_Input = {
  expiration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "access" */
export type Access_Insert_Input = {
  activations?: InputMaybe<Activation_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabling_discounts?: InputMaybe<Disabled_Access_Discounts_View_Obj_Rel_Insert_Input>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  promo?: InputMaybe<Promo_Obj_Rel_Insert_Input>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  requiring_discounts?: InputMaybe<Required_Access_Discounts_View_Arr_Rel_Insert_Input>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  subscription?: InputMaybe<Magenta_Subscription_Obj_Rel_Insert_Input>;
  titles?: InputMaybe<Access_Titles_View_Arr_Rel_Insert_Input>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Access_Max_Fields = {
  __typename?: 'access_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Access_Min_Fields = {
  __typename?: 'access_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "access" */
export type Access_Mutation_Response = {
  __typename?: 'access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Access>;
};

/** input type for inserting object relation for remote table "access" */
export type Access_Obj_Rel_Insert_Input = {
  data: Access_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Access_On_Conflict>;
};

/** on_conflict condition type for table "access" */
export type Access_On_Conflict = {
  constraint: Access_Constraint;
  update_columns?: Array<Access_Update_Column>;
  where?: InputMaybe<Access_Bool_Exp>;
};

/** Ordering options when selecting data from "access". */
export type Access_Order_By = {
  activations_aggregate?: InputMaybe<Activation_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  disabling_discounts?: InputMaybe<Disabled_Access_Discounts_View_Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Order_By>;
  name?: InputMaybe<Order_By>;
  promo?: InputMaybe<Promo_Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  requiring_discounts_aggregate?: InputMaybe<Required_Access_Discounts_View_Aggregate_Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Magenta_Subscription_Order_By>;
  titles_aggregate?: InputMaybe<Access_Titles_View_Aggregate_Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: access */
export type Access_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "access" */
export enum Access_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RemindAfterSubscription = 'remind_after_subscription',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** input type for updating data in table "access" */
export type Access_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Access_Stddev_Fields = {
  __typename?: 'access_stddev_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Access_Stddev_Pop_Fields = {
  __typename?: 'access_stddev_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Access_Stddev_Samp_Fields = {
  __typename?: 'access_stddev_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "access" */
export type Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Access_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Access_Sum_Fields = {
  __typename?: 'access_sum_fields';
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "_access_title" */
export type Access_Title = {
  __typename?: 'access_title';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  access: Access;
  /** An object relationship */
  title: Title;
};

/** aggregated selection of "_access_title" */
export type Access_Title_Aggregate = {
  __typename?: 'access_title_aggregate';
  aggregate?: Maybe<Access_Title_Aggregate_Fields>;
  nodes: Array<Access_Title>;
};

/** aggregate fields of "_access_title" */
export type Access_Title_Aggregate_Fields = {
  __typename?: 'access_title_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Access_Title_Max_Fields>;
  min?: Maybe<Access_Title_Min_Fields>;
};


/** aggregate fields of "_access_title" */
export type Access_Title_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Access_Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_access_title". All fields are combined with a logical 'AND'. */
export type Access_Title_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Access_Title_Bool_Exp>>;
  _not?: InputMaybe<Access_Title_Bool_Exp>;
  _or?: InputMaybe<Array<Access_Title_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
};

/** unique or primary key constraints on table "_access_title" */
export enum Access_Title_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  AccessTitleAbUnique = '_access_title_AB_unique'
}

/** input type for inserting data into table "_access_title" */
export type Access_Title_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Access_Title_Max_Fields = {
  __typename?: 'access_title_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Access_Title_Min_Fields = {
  __typename?: 'access_title_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_access_title" */
export type Access_Title_Mutation_Response = {
  __typename?: 'access_title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Access_Title>;
};

/** on_conflict condition type for table "_access_title" */
export type Access_Title_On_Conflict = {
  constraint: Access_Title_Constraint;
  update_columns?: Array<Access_Title_Update_Column>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};

/** Ordering options when selecting data from "_access_title". */
export type Access_Title_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  access?: InputMaybe<Access_Order_By>;
  title?: InputMaybe<Title_Order_By>;
};

/** select columns of table "_access_title" */
export enum Access_Title_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_access_title" */
export type Access_Title_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "access_title" */
export type Access_Title_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Access_Title_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Access_Title_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_access_title" */
export enum Access_Title_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Access_Title_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Access_Title_Set_Input>;
  /** filter the rows which have to be updated */
  where: Access_Title_Bool_Exp;
};

/** columns and relationships of "access_titles_view" */
export type Access_Titles_View = {
  __typename?: 'access_titles_view';
  access_id?: Maybe<Scalars['uuid']['output']>;
  adults_only?: Maybe<Scalars['Boolean']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  is_free?: Maybe<Scalars['Boolean']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  show_winner?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "access_titles_view" */
export type Access_Titles_View_Aggregate = {
  __typename?: 'access_titles_view_aggregate';
  aggregate?: Maybe<Access_Titles_View_Aggregate_Fields>;
  nodes: Array<Access_Titles_View>;
};

export type Access_Titles_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Access_Titles_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Access_Titles_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Access_Titles_View_Aggregate_Bool_Exp_Count>;
};

export type Access_Titles_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Access_Titles_View_Select_Column_Access_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Access_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Access_Titles_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Access_Titles_View_Select_Column_Access_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Access_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Access_Titles_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Access_Titles_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "access_titles_view" */
export type Access_Titles_View_Aggregate_Fields = {
  __typename?: 'access_titles_view_aggregate_fields';
  avg?: Maybe<Access_Titles_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Access_Titles_View_Max_Fields>;
  min?: Maybe<Access_Titles_View_Min_Fields>;
  stddev?: Maybe<Access_Titles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Access_Titles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Access_Titles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Access_Titles_View_Sum_Fields>;
  var_pop?: Maybe<Access_Titles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Access_Titles_View_Var_Samp_Fields>;
  variance?: Maybe<Access_Titles_View_Variance_Fields>;
};


/** aggregate fields of "access_titles_view" */
export type Access_Titles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "access_titles_view" */
export type Access_Titles_View_Aggregate_Order_By = {
  avg?: InputMaybe<Access_Titles_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Access_Titles_View_Max_Order_By>;
  min?: InputMaybe<Access_Titles_View_Min_Order_By>;
  stddev?: InputMaybe<Access_Titles_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Access_Titles_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Access_Titles_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Access_Titles_View_Sum_Order_By>;
  var_pop?: InputMaybe<Access_Titles_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Access_Titles_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Access_Titles_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "access_titles_view" */
export type Access_Titles_View_Arr_Rel_Insert_Input = {
  data: Array<Access_Titles_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Access_Titles_View_Avg_Fields = {
  __typename?: 'access_titles_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "access_titles_view" */
export type Access_Titles_View_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "access_titles_view". All fields are combined with a logical 'AND'. */
export type Access_Titles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Access_Titles_View_Bool_Exp>>;
  _not?: InputMaybe<Access_Titles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Access_Titles_View_Bool_Exp>>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "access_titles_view" */
export type Access_Titles_View_Insert_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Access_Titles_View_Max_Fields = {
  __typename?: 'access_titles_view_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "access_titles_view" */
export type Access_Titles_View_Max_Order_By = {
  access_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Access_Titles_View_Min_Fields = {
  __typename?: 'access_titles_view_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "access_titles_view" */
export type Access_Titles_View_Min_Order_By = {
  access_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "access_titles_view". */
export type Access_Titles_View_Order_By = {
  access_id?: InputMaybe<Order_By>;
  adults_only?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** select columns of table "access_titles_view" */
export enum Access_Titles_View_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** select "access_titles_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "access_titles_view" */
export enum Access_Titles_View_Select_Column_Access_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** select "access_titles_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "access_titles_view" */
export enum Access_Titles_View_Select_Column_Access_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** aggregate stddev on columns */
export type Access_Titles_View_Stddev_Fields = {
  __typename?: 'access_titles_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "access_titles_view" */
export type Access_Titles_View_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Access_Titles_View_Stddev_Pop_Fields = {
  __typename?: 'access_titles_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "access_titles_view" */
export type Access_Titles_View_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Access_Titles_View_Stddev_Samp_Fields = {
  __typename?: 'access_titles_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "access_titles_view" */
export type Access_Titles_View_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "access_titles_view" */
export type Access_Titles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Access_Titles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Access_Titles_View_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Access_Titles_View_Sum_Fields = {
  __typename?: 'access_titles_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "access_titles_view" */
export type Access_Titles_View_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Access_Titles_View_Var_Pop_Fields = {
  __typename?: 'access_titles_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "access_titles_view" */
export type Access_Titles_View_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Access_Titles_View_Var_Samp_Fields = {
  __typename?: 'access_titles_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "access_titles_view" */
export type Access_Titles_View_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Access_Titles_View_Variance_Fields = {
  __typename?: 'access_titles_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "access_titles_view" */
export type Access_Titles_View_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "access" */
export enum Access_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RemindAfterSubscription = 'remind_after_subscription',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

export type Access_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Access_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Access_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Access_Var_Pop_Fields = {
  __typename?: 'access_var_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Access_Var_Samp_Fields = {
  __typename?: 'access_var_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Access_Variance_Fields = {
  __typename?: 'access_variance_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "activation" */
export type Activation = {
  __typename?: 'activation';
  /** An object relationship */
  access?: Maybe<Access>;
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  code?: Maybe<Code>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  in_app_purchase_activation?: Maybe<In_App_Purchase_Activation>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  promo?: Maybe<Promo>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  titles: Array<Activation_Titles_View>;
  /** An aggregate relationship */
  titles_aggregate: Activation_Titles_View_Aggregate;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
};


/** columns and relationships of "activation" */
export type ActivationTitlesArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


/** columns and relationships of "activation" */
export type ActivationTitles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};

/** aggregated selection of "activation" */
export type Activation_Aggregate = {
  __typename?: 'activation_aggregate';
  aggregate?: Maybe<Activation_Aggregate_Fields>;
  nodes: Array<Activation>;
};

export type Activation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Activation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Activation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Activation_Aggregate_Bool_Exp_Count>;
};

export type Activation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Activation_Select_Column_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Activation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Activation_Select_Column_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Activation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "activation" */
export type Activation_Aggregate_Fields = {
  __typename?: 'activation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Activation_Max_Fields>;
  min?: Maybe<Activation_Min_Fields>;
};


/** aggregate fields of "activation" */
export type Activation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "activation" */
export type Activation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Activation_Max_Order_By>;
  min?: InputMaybe<Activation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "activation" */
export type Activation_Arr_Rel_Insert_Input = {
  data: Array<Activation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Activation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "activation". All fields are combined with a logical 'AND'. */
export type Activation_Bool_Exp = {
  _and?: InputMaybe<Array<Activation_Bool_Exp>>;
  _not?: InputMaybe<Activation_Bool_Exp>;
  _or?: InputMaybe<Array<Activation_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  activated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  code?: InputMaybe<Code_Bool_Exp>;
  code_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  in_app_purchase_activation?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  promo?: InputMaybe<Promo_Bool_Exp>;
  promo_id?: InputMaybe<Uuid_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  titles?: InputMaybe<Activation_Titles_View_Bool_Exp>;
  titles_aggregate?: InputMaybe<Activation_Titles_View_Aggregate_Bool_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "activation" */
export enum Activation_Constraint {
  /** unique or primary key constraint on columns "code_id" */
  ActivationCodeIdUnique = 'activation_code_id_unique',
  /** unique or primary key constraint on columns "id" */
  ActivationPkey = 'activation_pkey'
}

/** input type for inserting data into table "activation" */
export type Activation_Insert_Input = {
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activated_at?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Code_Obj_Rel_Insert_Input>;
  code_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase_activation?: InputMaybe<In_App_Purchase_Activation_Obj_Rel_Insert_Input>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  promo?: InputMaybe<Promo_Obj_Rel_Insert_Input>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  titles?: InputMaybe<Activation_Titles_View_Arr_Rel_Insert_Input>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Activation_Max_Fields = {
  __typename?: 'activation_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at?: Maybe<Scalars['timestamp']['output']>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "activation" */
export type Activation_Max_Order_By = {
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Activation_Min_Fields = {
  __typename?: 'activation_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at?: Maybe<Scalars['timestamp']['output']>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "activation" */
export type Activation_Min_Order_By = {
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "activation" */
export type Activation_Mutation_Response = {
  __typename?: 'activation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Activation>;
};

/** input type for inserting object relation for remote table "activation" */
export type Activation_Obj_Rel_Insert_Input = {
  data: Activation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Activation_On_Conflict>;
};

/** on_conflict condition type for table "activation" */
export type Activation_On_Conflict = {
  constraint: Activation_Constraint;
  update_columns?: Array<Activation_Update_Column>;
  where?: InputMaybe<Activation_Bool_Exp>;
};

/** Ordering options when selecting data from "activation". */
export type Activation_Order_By = {
  access?: InputMaybe<Access_Order_By>;
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code?: InputMaybe<Code_Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase_activation?: InputMaybe<In_App_Purchase_Activation_Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo?: InputMaybe<Promo_Order_By>;
  promo_id?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  titles_aggregate?: InputMaybe<Activation_Titles_View_Aggregate_Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
};

/** primary key columns input for table: activation */
export type Activation_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "activation" */
export enum Activation_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  ActivatedAt = 'activated_at',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "activation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "activation" */
export enum Activation_Select_Column_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "activation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "activation" */
export enum Activation_Select_Column_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** input type for updating data in table "activation" */
export type Activation_Set_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activated_at?: InputMaybe<Scalars['timestamp']['input']>;
  code_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "activation" */
export type Activation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activation_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activated_at?: InputMaybe<Scalars['timestamp']['input']>;
  code_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "_activation_title" */
export type Activation_Title = {
  __typename?: 'activation_title';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  activation: Activation;
  /** An object relationship */
  title: Title;
};

/** aggregated selection of "_activation_title" */
export type Activation_Title_Aggregate = {
  __typename?: 'activation_title_aggregate';
  aggregate?: Maybe<Activation_Title_Aggregate_Fields>;
  nodes: Array<Activation_Title>;
};

/** aggregate fields of "_activation_title" */
export type Activation_Title_Aggregate_Fields = {
  __typename?: 'activation_title_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Activation_Title_Max_Fields>;
  min?: Maybe<Activation_Title_Min_Fields>;
};


/** aggregate fields of "_activation_title" */
export type Activation_Title_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Activation_Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_activation_title". All fields are combined with a logical 'AND'. */
export type Activation_Title_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Activation_Title_Bool_Exp>>;
  _not?: InputMaybe<Activation_Title_Bool_Exp>;
  _or?: InputMaybe<Array<Activation_Title_Bool_Exp>>;
  activation?: InputMaybe<Activation_Bool_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
};

/** unique or primary key constraints on table "_activation_title" */
export enum Activation_Title_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  ActivationTitleAbUnique = '_activation_title_AB_unique'
}

/** input type for inserting data into table "_activation_title" */
export type Activation_Title_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  activation?: InputMaybe<Activation_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Activation_Title_Max_Fields = {
  __typename?: 'activation_title_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Activation_Title_Min_Fields = {
  __typename?: 'activation_title_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_activation_title" */
export type Activation_Title_Mutation_Response = {
  __typename?: 'activation_title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Activation_Title>;
};

/** on_conflict condition type for table "_activation_title" */
export type Activation_Title_On_Conflict = {
  constraint: Activation_Title_Constraint;
  update_columns?: Array<Activation_Title_Update_Column>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};

/** Ordering options when selecting data from "_activation_title". */
export type Activation_Title_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  activation?: InputMaybe<Activation_Order_By>;
  title?: InputMaybe<Title_Order_By>;
};

/** select columns of table "_activation_title" */
export enum Activation_Title_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_activation_title" */
export type Activation_Title_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "activation_title" */
export type Activation_Title_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activation_Title_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activation_Title_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_activation_title" */
export enum Activation_Title_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Activation_Title_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Activation_Title_Set_Input>;
  /** filter the rows which have to be updated */
  where: Activation_Title_Bool_Exp;
};

/** columns and relationships of "activation_titles_view" */
export type Activation_Titles_View = {
  __typename?: 'activation_titles_view';
  activation_id?: Maybe<Scalars['uuid']['output']>;
  adults_only?: Maybe<Scalars['Boolean']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  is_free?: Maybe<Scalars['Boolean']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  show_winner?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "activation_titles_view" */
export type Activation_Titles_View_Aggregate = {
  __typename?: 'activation_titles_view_aggregate';
  aggregate?: Maybe<Activation_Titles_View_Aggregate_Fields>;
  nodes: Array<Activation_Titles_View>;
};

export type Activation_Titles_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Activation_Titles_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Activation_Titles_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Activation_Titles_View_Aggregate_Bool_Exp_Count>;
};

export type Activation_Titles_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Activation_Titles_View_Select_Column_Activation_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Activation_Titles_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Activation_Titles_View_Select_Column_Activation_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Activation_Titles_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Activation_Titles_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "activation_titles_view" */
export type Activation_Titles_View_Aggregate_Fields = {
  __typename?: 'activation_titles_view_aggregate_fields';
  avg?: Maybe<Activation_Titles_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Activation_Titles_View_Max_Fields>;
  min?: Maybe<Activation_Titles_View_Min_Fields>;
  stddev?: Maybe<Activation_Titles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Activation_Titles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Activation_Titles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Activation_Titles_View_Sum_Fields>;
  var_pop?: Maybe<Activation_Titles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Activation_Titles_View_Var_Samp_Fields>;
  variance?: Maybe<Activation_Titles_View_Variance_Fields>;
};


/** aggregate fields of "activation_titles_view" */
export type Activation_Titles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "activation_titles_view" */
export type Activation_Titles_View_Aggregate_Order_By = {
  avg?: InputMaybe<Activation_Titles_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Activation_Titles_View_Max_Order_By>;
  min?: InputMaybe<Activation_Titles_View_Min_Order_By>;
  stddev?: InputMaybe<Activation_Titles_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Activation_Titles_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Activation_Titles_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Activation_Titles_View_Sum_Order_By>;
  var_pop?: InputMaybe<Activation_Titles_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Activation_Titles_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Activation_Titles_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "activation_titles_view" */
export type Activation_Titles_View_Arr_Rel_Insert_Input = {
  data: Array<Activation_Titles_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Activation_Titles_View_Avg_Fields = {
  __typename?: 'activation_titles_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "activation_titles_view". All fields are combined with a logical 'AND'. */
export type Activation_Titles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Activation_Titles_View_Bool_Exp>>;
  _not?: InputMaybe<Activation_Titles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Activation_Titles_View_Bool_Exp>>;
  activation_id?: InputMaybe<Uuid_Comparison_Exp>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "activation_titles_view" */
export type Activation_Titles_View_Insert_Input = {
  activation_id?: InputMaybe<Scalars['uuid']['input']>;
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Activation_Titles_View_Max_Fields = {
  __typename?: 'activation_titles_view_max_fields';
  activation_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Max_Order_By = {
  activation_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Activation_Titles_View_Min_Fields = {
  __typename?: 'activation_titles_view_min_fields';
  activation_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Min_Order_By = {
  activation_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "activation_titles_view". */
export type Activation_Titles_View_Order_By = {
  activation_id?: InputMaybe<Order_By>;
  adults_only?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** select columns of table "activation_titles_view" */
export enum Activation_Titles_View_Select_Column {
  /** column name */
  ActivationId = 'activation_id',
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** select "activation_titles_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "activation_titles_view" */
export enum Activation_Titles_View_Select_Column_Activation_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** select "activation_titles_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "activation_titles_view" */
export enum Activation_Titles_View_Select_Column_Activation_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** aggregate stddev on columns */
export type Activation_Titles_View_Stddev_Fields = {
  __typename?: 'activation_titles_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Activation_Titles_View_Stddev_Pop_Fields = {
  __typename?: 'activation_titles_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Activation_Titles_View_Stddev_Samp_Fields = {
  __typename?: 'activation_titles_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "activation_titles_view" */
export type Activation_Titles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activation_Titles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activation_Titles_View_Stream_Cursor_Value_Input = {
  activation_id?: InputMaybe<Scalars['uuid']['input']>;
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Activation_Titles_View_Sum_Fields = {
  __typename?: 'activation_titles_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Activation_Titles_View_Var_Pop_Fields = {
  __typename?: 'activation_titles_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Activation_Titles_View_Var_Samp_Fields = {
  __typename?: 'activation_titles_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Activation_Titles_View_Variance_Fields = {
  __typename?: 'activation_titles_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "activation_titles_view" */
export type Activation_Titles_View_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "activation" */
export enum Activation_Update_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  ActivatedAt = 'activated_at',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

export type Activation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Activation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Activation_Bool_Exp;
};

/** columns and relationships of "code" */
export type Code = {
  __typename?: 'code';
  /** An object relationship */
  activation?: Maybe<Activation>;
  code: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  promo: Promo;
  promo_id: Scalars['uuid']['output'];
  used?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "code" */
export type Code_Aggregate = {
  __typename?: 'code_aggregate';
  aggregate?: Maybe<Code_Aggregate_Fields>;
  nodes: Array<Code>;
};

export type Code_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Code_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Code_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Code_Aggregate_Bool_Exp_Count>;
};

export type Code_Aggregate_Bool_Exp_Bool_And = {
  arguments: Code_Select_Column_Code_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Code_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Code_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Code_Select_Column_Code_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Code_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Code_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Code_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "code" */
export type Code_Aggregate_Fields = {
  __typename?: 'code_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Code_Max_Fields>;
  min?: Maybe<Code_Min_Fields>;
};


/** aggregate fields of "code" */
export type Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "code" */
export type Code_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Code_Max_Order_By>;
  min?: InputMaybe<Code_Min_Order_By>;
};

/** input type for inserting array relation for remote table "code" */
export type Code_Arr_Rel_Insert_Input = {
  data: Array<Code_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Code_On_Conflict>;
};

/** Boolean expression to filter rows from the table "code". All fields are combined with a logical 'AND'. */
export type Code_Bool_Exp = {
  _and?: InputMaybe<Array<Code_Bool_Exp>>;
  _not?: InputMaybe<Code_Bool_Exp>;
  _or?: InputMaybe<Array<Code_Bool_Exp>>;
  activation?: InputMaybe<Activation_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  promo?: InputMaybe<Promo_Bool_Exp>;
  promo_id?: InputMaybe<Uuid_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "code" */
export enum Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  CodePkey = 'code_pkey'
}

/** input type for inserting data into table "code" */
export type Code_Insert_Input = {
  activation?: InputMaybe<Activation_Obj_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  promo?: InputMaybe<Promo_Obj_Rel_Insert_Input>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  used?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Code_Max_Fields = {
  __typename?: 'code_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "code" */
export type Code_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Code_Min_Fields = {
  __typename?: 'code_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "code" */
export type Code_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "code" */
export type Code_Mutation_Response = {
  __typename?: 'code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Code>;
};

/** input type for inserting object relation for remote table "code" */
export type Code_Obj_Rel_Insert_Input = {
  data: Code_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Code_On_Conflict>;
};

/** on_conflict condition type for table "code" */
export type Code_On_Conflict = {
  constraint: Code_Constraint;
  update_columns?: Array<Code_Update_Column>;
  where?: InputMaybe<Code_Bool_Exp>;
};

/** Ordering options when selecting data from "code". */
export type Code_Order_By = {
  activation?: InputMaybe<Activation_Order_By>;
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  promo?: InputMaybe<Promo_Order_By>;
  promo_id?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
};

/** primary key columns input for table: code */
export type Code_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "code" */
export enum Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  Used = 'used'
}

/** select "code_aggregate_bool_exp_bool_and_arguments_columns" columns of table "code" */
export enum Code_Select_Column_Code_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Used = 'used'
}

/** select "code_aggregate_bool_exp_bool_or_arguments_columns" columns of table "code" */
export enum Code_Select_Column_Code_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "code" */
export type Code_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  used?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "code" */
export type Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  used?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "code" */
export enum Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  Used = 'used'
}

export type Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Code_Bool_Exp;
};

/** columns and relationships of "collection" */
export type Collection = {
  __typename?: 'collection';
  color: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  titles: Array<Collection_Titles_View>;
  /** An aggregate relationship */
  titles_aggregate: Collection_Titles_View_Aggregate;
};


/** columns and relationships of "collection" */
export type CollectionTitlesArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


/** columns and relationships of "collection" */
export type CollectionTitles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};

/** aggregated selection of "collection" */
export type Collection_Aggregate = {
  __typename?: 'collection_aggregate';
  aggregate?: Maybe<Collection_Aggregate_Fields>;
  nodes: Array<Collection>;
};

/** aggregate fields of "collection" */
export type Collection_Aggregate_Fields = {
  __typename?: 'collection_aggregate_fields';
  avg?: Maybe<Collection_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Collection_Max_Fields>;
  min?: Maybe<Collection_Min_Fields>;
  stddev?: Maybe<Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Sum_Fields>;
  var_pop?: Maybe<Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Var_Samp_Fields>;
  variance?: Maybe<Collection_Variance_Fields>;
};


/** aggregate fields of "collection" */
export type Collection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Collection_Avg_Fields = {
  __typename?: 'collection_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "collection". All fields are combined with a logical 'AND'. */
export type Collection_Bool_Exp = {
  _and?: InputMaybe<Array<Collection_Bool_Exp>>;
  _not?: InputMaybe<Collection_Bool_Exp>;
  _or?: InputMaybe<Array<Collection_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  titles?: InputMaybe<Collection_Titles_View_Bool_Exp>;
  titles_aggregate?: InputMaybe<Collection_Titles_View_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "collection" */
export enum Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionPkey = 'collection_pkey'
}

/** input type for incrementing numeric columns in table "collection" */
export type Collection_Inc_Input = {
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "collection" */
export type Collection_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  titles?: InputMaybe<Collection_Titles_View_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Collection_Max_Fields = {
  __typename?: 'collection_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Collection_Min_Fields = {
  __typename?: 'collection_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "collection" */
export type Collection_Mutation_Response = {
  __typename?: 'collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection>;
};

/** input type for inserting object relation for remote table "collection" */
export type Collection_Obj_Rel_Insert_Input = {
  data: Collection_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};

/** on_conflict condition type for table "collection" */
export type Collection_On_Conflict = {
  constraint: Collection_Constraint;
  update_columns?: Array<Collection_Update_Column>;
  where?: InputMaybe<Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "collection". */
export type Collection_Order_By = {
  color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  titles_aggregate?: InputMaybe<Collection_Titles_View_Aggregate_Order_By>;
};

/** primary key columns input for table: collection */
export type Collection_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "collection" */
export enum Collection_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "collection" */
export type Collection_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Collection_Stddev_Fields = {
  __typename?: 'collection_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Collection_Stddev_Pop_Fields = {
  __typename?: 'collection_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Collection_Stddev_Samp_Fields = {
  __typename?: 'collection_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "collection" */
export type Collection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Collection_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Collection_Sum_Fields = {
  __typename?: 'collection_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "_collection_title" */
export type Collection_Title = {
  __typename?: 'collection_title';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  collection: Collection;
  /** An object relationship */
  title: Title;
};

/** aggregated selection of "_collection_title" */
export type Collection_Title_Aggregate = {
  __typename?: 'collection_title_aggregate';
  aggregate?: Maybe<Collection_Title_Aggregate_Fields>;
  nodes: Array<Collection_Title>;
};

/** aggregate fields of "_collection_title" */
export type Collection_Title_Aggregate_Fields = {
  __typename?: 'collection_title_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Collection_Title_Max_Fields>;
  min?: Maybe<Collection_Title_Min_Fields>;
};


/** aggregate fields of "_collection_title" */
export type Collection_Title_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collection_Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_collection_title". All fields are combined with a logical 'AND'. */
export type Collection_Title_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Collection_Title_Bool_Exp>>;
  _not?: InputMaybe<Collection_Title_Bool_Exp>;
  _or?: InputMaybe<Array<Collection_Title_Bool_Exp>>;
  collection?: InputMaybe<Collection_Bool_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
};

/** unique or primary key constraints on table "_collection_title" */
export enum Collection_Title_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  CollectionTitleAbUnique = '_collection_title_AB_unique'
}

/** input type for inserting data into table "_collection_title" */
export type Collection_Title_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  collection?: InputMaybe<Collection_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Collection_Title_Max_Fields = {
  __typename?: 'collection_title_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Collection_Title_Min_Fields = {
  __typename?: 'collection_title_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_collection_title" */
export type Collection_Title_Mutation_Response = {
  __typename?: 'collection_title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Title>;
};

/** on_conflict condition type for table "_collection_title" */
export type Collection_Title_On_Conflict = {
  constraint: Collection_Title_Constraint;
  update_columns?: Array<Collection_Title_Update_Column>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};

/** Ordering options when selecting data from "_collection_title". */
export type Collection_Title_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  collection?: InputMaybe<Collection_Order_By>;
  title?: InputMaybe<Title_Order_By>;
};

/** select columns of table "_collection_title" */
export enum Collection_Title_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_collection_title" */
export type Collection_Title_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "collection_title" */
export type Collection_Title_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Title_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Collection_Title_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_collection_title" */
export enum Collection_Title_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Collection_Title_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Collection_Title_Set_Input>;
  /** filter the rows which have to be updated */
  where: Collection_Title_Bool_Exp;
};

/** columns and relationships of "collection_titles_view" */
export type Collection_Titles_View = {
  __typename?: 'collection_titles_view';
  adults_only?: Maybe<Scalars['Boolean']['output']>;
  collection_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  is_free?: Maybe<Scalars['Boolean']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  show_winner?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "collection_titles_view" */
export type Collection_Titles_View_Aggregate = {
  __typename?: 'collection_titles_view_aggregate';
  aggregate?: Maybe<Collection_Titles_View_Aggregate_Fields>;
  nodes: Array<Collection_Titles_View>;
};

export type Collection_Titles_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Collection_Titles_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Collection_Titles_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Collection_Titles_View_Aggregate_Bool_Exp_Count>;
};

export type Collection_Titles_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Collection_Titles_View_Select_Column_Collection_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Collection_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Collection_Titles_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Collection_Titles_View_Select_Column_Collection_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Collection_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Collection_Titles_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Collection_Titles_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "collection_titles_view" */
export type Collection_Titles_View_Aggregate_Fields = {
  __typename?: 'collection_titles_view_aggregate_fields';
  avg?: Maybe<Collection_Titles_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Collection_Titles_View_Max_Fields>;
  min?: Maybe<Collection_Titles_View_Min_Fields>;
  stddev?: Maybe<Collection_Titles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Titles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Titles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Titles_View_Sum_Fields>;
  var_pop?: Maybe<Collection_Titles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Titles_View_Var_Samp_Fields>;
  variance?: Maybe<Collection_Titles_View_Variance_Fields>;
};


/** aggregate fields of "collection_titles_view" */
export type Collection_Titles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "collection_titles_view" */
export type Collection_Titles_View_Aggregate_Order_By = {
  avg?: InputMaybe<Collection_Titles_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Collection_Titles_View_Max_Order_By>;
  min?: InputMaybe<Collection_Titles_View_Min_Order_By>;
  stddev?: InputMaybe<Collection_Titles_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Collection_Titles_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Collection_Titles_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Collection_Titles_View_Sum_Order_By>;
  var_pop?: InputMaybe<Collection_Titles_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Collection_Titles_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Collection_Titles_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "collection_titles_view" */
export type Collection_Titles_View_Arr_Rel_Insert_Input = {
  data: Array<Collection_Titles_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Collection_Titles_View_Avg_Fields = {
  __typename?: 'collection_titles_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "collection_titles_view". All fields are combined with a logical 'AND'. */
export type Collection_Titles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Collection_Titles_View_Bool_Exp>>;
  _not?: InputMaybe<Collection_Titles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Collection_Titles_View_Bool_Exp>>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  collection_id?: InputMaybe<Uuid_Comparison_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "collection_titles_view" */
export type Collection_Titles_View_Insert_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  collection_id?: InputMaybe<Scalars['uuid']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Collection_Titles_View_Max_Fields = {
  __typename?: 'collection_titles_view_max_fields';
  collection_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Max_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Collection_Titles_View_Min_Fields = {
  __typename?: 'collection_titles_view_min_fields';
  collection_id?: Maybe<Scalars['uuid']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Min_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "collection_titles_view". */
export type Collection_Titles_View_Order_By = {
  adults_only?: InputMaybe<Order_By>;
  collection_id?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** select columns of table "collection_titles_view" */
export enum Collection_Titles_View_Select_Column {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** select "collection_titles_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "collection_titles_view" */
export enum Collection_Titles_View_Select_Column_Collection_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** select "collection_titles_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "collection_titles_view" */
export enum Collection_Titles_View_Select_Column_Collection_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** aggregate stddev on columns */
export type Collection_Titles_View_Stddev_Fields = {
  __typename?: 'collection_titles_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collection_Titles_View_Stddev_Pop_Fields = {
  __typename?: 'collection_titles_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collection_Titles_View_Stddev_Samp_Fields = {
  __typename?: 'collection_titles_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "collection_titles_view" */
export type Collection_Titles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Titles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Collection_Titles_View_Stream_Cursor_Value_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  collection_id?: InputMaybe<Scalars['uuid']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Collection_Titles_View_Sum_Fields = {
  __typename?: 'collection_titles_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Collection_Titles_View_Var_Pop_Fields = {
  __typename?: 'collection_titles_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collection_Titles_View_Var_Samp_Fields = {
  __typename?: 'collection_titles_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Collection_Titles_View_Variance_Fields = {
  __typename?: 'collection_titles_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "collection_titles_view" */
export type Collection_Titles_View_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "collection" */
export enum Collection_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

export type Collection_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Collection_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Collection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Collection_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Collection_Var_Pop_Fields = {
  __typename?: 'collection_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Collection_Var_Samp_Fields = {
  __typename?: 'collection_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Collection_Variance_Fields = {
  __typename?: 'collection_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View = {
  __typename?: 'disabled_access_discounts_view';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** aggregated selection of "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Aggregate = {
  __typename?: 'disabled_access_discounts_view_aggregate';
  aggregate?: Maybe<Disabled_Access_Discounts_View_Aggregate_Fields>;
  nodes: Array<Disabled_Access_Discounts_View>;
};

/** aggregate fields of "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Aggregate_Fields = {
  __typename?: 'disabled_access_discounts_view_aggregate_fields';
  avg?: Maybe<Disabled_Access_Discounts_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Disabled_Access_Discounts_View_Max_Fields>;
  min?: Maybe<Disabled_Access_Discounts_View_Min_Fields>;
  stddev?: Maybe<Disabled_Access_Discounts_View_Stddev_Fields>;
  stddev_pop?: Maybe<Disabled_Access_Discounts_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Disabled_Access_Discounts_View_Stddev_Samp_Fields>;
  sum?: Maybe<Disabled_Access_Discounts_View_Sum_Fields>;
  var_pop?: Maybe<Disabled_Access_Discounts_View_Var_Pop_Fields>;
  var_samp?: Maybe<Disabled_Access_Discounts_View_Var_Samp_Fields>;
  variance?: Maybe<Disabled_Access_Discounts_View_Variance_Fields>;
};


/** aggregate fields of "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Disabled_Access_Discounts_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Disabled_Access_Discounts_View_Avg_Fields = {
  __typename?: 'disabled_access_discounts_view_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "disabled_access_discounts_view". All fields are combined with a logical 'AND'. */
export type Disabled_Access_Discounts_View_Bool_Exp = {
  _and?: InputMaybe<Array<Disabled_Access_Discounts_View_Bool_Exp>>;
  _not?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
  _or?: InputMaybe<Array<Disabled_Access_Discounts_View_Bool_Exp>>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  days?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  relative_position?: InputMaybe<String_Comparison_Exp>;
  relative_position_prisma?: InputMaybe<Relative_Position_Enum_Prisma_Comparison_Exp>;
};

/** input type for inserting data into table "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Insert_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Scalars['String']['input']>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate max on columns */
export type Disabled_Access_Discounts_View_Max_Fields = {
  __typename?: 'disabled_access_discounts_view_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** aggregate min on columns */
export type Disabled_Access_Discounts_View_Min_Fields = {
  __typename?: 'disabled_access_discounts_view_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** input type for inserting object relation for remote table "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Obj_Rel_Insert_Input = {
  data: Disabled_Access_Discounts_View_Insert_Input;
};

/** Ordering options when selecting data from "disabled_access_discounts_view". */
export type Disabled_Access_Discounts_View_Order_By = {
  access_id?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** select columns of table "disabled_access_discounts_view" */
export enum Disabled_Access_Discounts_View_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelativePosition = 'relative_position',
  /** column name */
  RelativePositionPrisma = 'relative_position_prisma'
}

/** aggregate stddev on columns */
export type Disabled_Access_Discounts_View_Stddev_Fields = {
  __typename?: 'disabled_access_discounts_view_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Disabled_Access_Discounts_View_Stddev_Pop_Fields = {
  __typename?: 'disabled_access_discounts_view_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Disabled_Access_Discounts_View_Stddev_Samp_Fields = {
  __typename?: 'disabled_access_discounts_view_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "disabled_access_discounts_view" */
export type Disabled_Access_Discounts_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Disabled_Access_Discounts_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Disabled_Access_Discounts_View_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Scalars['String']['input']>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate sum on columns */
export type Disabled_Access_Discounts_View_Sum_Fields = {
  __typename?: 'disabled_access_discounts_view_sum_fields';
  days?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Disabled_Access_Discounts_View_Var_Pop_Fields = {
  __typename?: 'disabled_access_discounts_view_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Disabled_Access_Discounts_View_Var_Samp_Fields = {
  __typename?: 'disabled_access_discounts_view_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Disabled_Access_Discounts_View_Variance_Fields = {
  __typename?: 'disabled_access_discounts_view_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount = {
  __typename?: 'disabled_access_for_discount';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  access: Access;
  /** An object relationship */
  discount: Discount;
};

/** aggregated selection of "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Aggregate = {
  __typename?: 'disabled_access_for_discount_aggregate';
  aggregate?: Maybe<Disabled_Access_For_Discount_Aggregate_Fields>;
  nodes: Array<Disabled_Access_For_Discount>;
};

/** aggregate fields of "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Aggregate_Fields = {
  __typename?: 'disabled_access_for_discount_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Disabled_Access_For_Discount_Max_Fields>;
  min?: Maybe<Disabled_Access_For_Discount_Min_Fields>;
};


/** aggregate fields of "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Disabled_Access_For_Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_disabled_access_for_discount". All fields are combined with a logical 'AND'. */
export type Disabled_Access_For_Discount_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Disabled_Access_For_Discount_Bool_Exp>>;
  _not?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
  _or?: InputMaybe<Array<Disabled_Access_For_Discount_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  discount?: InputMaybe<Discount_Bool_Exp>;
};

/** unique or primary key constraints on table "_disabled_access_for_discount" */
export enum Disabled_Access_For_Discount_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  DisabledAccessForDiscountAbUnique = '_disabled_access_for_discount_AB_unique'
}

/** input type for inserting data into table "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  discount?: InputMaybe<Discount_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Disabled_Access_For_Discount_Max_Fields = {
  __typename?: 'disabled_access_for_discount_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Disabled_Access_For_Discount_Min_Fields = {
  __typename?: 'disabled_access_for_discount_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Mutation_Response = {
  __typename?: 'disabled_access_for_discount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Disabled_Access_For_Discount>;
};

/** on_conflict condition type for table "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_On_Conflict = {
  constraint: Disabled_Access_For_Discount_Constraint;
  update_columns?: Array<Disabled_Access_For_Discount_Update_Column>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "_disabled_access_for_discount". */
export type Disabled_Access_For_Discount_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  access?: InputMaybe<Access_Order_By>;
  discount?: InputMaybe<Discount_Order_By>;
};

/** select columns of table "_disabled_access_for_discount" */
export enum Disabled_Access_For_Discount_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "disabled_access_for_discount" */
export type Disabled_Access_For_Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Disabled_Access_For_Discount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Disabled_Access_For_Discount_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_disabled_access_for_discount" */
export enum Disabled_Access_For_Discount_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Disabled_Access_For_Discount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Disabled_Access_For_Discount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Disabled_Access_For_Discount_Bool_Exp;
};

/** columns and relationships of "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View = {
  __typename?: 'disabled_discount_accesses_view';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Aggregate = {
  __typename?: 'disabled_discount_accesses_view_aggregate';
  aggregate?: Maybe<Disabled_Discount_Accesses_View_Aggregate_Fields>;
  nodes: Array<Disabled_Discount_Accesses_View>;
};

export type Disabled_Discount_Accesses_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Count>;
};

export type Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Disabled_Discount_Accesses_View_Select_Column_Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Disabled_Discount_Accesses_View_Select_Column_Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Aggregate_Fields = {
  __typename?: 'disabled_discount_accesses_view_aggregate_fields';
  avg?: Maybe<Disabled_Discount_Accesses_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Disabled_Discount_Accesses_View_Max_Fields>;
  min?: Maybe<Disabled_Discount_Accesses_View_Min_Fields>;
  stddev?: Maybe<Disabled_Discount_Accesses_View_Stddev_Fields>;
  stddev_pop?: Maybe<Disabled_Discount_Accesses_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Disabled_Discount_Accesses_View_Stddev_Samp_Fields>;
  sum?: Maybe<Disabled_Discount_Accesses_View_Sum_Fields>;
  var_pop?: Maybe<Disabled_Discount_Accesses_View_Var_Pop_Fields>;
  var_samp?: Maybe<Disabled_Discount_Accesses_View_Var_Samp_Fields>;
  variance?: Maybe<Disabled_Discount_Accesses_View_Variance_Fields>;
};


/** aggregate fields of "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Aggregate_Order_By = {
  avg?: InputMaybe<Disabled_Discount_Accesses_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Disabled_Discount_Accesses_View_Max_Order_By>;
  min?: InputMaybe<Disabled_Discount_Accesses_View_Min_Order_By>;
  stddev?: InputMaybe<Disabled_Discount_Accesses_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Disabled_Discount_Accesses_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Disabled_Discount_Accesses_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Disabled_Discount_Accesses_View_Sum_Order_By>;
  var_pop?: InputMaybe<Disabled_Discount_Accesses_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Disabled_Discount_Accesses_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Disabled_Discount_Accesses_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Arr_Rel_Insert_Input = {
  data: Array<Disabled_Discount_Accesses_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Disabled_Discount_Accesses_View_Avg_Fields = {
  __typename?: 'disabled_discount_accesses_view_avg_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Avg_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "disabled_discount_accesses_view". All fields are combined with a logical 'AND'. */
export type Disabled_Discount_Accesses_View_Bool_Exp = {
  _and?: InputMaybe<Array<Disabled_Discount_Accesses_View_Bool_Exp>>;
  _not?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
  _or?: InputMaybe<Array<Disabled_Discount_Accesses_View_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount_id?: InputMaybe<Uuid_Comparison_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remind_after_subscription?: InputMaybe<String_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Disabled_Discount_Accesses_View_Max_Fields = {
  __typename?: 'disabled_discount_accesses_view_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Disabled_Discount_Accesses_View_Min_Fields = {
  __typename?: 'disabled_discount_accesses_view_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "disabled_discount_accesses_view". */
export type Disabled_Discount_Accesses_View_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** select columns of table "disabled_discount_accesses_view" */
export enum Disabled_Discount_Accesses_View_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RemindAfterSubscription = 'remind_after_subscription',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** select "disabled_discount_accesses_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "disabled_discount_accesses_view" */
export enum Disabled_Discount_Accesses_View_Select_Column_Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "disabled_discount_accesses_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "disabled_discount_accesses_view" */
export enum Disabled_Discount_Accesses_View_Select_Column_Disabled_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** aggregate stddev on columns */
export type Disabled_Discount_Accesses_View_Stddev_Fields = {
  __typename?: 'disabled_discount_accesses_view_stddev_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Stddev_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Disabled_Discount_Accesses_View_Stddev_Pop_Fields = {
  __typename?: 'disabled_discount_accesses_view_stddev_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Stddev_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Disabled_Discount_Accesses_View_Stddev_Samp_Fields = {
  __typename?: 'disabled_discount_accesses_view_stddev_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Stddev_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Disabled_Discount_Accesses_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Disabled_Discount_Accesses_View_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Disabled_Discount_Accesses_View_Sum_Fields = {
  __typename?: 'disabled_discount_accesses_view_sum_fields';
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Sum_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Disabled_Discount_Accesses_View_Var_Pop_Fields = {
  __typename?: 'disabled_discount_accesses_view_var_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Var_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Disabled_Discount_Accesses_View_Var_Samp_Fields = {
  __typename?: 'disabled_discount_accesses_view_var_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Var_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Disabled_Discount_Accesses_View_Variance_Fields = {
  __typename?: 'disabled_discount_accesses_view_variance_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "disabled_discount_accesses_view" */
export type Disabled_Discount_Accesses_View_Variance_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** columns and relationships of "discount" */
export type Discount = {
  __typename?: 'discount';
  days: Scalars['Int']['output'];
  /** An array relationship */
  disabled_accesses: Array<Disabled_Discount_Accesses_View>;
  /** An aggregate relationship */
  disabled_accesses_aggregate: Disabled_Discount_Accesses_View_Aggregate;
  /** An array relationship */
  discount_proposals: Array<Discount_Proposal>;
  /** An aggregate relationship */
  discount_proposals_aggregate: Discount_Proposal_Aggregate;
  id: Scalars['uuid']['output'];
  paywall_id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Relative_Position_Enum_Enum>;
  /** An object relationship */
  relative_position_enum?: Maybe<Relative_Position_Enum>;
  relative_position_prisma: Scalars['relative_position_enum_prisma']['output'];
  /** An array relationship */
  required_accesses: Array<Required_Discount_Accesses_View>;
  /** An aggregate relationship */
  required_accesses_aggregate: Required_Discount_Accesses_View_Aggregate;
};


/** columns and relationships of "discount" */
export type DiscountDisabled_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountDisabled_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountDiscount_ProposalsArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountDiscount_Proposals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountRequired_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "discount" */
export type DiscountRequired_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};

/** aggregated selection of "discount" */
export type Discount_Aggregate = {
  __typename?: 'discount_aggregate';
  aggregate?: Maybe<Discount_Aggregate_Fields>;
  nodes: Array<Discount>;
};

export type Discount_Aggregate_Bool_Exp = {
  count?: InputMaybe<Discount_Aggregate_Bool_Exp_Count>;
};

export type Discount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Discount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "discount" */
export type Discount_Aggregate_Fields = {
  __typename?: 'discount_aggregate_fields';
  avg?: Maybe<Discount_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Discount_Max_Fields>;
  min?: Maybe<Discount_Min_Fields>;
  stddev?: Maybe<Discount_Stddev_Fields>;
  stddev_pop?: Maybe<Discount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discount_Stddev_Samp_Fields>;
  sum?: Maybe<Discount_Sum_Fields>;
  var_pop?: Maybe<Discount_Var_Pop_Fields>;
  var_samp?: Maybe<Discount_Var_Samp_Fields>;
  variance?: Maybe<Discount_Variance_Fields>;
};


/** aggregate fields of "discount" */
export type Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "discount" */
export type Discount_Aggregate_Order_By = {
  avg?: InputMaybe<Discount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Discount_Max_Order_By>;
  min?: InputMaybe<Discount_Min_Order_By>;
  stddev?: InputMaybe<Discount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Discount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Discount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Discount_Sum_Order_By>;
  var_pop?: InputMaybe<Discount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Discount_Var_Samp_Order_By>;
  variance?: InputMaybe<Discount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "discount" */
export type Discount_Arr_Rel_Insert_Input = {
  data: Array<Discount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Discount_On_Conflict>;
};

/** aggregate avg on columns */
export type Discount_Avg_Fields = {
  __typename?: 'discount_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "discount" */
export type Discount_Avg_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "discount". All fields are combined with a logical 'AND'. */
export type Discount_Bool_Exp = {
  _and?: InputMaybe<Array<Discount_Bool_Exp>>;
  _not?: InputMaybe<Discount_Bool_Exp>;
  _or?: InputMaybe<Array<Discount_Bool_Exp>>;
  days?: InputMaybe<Int_Comparison_Exp>;
  disabled_accesses?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
  disabled_accesses_aggregate?: InputMaybe<Disabled_Discount_Accesses_View_Aggregate_Bool_Exp>;
  discount_proposals?: InputMaybe<Discount_Proposal_Bool_Exp>;
  discount_proposals_aggregate?: InputMaybe<Discount_Proposal_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  relative_position?: InputMaybe<Relative_Position_Enum_Enum_Comparison_Exp>;
  relative_position_enum?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
  relative_position_prisma?: InputMaybe<Relative_Position_Enum_Prisma_Comparison_Exp>;
  required_accesses?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
  required_accesses_aggregate?: InputMaybe<Required_Discount_Accesses_View_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "discount" */
export enum Discount_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscountPkey = 'discount_pkey'
}

/** input type for incrementing numeric columns in table "discount" */
export type Discount_Inc_Input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "discount" */
export type Discount_Insert_Input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  disabled_accesses?: InputMaybe<Disabled_Discount_Accesses_View_Arr_Rel_Insert_Input>;
  discount_proposals?: InputMaybe<Discount_Proposal_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Relative_Position_Enum_Enum>;
  relative_position_enum?: InputMaybe<Relative_Position_Enum_Obj_Rel_Insert_Input>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  required_accesses?: InputMaybe<Required_Discount_Accesses_View_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Discount_Max_Fields = {
  __typename?: 'discount_max_fields';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** order by max() on columns of table "discount" */
export type Discount_Max_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Discount_Min_Fields = {
  __typename?: 'discount_min_fields';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** order by min() on columns of table "discount" */
export type Discount_Min_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "discount" */
export type Discount_Mutation_Response = {
  __typename?: 'discount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Discount>;
};

/** input type for inserting object relation for remote table "discount" */
export type Discount_Obj_Rel_Insert_Input = {
  data: Discount_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Discount_On_Conflict>;
};

/** on_conflict condition type for table "discount" */
export type Discount_On_Conflict = {
  constraint: Discount_Constraint;
  update_columns?: Array<Discount_Update_Column>;
  where?: InputMaybe<Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "discount". */
export type Discount_Order_By = {
  days?: InputMaybe<Order_By>;
  disabled_accesses_aggregate?: InputMaybe<Disabled_Discount_Accesses_View_Aggregate_Order_By>;
  discount_proposals_aggregate?: InputMaybe<Discount_Proposal_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position?: InputMaybe<Order_By>;
  relative_position_enum?: InputMaybe<Relative_Position_Enum_Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
  required_accesses_aggregate?: InputMaybe<Required_Discount_Accesses_View_Aggregate_Order_By>;
};

/** primary key columns input for table: discount */
export type Discount_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "discount_proposal" */
export type Discount_Proposal = {
  __typename?: 'discount_proposal';
  /** An object relationship */
  discount: Discount;
  discount_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "discount_proposal" */
export type Discount_Proposal_Aggregate = {
  __typename?: 'discount_proposal_aggregate';
  aggregate?: Maybe<Discount_Proposal_Aggregate_Fields>;
  nodes: Array<Discount_Proposal>;
};

export type Discount_Proposal_Aggregate_Bool_Exp = {
  count?: InputMaybe<Discount_Proposal_Aggregate_Bool_Exp_Count>;
};

export type Discount_Proposal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Discount_Proposal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "discount_proposal" */
export type Discount_Proposal_Aggregate_Fields = {
  __typename?: 'discount_proposal_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Discount_Proposal_Max_Fields>;
  min?: Maybe<Discount_Proposal_Min_Fields>;
};


/** aggregate fields of "discount_proposal" */
export type Discount_Proposal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "discount_proposal" */
export type Discount_Proposal_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Discount_Proposal_Max_Order_By>;
  min?: InputMaybe<Discount_Proposal_Min_Order_By>;
};

/** input type for inserting array relation for remote table "discount_proposal" */
export type Discount_Proposal_Arr_Rel_Insert_Input = {
  data: Array<Discount_Proposal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Discount_Proposal_On_Conflict>;
};

/** Boolean expression to filter rows from the table "discount_proposal". All fields are combined with a logical 'AND'. */
export type Discount_Proposal_Bool_Exp = {
  _and?: InputMaybe<Array<Discount_Proposal_Bool_Exp>>;
  _not?: InputMaybe<Discount_Proposal_Bool_Exp>;
  _or?: InputMaybe<Array<Discount_Proposal_Bool_Exp>>;
  discount?: InputMaybe<Discount_Bool_Exp>;
  discount_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "discount_proposal" */
export enum Discount_Proposal_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscountProposalPkey = 'discount_proposal_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  DiscountProposalProfileIdUnique = 'discount_proposal_profile_id_unique'
}

/** input type for inserting data into table "discount_proposal" */
export type Discount_Proposal_Insert_Input = {
  discount?: InputMaybe<Discount_Obj_Rel_Insert_Input>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Discount_Proposal_Max_Fields = {
  __typename?: 'discount_proposal_max_fields';
  discount_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "discount_proposal" */
export type Discount_Proposal_Max_Order_By = {
  discount_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Discount_Proposal_Min_Fields = {
  __typename?: 'discount_proposal_min_fields';
  discount_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "discount_proposal" */
export type Discount_Proposal_Min_Order_By = {
  discount_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "discount_proposal" */
export type Discount_Proposal_Mutation_Response = {
  __typename?: 'discount_proposal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Discount_Proposal>;
};

/** input type for inserting object relation for remote table "discount_proposal" */
export type Discount_Proposal_Obj_Rel_Insert_Input = {
  data: Discount_Proposal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Discount_Proposal_On_Conflict>;
};

/** on_conflict condition type for table "discount_proposal" */
export type Discount_Proposal_On_Conflict = {
  constraint: Discount_Proposal_Constraint;
  update_columns?: Array<Discount_Proposal_Update_Column>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};

/** Ordering options when selecting data from "discount_proposal". */
export type Discount_Proposal_Order_By = {
  discount?: InputMaybe<Discount_Order_By>;
  discount_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: discount_proposal */
export type Discount_Proposal_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "discount_proposal" */
export enum Discount_Proposal_Select_Column {
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id'
}

/** input type for updating data in table "discount_proposal" */
export type Discount_Proposal_Set_Input = {
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "discount_proposal" */
export type Discount_Proposal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discount_Proposal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Discount_Proposal_Stream_Cursor_Value_Input = {
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "discount_proposal" */
export enum Discount_Proposal_Update_Column {
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id'
}

export type Discount_Proposal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Discount_Proposal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Discount_Proposal_Bool_Exp;
};

/** select columns of table "discount" */
export enum Discount_Select_Column {
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelativePosition = 'relative_position',
  /** column name */
  RelativePositionPrisma = 'relative_position_prisma'
}

/** input type for updating data in table "discount" */
export type Discount_Set_Input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Relative_Position_Enum_Enum>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate stddev on columns */
export type Discount_Stddev_Fields = {
  __typename?: 'discount_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "discount" */
export type Discount_Stddev_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Discount_Stddev_Pop_Fields = {
  __typename?: 'discount_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "discount" */
export type Discount_Stddev_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Discount_Stddev_Samp_Fields = {
  __typename?: 'discount_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "discount" */
export type Discount_Stddev_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "discount" */
export type Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Discount_Stream_Cursor_Value_Input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Relative_Position_Enum_Enum>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate sum on columns */
export type Discount_Sum_Fields = {
  __typename?: 'discount_sum_fields';
  days?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "discount" */
export type Discount_Sum_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "discount" */
export enum Discount_Update_Column {
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelativePosition = 'relative_position',
  /** column name */
  RelativePositionPrisma = 'relative_position_prisma'
}

export type Discount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Discount_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Discount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Discount_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Discount_Var_Pop_Fields = {
  __typename?: 'discount_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "discount" */
export type Discount_Var_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Discount_Var_Samp_Fields = {
  __typename?: 'discount_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "discount" */
export type Discount_Var_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Discount_Variance_Fields = {
  __typename?: 'discount_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "discount" */
export type Discount_Variance_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "favorite" */
export type Favorite = {
  __typename?: 'favorite';
  added_date: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['uuid']['output'];
  /** An object relationship */
  track: Track;
  track_id: Scalars['uuid']['output'];
};

/** aggregated selection of "favorite" */
export type Favorite_Aggregate = {
  __typename?: 'favorite_aggregate';
  aggregate?: Maybe<Favorite_Aggregate_Fields>;
  nodes: Array<Favorite>;
};

export type Favorite_Aggregate_Bool_Exp = {
  count?: InputMaybe<Favorite_Aggregate_Bool_Exp_Count>;
};

export type Favorite_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Favorite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Favorite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "favorite" */
export type Favorite_Aggregate_Fields = {
  __typename?: 'favorite_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Favorite_Max_Fields>;
  min?: Maybe<Favorite_Min_Fields>;
};


/** aggregate fields of "favorite" */
export type Favorite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Favorite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "favorite" */
export type Favorite_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Favorite_Max_Order_By>;
  min?: InputMaybe<Favorite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "favorite" */
export type Favorite_Arr_Rel_Insert_Input = {
  data: Array<Favorite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Favorite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "favorite". All fields are combined with a logical 'AND'. */
export type Favorite_Bool_Exp = {
  _and?: InputMaybe<Array<Favorite_Bool_Exp>>;
  _not?: InputMaybe<Favorite_Bool_Exp>;
  _or?: InputMaybe<Array<Favorite_Bool_Exp>>;
  added_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  track?: InputMaybe<Track_Bool_Exp>;
  track_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "favorite" */
export enum Favorite_Constraint {
  /** unique or primary key constraint on columns "id" */
  FavoritePkey = 'favorite_pkey'
}

/** input type for inserting data into table "favorite" */
export type Favorite_Insert_Input = {
  added_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track?: InputMaybe<Track_Obj_Rel_Insert_Input>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Favorite_Max_Fields = {
  __typename?: 'favorite_max_fields';
  added_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  track_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "favorite" */
export type Favorite_Max_Order_By = {
  added_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Favorite_Min_Fields = {
  __typename?: 'favorite_min_fields';
  added_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  track_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "favorite" */
export type Favorite_Min_Order_By = {
  added_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "favorite" */
export type Favorite_Mutation_Response = {
  __typename?: 'favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Favorite>;
};

/** on_conflict condition type for table "favorite" */
export type Favorite_On_Conflict = {
  constraint: Favorite_Constraint;
  update_columns?: Array<Favorite_Update_Column>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "favorite". */
export type Favorite_Order_By = {
  added_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track?: InputMaybe<Track_Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: favorite */
export type Favorite_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "favorite" */
export enum Favorite_Select_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TrackId = 'track_id'
}

/** input type for updating data in table "favorite" */
export type Favorite_Set_Input = {
  added_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "favorite" */
export type Favorite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Favorite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Favorite_Stream_Cursor_Value_Input = {
  added_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "favorite" */
export enum Favorite_Update_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TrackId = 'track_id'
}

export type Favorite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Favorite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Favorite_Bool_Exp;
};

/** columns and relationships of "feedback" */
export type Feedback = {
  __typename?: 'feedback';
  date: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

/** aggregated selection of "feedback" */
export type Feedback_Aggregate = {
  __typename?: 'feedback_aggregate';
  aggregate?: Maybe<Feedback_Aggregate_Fields>;
  nodes: Array<Feedback>;
};

/** aggregate fields of "feedback" */
export type Feedback_Aggregate_Fields = {
  __typename?: 'feedback_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Feedback_Max_Fields>;
  min?: Maybe<Feedback_Min_Fields>;
};


/** aggregate fields of "feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Feedback_Bool_Exp>>;
  _not?: InputMaybe<Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Feedback_Bool_Exp>>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  date?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  __typename?: 'feedback_max_fields';
  date?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  __typename?: 'feedback_min_fields';
  date?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  __typename?: 'feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Feedback>;
};

/** on_conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  constraint: Feedback_Constraint;
  update_columns?: Array<Feedback_Update_Column>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback". */
export type Feedback_Order_By = {
  date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feedback */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Phone = 'phone'
}

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  date?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "feedback" */
export type Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "feedback" */
export enum Feedback_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Phone = 'phone'
}

export type Feedback_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feedback_Bool_Exp;
};

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  duration?: Maybe<Scalars['numeric']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  short_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  sounds: Array<Sound>;
  /** An aggregate relationship */
  sounds_aggregate: Sound_Aggregate;
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: Track_Aggregate;
  url: Scalars['String']['output'];
};


/** columns and relationships of "file" */
export type FileSoundsArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileSounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTracksArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


/** columns and relationships of "file" */
export type FileTracks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};

/** aggregated selection of "file" */
export type File_Aggregate = {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "file" */
export type File_Aggregate_Fields = {
  __typename?: 'file_aggregate_fields';
  avg?: Maybe<File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
  stddev?: Maybe<File_Stddev_Fields>;
  stddev_pop?: Maybe<File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Stddev_Samp_Fields>;
  sum?: Maybe<File_Sum_Fields>;
  var_pop?: Maybe<File_Var_Pop_Fields>;
  var_samp?: Maybe<File_Var_Samp_Fields>;
  variance?: Maybe<File_Variance_Fields>;
};


/** aggregate fields of "file" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type File_Avg_Fields = {
  __typename?: 'file_avg_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  duration?: InputMaybe<Numeric_Comparison_Exp>;
  encoding?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mimetype?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  short_id?: InputMaybe<String_Comparison_Exp>;
  sounds?: InputMaybe<Sound_Bool_Exp>;
  sounds_aggregate?: InputMaybe<Sound_Aggregate_Bool_Exp>;
  tracks?: InputMaybe<Track_Bool_Exp>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "file" */
export enum File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'file_pkey'
}

/** input type for incrementing numeric columns in table "file" */
export type File_Inc_Input = {
  duration?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "file" */
export type File_Insert_Input = {
  duration?: InputMaybe<Scalars['numeric']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  short_id?: InputMaybe<Scalars['String']['input']>;
  sounds?: InputMaybe<Sound_Arr_Rel_Insert_Input>;
  tracks?: InputMaybe<Track_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'file_max_fields';
  duration?: Maybe<Scalars['numeric']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  short_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'file_min_fields';
  duration?: Maybe<Scalars['numeric']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  short_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on_conflict condition type for table "file" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  duration?: InputMaybe<Order_By>;
  encoding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimetype?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  short_id?: InputMaybe<Order_By>;
  sounds_aggregate?: InputMaybe<Sound_Aggregate_Order_By>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Order_By>;
};

/** primary key columns input for table: file */
export type File_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  Duration = 'duration',
  /** column name */
  Encoding = 'encoding',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  ShortId = 'short_id'
}

/** input type for updating data in table "file" */
export type File_Set_Input = {
  duration?: InputMaybe<Scalars['numeric']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  short_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type File_Stddev_Fields = {
  __typename?: 'file_stddev_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type File_Stddev_Pop_Fields = {
  __typename?: 'file_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type File_Stddev_Samp_Fields = {
  __typename?: 'file_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  duration?: InputMaybe<Scalars['numeric']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  short_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type File_Sum_Fields = {
  __typename?: 'file_sum_fields';
  duration?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "file" */
export enum File_Update_Column {
  /** column name */
  Duration = 'duration',
  /** column name */
  Encoding = 'encoding',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  ShortId = 'short_id'
}

export type File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Set_Input>;
  /** filter the rows which have to be updated */
  where: File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type File_Var_Pop_Fields = {
  __typename?: 'file_var_pop_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type File_Var_Samp_Fields = {
  __typename?: 'file_var_samp_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type File_Variance_Fields = {
  __typename?: 'file_variance_fields';
  duration?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "in_app_purchase" */
export type In_App_Purchase = {
  __typename?: 'in_app_purchase';
  /** An object relationship */
  access: Access;
  access_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  in_app_purchase_activations: Array<In_App_Purchase_Activation>;
  /** An aggregate relationship */
  in_app_purchase_activations_aggregate: In_App_Purchase_Activation_Aggregate;
  name: Scalars['String']['output'];
  plan_id?: Maybe<Array<Scalars['String']['output']>>;
  rc_plan_id?: Maybe<Scalars['String']['output']>;
  remind_before_resubscription?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "in_app_purchase" */
export type In_App_PurchaseIn_App_Purchase_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


/** columns and relationships of "in_app_purchase" */
export type In_App_PurchaseIn_App_Purchase_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};

/** columns and relationships of "in_app_purchase_activation" */
export type In_App_Purchase_Activation = {
  __typename?: 'in_app_purchase_activation';
  /** An object relationship */
  activation: Activation;
  activation_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  in_app_purchase: In_App_Purchase;
  in_app_purchase_id: Scalars['uuid']['output'];
  /** An object relationship */
  in_app_purchase_platform?: Maybe<In_App_Purchase_Platform>;
  platform?: Maybe<In_App_Purchase_Platform_Enum>;
  platform_prisma: Scalars['in_app_purchase_platform_prisma']['output'];
  purchase_id: Scalars['String']['output'];
  should_cancel?: Maybe<Scalars['Boolean']['output']>;
  will_renew: Scalars['Boolean']['output'];
};

/** aggregated selection of "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Aggregate = {
  __typename?: 'in_app_purchase_activation_aggregate';
  aggregate?: Maybe<In_App_Purchase_Activation_Aggregate_Fields>;
  nodes: Array<In_App_Purchase_Activation>;
};

export type In_App_Purchase_Activation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<In_App_Purchase_Activation_Aggregate_Bool_Exp_Count>;
};

export type In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_And = {
  arguments: In_App_Purchase_Activation_Select_Column_In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: In_App_Purchase_Activation_Select_Column_In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type In_App_Purchase_Activation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Aggregate_Fields = {
  __typename?: 'in_app_purchase_activation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<In_App_Purchase_Activation_Max_Fields>;
  min?: Maybe<In_App_Purchase_Activation_Min_Fields>;
};


/** aggregate fields of "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<In_App_Purchase_Activation_Max_Order_By>;
  min?: InputMaybe<In_App_Purchase_Activation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Arr_Rel_Insert_Input = {
  data: Array<In_App_Purchase_Activation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<In_App_Purchase_Activation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "in_app_purchase_activation". All fields are combined with a logical 'AND'. */
export type In_App_Purchase_Activation_Bool_Exp = {
  _and?: InputMaybe<Array<In_App_Purchase_Activation_Bool_Exp>>;
  _not?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  _or?: InputMaybe<Array<In_App_Purchase_Activation_Bool_Exp>>;
  activation?: InputMaybe<Activation_Bool_Exp>;
  activation_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Bool_Exp>;
  in_app_purchase_id?: InputMaybe<Uuid_Comparison_Exp>;
  in_app_purchase_platform?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
  platform?: InputMaybe<In_App_Purchase_Platform_Enum_Comparison_Exp>;
  platform_prisma?: InputMaybe<In_App_Purchase_Platform_Prisma_Comparison_Exp>;
  purchase_id?: InputMaybe<String_Comparison_Exp>;
  should_cancel?: InputMaybe<Boolean_Comparison_Exp>;
  will_renew?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "in_app_purchase_activation" */
export enum In_App_Purchase_Activation_Constraint {
  /** unique or primary key constraint on columns "activation_id" */
  InAppPurchaseActivationActivationIdUnique = 'in_app_purchase_activation_activation_id_unique',
  /** unique or primary key constraint on columns "id" */
  InAppPurchaseActivationPkey = 'in_app_purchase_activation_pkey'
}

/** input type for inserting data into table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Insert_Input = {
  activation?: InputMaybe<Activation_Obj_Rel_Insert_Input>;
  activation_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Obj_Rel_Insert_Input>;
  in_app_purchase_id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase_platform?: InputMaybe<In_App_Purchase_Platform_Obj_Rel_Insert_Input>;
  platform?: InputMaybe<In_App_Purchase_Platform_Enum>;
  platform_prisma?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  should_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  will_renew?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type In_App_Purchase_Activation_Max_Fields = {
  __typename?: 'in_app_purchase_activation_max_fields';
  activation_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  in_app_purchase_id?: Maybe<Scalars['uuid']['output']>;
  platform_prisma?: Maybe<Scalars['in_app_purchase_platform_prisma']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Max_Order_By = {
  activation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase_id?: InputMaybe<Order_By>;
  platform_prisma?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type In_App_Purchase_Activation_Min_Fields = {
  __typename?: 'in_app_purchase_activation_min_fields';
  activation_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  in_app_purchase_id?: Maybe<Scalars['uuid']['output']>;
  platform_prisma?: Maybe<Scalars['in_app_purchase_platform_prisma']['output']>;
  purchase_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Min_Order_By = {
  activation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase_id?: InputMaybe<Order_By>;
  platform_prisma?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Mutation_Response = {
  __typename?: 'in_app_purchase_activation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<In_App_Purchase_Activation>;
};

/** input type for inserting object relation for remote table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Obj_Rel_Insert_Input = {
  data: In_App_Purchase_Activation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<In_App_Purchase_Activation_On_Conflict>;
};

/** on_conflict condition type for table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_On_Conflict = {
  constraint: In_App_Purchase_Activation_Constraint;
  update_columns?: Array<In_App_Purchase_Activation_Update_Column>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};

/** Ordering options when selecting data from "in_app_purchase_activation". */
export type In_App_Purchase_Activation_Order_By = {
  activation?: InputMaybe<Activation_Order_By>;
  activation_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase?: InputMaybe<In_App_Purchase_Order_By>;
  in_app_purchase_id?: InputMaybe<Order_By>;
  in_app_purchase_platform?: InputMaybe<In_App_Purchase_Platform_Order_By>;
  platform?: InputMaybe<Order_By>;
  platform_prisma?: InputMaybe<Order_By>;
  purchase_id?: InputMaybe<Order_By>;
  should_cancel?: InputMaybe<Order_By>;
  will_renew?: InputMaybe<Order_By>;
};

/** primary key columns input for table: in_app_purchase_activation */
export type In_App_Purchase_Activation_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "in_app_purchase_activation" */
export enum In_App_Purchase_Activation_Select_Column {
  /** column name */
  ActivationId = 'activation_id',
  /** column name */
  Id = 'id',
  /** column name */
  InAppPurchaseId = 'in_app_purchase_id',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformPrisma = 'platform_prisma',
  /** column name */
  PurchaseId = 'purchase_id',
  /** column name */
  ShouldCancel = 'should_cancel',
  /** column name */
  WillRenew = 'will_renew'
}

/** select "in_app_purchase_activation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "in_app_purchase_activation" */
export enum In_App_Purchase_Activation_Select_Column_In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShouldCancel = 'should_cancel',
  /** column name */
  WillRenew = 'will_renew'
}

/** select "in_app_purchase_activation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "in_app_purchase_activation" */
export enum In_App_Purchase_Activation_Select_Column_In_App_Purchase_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShouldCancel = 'should_cancel',
  /** column name */
  WillRenew = 'will_renew'
}

/** input type for updating data in table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Set_Input = {
  activation_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase_id?: InputMaybe<Scalars['uuid']['input']>;
  platform?: InputMaybe<In_App_Purchase_Platform_Enum>;
  platform_prisma?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  should_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  will_renew?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "in_app_purchase_activation" */
export type In_App_Purchase_Activation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: In_App_Purchase_Activation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type In_App_Purchase_Activation_Stream_Cursor_Value_Input = {
  activation_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase_id?: InputMaybe<Scalars['uuid']['input']>;
  platform?: InputMaybe<In_App_Purchase_Platform_Enum>;
  platform_prisma?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  purchase_id?: InputMaybe<Scalars['String']['input']>;
  should_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  will_renew?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "in_app_purchase_activation" */
export enum In_App_Purchase_Activation_Update_Column {
  /** column name */
  ActivationId = 'activation_id',
  /** column name */
  Id = 'id',
  /** column name */
  InAppPurchaseId = 'in_app_purchase_id',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformPrisma = 'platform_prisma',
  /** column name */
  PurchaseId = 'purchase_id',
  /** column name */
  ShouldCancel = 'should_cancel',
  /** column name */
  WillRenew = 'will_renew'
}

export type In_App_Purchase_Activation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<In_App_Purchase_Activation_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_App_Purchase_Activation_Bool_Exp;
};

/** aggregated selection of "in_app_purchase" */
export type In_App_Purchase_Aggregate = {
  __typename?: 'in_app_purchase_aggregate';
  aggregate?: Maybe<In_App_Purchase_Aggregate_Fields>;
  nodes: Array<In_App_Purchase>;
};

/** aggregate fields of "in_app_purchase" */
export type In_App_Purchase_Aggregate_Fields = {
  __typename?: 'in_app_purchase_aggregate_fields';
  avg?: Maybe<In_App_Purchase_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<In_App_Purchase_Max_Fields>;
  min?: Maybe<In_App_Purchase_Min_Fields>;
  stddev?: Maybe<In_App_Purchase_Stddev_Fields>;
  stddev_pop?: Maybe<In_App_Purchase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<In_App_Purchase_Stddev_Samp_Fields>;
  sum?: Maybe<In_App_Purchase_Sum_Fields>;
  var_pop?: Maybe<In_App_Purchase_Var_Pop_Fields>;
  var_samp?: Maybe<In_App_Purchase_Var_Samp_Fields>;
  variance?: Maybe<In_App_Purchase_Variance_Fields>;
};


/** aggregate fields of "in_app_purchase" */
export type In_App_Purchase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<In_App_Purchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type In_App_Purchase_Avg_Fields = {
  __typename?: 'in_app_purchase_avg_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "in_app_purchase". All fields are combined with a logical 'AND'. */
export type In_App_Purchase_Bool_Exp = {
  _and?: InputMaybe<Array<In_App_Purchase_Bool_Exp>>;
  _not?: InputMaybe<In_App_Purchase_Bool_Exp>;
  _or?: InputMaybe<Array<In_App_Purchase_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  in_app_purchase_activations?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  in_app_purchase_activations_aggregate?: InputMaybe<In_App_Purchase_Activation_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_id?: InputMaybe<String_Array_Comparison_Exp>;
  rc_plan_id?: InputMaybe<String_Comparison_Exp>;
  remind_before_resubscription?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "in_app_purchase" */
export enum In_App_Purchase_Constraint {
  /** unique or primary key constraint on columns "access_id" */
  InAppPurchaseAccessIdUnique = 'in_app_purchase_access_id_unique',
  /** unique or primary key constraint on columns "id" */
  InAppPurchasePkey = 'in_app_purchase_pkey',
  /** unique or primary key constraint on columns "plan_id" */
  InAppPurchasePlanIdUnique = 'in_app_purchase_plan_id_unique',
  /** unique or primary key constraint on columns "rc_plan_id" */
  InAppPurchaseRcPlanIdKey = 'in_app_purchase_rc_plan_id_key'
}

/** input type for incrementing numeric columns in table "in_app_purchase" */
export type In_App_Purchase_Inc_Input = {
  remind_before_resubscription?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "in_app_purchase" */
export type In_App_Purchase_Insert_Input = {
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  in_app_purchase_activations?: InputMaybe<In_App_Purchase_Activation_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Array<Scalars['String']['input']>>;
  rc_plan_id?: InputMaybe<Scalars['String']['input']>;
  remind_before_resubscription?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type In_App_Purchase_Max_Fields = {
  __typename?: 'in_app_purchase_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Array<Scalars['String']['output']>>;
  rc_plan_id?: Maybe<Scalars['String']['output']>;
  remind_before_resubscription?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type In_App_Purchase_Min_Fields = {
  __typename?: 'in_app_purchase_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Array<Scalars['String']['output']>>;
  rc_plan_id?: Maybe<Scalars['String']['output']>;
  remind_before_resubscription?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "in_app_purchase" */
export type In_App_Purchase_Mutation_Response = {
  __typename?: 'in_app_purchase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<In_App_Purchase>;
};

/** input type for inserting object relation for remote table "in_app_purchase" */
export type In_App_Purchase_Obj_Rel_Insert_Input = {
  data: In_App_Purchase_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<In_App_Purchase_On_Conflict>;
};

/** on_conflict condition type for table "in_app_purchase" */
export type In_App_Purchase_On_Conflict = {
  constraint: In_App_Purchase_Constraint;
  update_columns?: Array<In_App_Purchase_Update_Column>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};

/** Ordering options when selecting data from "in_app_purchase". */
export type In_App_Purchase_Order_By = {
  access?: InputMaybe<Access_Order_By>;
  access_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_app_purchase_activations_aggregate?: InputMaybe<In_App_Purchase_Activation_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  rc_plan_id?: InputMaybe<Order_By>;
  remind_before_resubscription?: InputMaybe<Order_By>;
};

/** primary key columns input for table: in_app_purchase */
export type In_App_Purchase_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "in_app_purchase_platform" */
export type In_App_Purchase_Platform = {
  __typename?: 'in_app_purchase_platform';
  /** An array relationship */
  in_app_purchase_activations: Array<In_App_Purchase_Activation>;
  /** An aggregate relationship */
  in_app_purchase_activations_aggregate: In_App_Purchase_Activation_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "in_app_purchase_platform" */
export type In_App_Purchase_PlatformIn_App_Purchase_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


/** columns and relationships of "in_app_purchase_platform" */
export type In_App_Purchase_PlatformIn_App_Purchase_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};

/** aggregated selection of "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Aggregate = {
  __typename?: 'in_app_purchase_platform_aggregate';
  aggregate?: Maybe<In_App_Purchase_Platform_Aggregate_Fields>;
  nodes: Array<In_App_Purchase_Platform>;
};

/** aggregate fields of "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Aggregate_Fields = {
  __typename?: 'in_app_purchase_platform_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<In_App_Purchase_Platform_Max_Fields>;
  min?: Maybe<In_App_Purchase_Platform_Min_Fields>;
};


/** aggregate fields of "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<In_App_Purchase_Platform_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "in_app_purchase_platform". All fields are combined with a logical 'AND'. */
export type In_App_Purchase_Platform_Bool_Exp = {
  _and?: InputMaybe<Array<In_App_Purchase_Platform_Bool_Exp>>;
  _not?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
  _or?: InputMaybe<Array<In_App_Purchase_Platform_Bool_Exp>>;
  in_app_purchase_activations?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
  in_app_purchase_activations_aggregate?: InputMaybe<In_App_Purchase_Activation_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "in_app_purchase_platform" */
export enum In_App_Purchase_Platform_Constraint {
  /** unique or primary key constraint on columns "value" */
  InAppPurchasePlatformPkey = 'in_app_purchase_platform_pkey'
}

export enum In_App_Purchase_Platform_Enum {
  Android = 'android',
  Ios = 'ios'
}

/** Boolean expression to compare columns of type "in_app_purchase_platform_enum". All fields are combined with logical 'AND'. */
export type In_App_Purchase_Platform_Enum_Comparison_Exp = {
  _eq?: InputMaybe<In_App_Purchase_Platform_Enum>;
  _in?: InputMaybe<Array<In_App_Purchase_Platform_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<In_App_Purchase_Platform_Enum>;
  _nin?: InputMaybe<Array<In_App_Purchase_Platform_Enum>>;
};

/** input type for inserting data into table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Insert_Input = {
  in_app_purchase_activations?: InputMaybe<In_App_Purchase_Activation_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type In_App_Purchase_Platform_Max_Fields = {
  __typename?: 'in_app_purchase_platform_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type In_App_Purchase_Platform_Min_Fields = {
  __typename?: 'in_app_purchase_platform_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Mutation_Response = {
  __typename?: 'in_app_purchase_platform_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<In_App_Purchase_Platform>;
};

/** input type for inserting object relation for remote table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Obj_Rel_Insert_Input = {
  data: In_App_Purchase_Platform_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<In_App_Purchase_Platform_On_Conflict>;
};

/** on_conflict condition type for table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_On_Conflict = {
  constraint: In_App_Purchase_Platform_Constraint;
  update_columns?: Array<In_App_Purchase_Platform_Update_Column>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};

/** Ordering options when selecting data from "in_app_purchase_platform". */
export type In_App_Purchase_Platform_Order_By = {
  in_app_purchase_activations_aggregate?: InputMaybe<In_App_Purchase_Activation_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: in_app_purchase_platform */
export type In_App_Purchase_Platform_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "in_app_purchase_platform_prisma". All fields are combined with logical 'AND'. */
export type In_App_Purchase_Platform_Prisma_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _gt?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _gte?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _in?: InputMaybe<Array<Scalars['in_app_purchase_platform_prisma']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _lte?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _neq?: InputMaybe<Scalars['in_app_purchase_platform_prisma']['input']>;
  _nin?: InputMaybe<Array<Scalars['in_app_purchase_platform_prisma']['input']>>;
};

/** select columns of table "in_app_purchase_platform" */
export enum In_App_Purchase_Platform_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "in_app_purchase_platform" */
export type In_App_Purchase_Platform_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: In_App_Purchase_Platform_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type In_App_Purchase_Platform_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "in_app_purchase_platform" */
export enum In_App_Purchase_Platform_Update_Column {
  /** column name */
  Value = 'value'
}

export type In_App_Purchase_Platform_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<In_App_Purchase_Platform_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_App_Purchase_Platform_Bool_Exp;
};

/** select columns of table "in_app_purchase" */
export enum In_App_Purchase_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  RcPlanId = 'rc_plan_id',
  /** column name */
  RemindBeforeResubscription = 'remind_before_resubscription'
}

/** input type for updating data in table "in_app_purchase" */
export type In_App_Purchase_Set_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Array<Scalars['String']['input']>>;
  rc_plan_id?: InputMaybe<Scalars['String']['input']>;
  remind_before_resubscription?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type In_App_Purchase_Stddev_Fields = {
  __typename?: 'in_app_purchase_stddev_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type In_App_Purchase_Stddev_Pop_Fields = {
  __typename?: 'in_app_purchase_stddev_pop_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type In_App_Purchase_Stddev_Samp_Fields = {
  __typename?: 'in_app_purchase_stddev_samp_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "in_app_purchase" */
export type In_App_Purchase_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: In_App_Purchase_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type In_App_Purchase_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Array<Scalars['String']['input']>>;
  rc_plan_id?: InputMaybe<Scalars['String']['input']>;
  remind_before_resubscription?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type In_App_Purchase_Sum_Fields = {
  __typename?: 'in_app_purchase_sum_fields';
  remind_before_resubscription?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "in_app_purchase" */
export enum In_App_Purchase_Update_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  RcPlanId = 'rc_plan_id',
  /** column name */
  RemindBeforeResubscription = 'remind_before_resubscription'
}

export type In_App_Purchase_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<In_App_Purchase_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<In_App_Purchase_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_App_Purchase_Bool_Exp;
};

/** aggregate var_pop on columns */
export type In_App_Purchase_Var_Pop_Fields = {
  __typename?: 'in_app_purchase_var_pop_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type In_App_Purchase_Var_Samp_Fields = {
  __typename?: 'in_app_purchase_var_samp_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type In_App_Purchase_Variance_Fields = {
  __typename?: 'in_app_purchase_variance_fields';
  remind_before_resubscription?: Maybe<Scalars['Float']['output']>;
};

export type Increment_Listening_Count_Args = {
  _track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "listening" */
export type Listening = {
  __typename?: 'listening';
  count: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['uuid']['output'];
  /** An object relationship */
  track: Track;
  track_id: Scalars['uuid']['output'];
};

/** aggregated selection of "listening" */
export type Listening_Aggregate = {
  __typename?: 'listening_aggregate';
  aggregate?: Maybe<Listening_Aggregate_Fields>;
  nodes: Array<Listening>;
};

export type Listening_Aggregate_Bool_Exp = {
  count?: InputMaybe<Listening_Aggregate_Bool_Exp_Count>;
};

export type Listening_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Listening_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Listening_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "listening" */
export type Listening_Aggregate_Fields = {
  __typename?: 'listening_aggregate_fields';
  avg?: Maybe<Listening_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Listening_Max_Fields>;
  min?: Maybe<Listening_Min_Fields>;
  stddev?: Maybe<Listening_Stddev_Fields>;
  stddev_pop?: Maybe<Listening_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Listening_Stddev_Samp_Fields>;
  sum?: Maybe<Listening_Sum_Fields>;
  var_pop?: Maybe<Listening_Var_Pop_Fields>;
  var_samp?: Maybe<Listening_Var_Samp_Fields>;
  variance?: Maybe<Listening_Variance_Fields>;
};


/** aggregate fields of "listening" */
export type Listening_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listening_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "listening" */
export type Listening_Aggregate_Order_By = {
  avg?: InputMaybe<Listening_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Listening_Max_Order_By>;
  min?: InputMaybe<Listening_Min_Order_By>;
  stddev?: InputMaybe<Listening_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Listening_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Listening_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Listening_Sum_Order_By>;
  var_pop?: InputMaybe<Listening_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Listening_Var_Samp_Order_By>;
  variance?: InputMaybe<Listening_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "listening" */
export type Listening_Arr_Rel_Insert_Input = {
  data: Array<Listening_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listening_On_Conflict>;
};

/** aggregate avg on columns */
export type Listening_Avg_Fields = {
  __typename?: 'listening_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "listening" */
export type Listening_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "listening". All fields are combined with a logical 'AND'. */
export type Listening_Bool_Exp = {
  _and?: InputMaybe<Array<Listening_Bool_Exp>>;
  _not?: InputMaybe<Listening_Bool_Exp>;
  _or?: InputMaybe<Array<Listening_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  track?: InputMaybe<Track_Bool_Exp>;
  track_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "listening" */
export enum Listening_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListeningPkey = 'listening_pkey'
}

/** input type for incrementing numeric columns in table "listening" */
export type Listening_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "listening" */
export type Listening_Insert_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track?: InputMaybe<Track_Obj_Rel_Insert_Input>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Listening_Max_Fields = {
  __typename?: 'listening_max_fields';
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  track_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "listening" */
export type Listening_Max_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Listening_Min_Fields = {
  __typename?: 'listening_min_fields';
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  track_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "listening" */
export type Listening_Min_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "listening" */
export type Listening_Mutation_Response = {
  __typename?: 'listening_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Listening>;
};

/** on_conflict condition type for table "listening" */
export type Listening_On_Conflict = {
  constraint: Listening_Constraint;
  update_columns?: Array<Listening_Update_Column>;
  where?: InputMaybe<Listening_Bool_Exp>;
};

/** Ordering options when selecting data from "listening". */
export type Listening_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  track?: InputMaybe<Track_Order_By>;
  track_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listening */
export type Listening_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "listening" */
export enum Listening_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TrackId = 'track_id'
}

/** input type for updating data in table "listening" */
export type Listening_Set_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Listening_Stddev_Fields = {
  __typename?: 'listening_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "listening" */
export type Listening_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Listening_Stddev_Pop_Fields = {
  __typename?: 'listening_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "listening" */
export type Listening_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Listening_Stddev_Samp_Fields = {
  __typename?: 'listening_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "listening" */
export type Listening_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "listening" */
export type Listening_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Listening_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listening_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  track_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Listening_Sum_Fields = {
  __typename?: 'listening_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "listening" */
export type Listening_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** update columns of table "listening" */
export enum Listening_Update_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TrackId = 'track_id'
}

export type Listening_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Listening_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listening_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listening_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Listening_Var_Pop_Fields = {
  __typename?: 'listening_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "listening" */
export type Listening_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Listening_Var_Samp_Fields = {
  __typename?: 'listening_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "listening" */
export type Listening_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Listening_Variance_Fields = {
  __typename?: 'listening_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "listening" */
export type Listening_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
};

/** columns and relationships of "magenta_subscription" */
export type Magenta_Subscription = {
  __typename?: 'magenta_subscription';
  /** An object relationship */
  access: Access;
  access_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

/** aggregated selection of "magenta_subscription" */
export type Magenta_Subscription_Aggregate = {
  __typename?: 'magenta_subscription_aggregate';
  aggregate?: Maybe<Magenta_Subscription_Aggregate_Fields>;
  nodes: Array<Magenta_Subscription>;
};

/** aggregate fields of "magenta_subscription" */
export type Magenta_Subscription_Aggregate_Fields = {
  __typename?: 'magenta_subscription_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Magenta_Subscription_Max_Fields>;
  min?: Maybe<Magenta_Subscription_Min_Fields>;
};


/** aggregate fields of "magenta_subscription" */
export type Magenta_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Magenta_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "magenta_subscription". All fields are combined with a logical 'AND'. */
export type Magenta_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Magenta_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Magenta_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Magenta_Subscription_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "magenta_subscription" */
export enum Magenta_Subscription_Constraint {
  /** unique or primary key constraint on columns "access_id" */
  MagentaSubscriptionAccessIdUnique = 'magenta_subscription_access_id_unique',
  /** unique or primary key constraint on columns "id" */
  MagentaSubscriptionPkey = 'magenta_subscription_pkey',
  /** unique or primary key constraint on columns "sku" */
  MagentaSubscriptionSkuUnique = 'magenta_subscription_sku_unique'
}

/** input type for inserting data into table "magenta_subscription" */
export type Magenta_Subscription_Insert_Input = {
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Magenta_Subscription_Max_Fields = {
  __typename?: 'magenta_subscription_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Magenta_Subscription_Min_Fields = {
  __typename?: 'magenta_subscription_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "magenta_subscription" */
export type Magenta_Subscription_Mutation_Response = {
  __typename?: 'magenta_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Magenta_Subscription>;
};

/** input type for inserting object relation for remote table "magenta_subscription" */
export type Magenta_Subscription_Obj_Rel_Insert_Input = {
  data: Magenta_Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Magenta_Subscription_On_Conflict>;
};

/** on_conflict condition type for table "magenta_subscription" */
export type Magenta_Subscription_On_Conflict = {
  constraint: Magenta_Subscription_Constraint;
  update_columns?: Array<Magenta_Subscription_Update_Column>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "magenta_subscription". */
export type Magenta_Subscription_Order_By = {
  access?: InputMaybe<Access_Order_By>;
  access_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
};

/** primary key columns input for table: magenta_subscription */
export type Magenta_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "magenta_subscription" */
export enum Magenta_Subscription_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sku = 'sku'
}

/** input type for updating data in table "magenta_subscription" */
export type Magenta_Subscription_Set_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "magenta_subscription" */
export type Magenta_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Magenta_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Magenta_Subscription_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "magenta_subscription" */
export enum Magenta_Subscription_Update_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sku = 'sku'
}

export type Magenta_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Magenta_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Magenta_Subscription_Bool_Exp;
};

export type Merge_Anonymous_Profile_Args = {
  _anonymous_id?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  applySubscription: ActivationPayload;
  /** delete data from the table: "access" */
  delete_access?: Maybe<Access_Mutation_Response>;
  /** delete single row from the table: "access" */
  delete_access_by_pk?: Maybe<Access>;
  /** delete data from the table: "_access_title" */
  delete_access_title?: Maybe<Access_Title_Mutation_Response>;
  /** delete data from the table: "activation" */
  delete_activation?: Maybe<Activation_Mutation_Response>;
  /** delete single row from the table: "activation" */
  delete_activation_by_pk?: Maybe<Activation>;
  /** delete data from the table: "_activation_title" */
  delete_activation_title?: Maybe<Activation_Title_Mutation_Response>;
  /** delete data from the table: "code" */
  delete_code?: Maybe<Code_Mutation_Response>;
  /** delete single row from the table: "code" */
  delete_code_by_pk?: Maybe<Code>;
  /** delete data from the table: "collection" */
  delete_collection?: Maybe<Collection_Mutation_Response>;
  /** delete single row from the table: "collection" */
  delete_collection_by_pk?: Maybe<Collection>;
  /** delete data from the table: "_collection_title" */
  delete_collection_title?: Maybe<Collection_Title_Mutation_Response>;
  /** delete data from the table: "_disabled_access_for_discount" */
  delete_disabled_access_for_discount?: Maybe<Disabled_Access_For_Discount_Mutation_Response>;
  /** delete data from the table: "discount" */
  delete_discount?: Maybe<Discount_Mutation_Response>;
  /** delete single row from the table: "discount" */
  delete_discount_by_pk?: Maybe<Discount>;
  /** delete data from the table: "discount_proposal" */
  delete_discount_proposal?: Maybe<Discount_Proposal_Mutation_Response>;
  /** delete single row from the table: "discount_proposal" */
  delete_discount_proposal_by_pk?: Maybe<Discount_Proposal>;
  /** delete data from the table: "favorite" */
  delete_favorite?: Maybe<Favorite_Mutation_Response>;
  /** delete single row from the table: "favorite" */
  delete_favorite_by_pk?: Maybe<Favorite>;
  /** delete data from the table: "feedback" */
  delete_feedback?: Maybe<Feedback_Mutation_Response>;
  /** delete single row from the table: "feedback" */
  delete_feedback_by_pk?: Maybe<Feedback>;
  /** delete data from the table: "file" */
  delete_file?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "in_app_purchase" */
  delete_in_app_purchase?: Maybe<In_App_Purchase_Mutation_Response>;
  /** delete data from the table: "in_app_purchase_activation" */
  delete_in_app_purchase_activation?: Maybe<In_App_Purchase_Activation_Mutation_Response>;
  /** delete single row from the table: "in_app_purchase_activation" */
  delete_in_app_purchase_activation_by_pk?: Maybe<In_App_Purchase_Activation>;
  /** delete single row from the table: "in_app_purchase" */
  delete_in_app_purchase_by_pk?: Maybe<In_App_Purchase>;
  /** delete data from the table: "in_app_purchase_platform" */
  delete_in_app_purchase_platform?: Maybe<In_App_Purchase_Platform_Mutation_Response>;
  /** delete single row from the table: "in_app_purchase_platform" */
  delete_in_app_purchase_platform_by_pk?: Maybe<In_App_Purchase_Platform>;
  /** delete data from the table: "listening" */
  delete_listening?: Maybe<Listening_Mutation_Response>;
  /** delete single row from the table: "listening" */
  delete_listening_by_pk?: Maybe<Listening>;
  /** delete data from the table: "magenta_subscription" */
  delete_magenta_subscription?: Maybe<Magenta_Subscription_Mutation_Response>;
  /** delete single row from the table: "magenta_subscription" */
  delete_magenta_subscription_by_pk?: Maybe<Magenta_Subscription>;
  /** delete data from the table: "opening" */
  delete_opening?: Maybe<Opening_Mutation_Response>;
  /** delete single row from the table: "opening" */
  delete_opening_by_pk?: Maybe<Opening>;
  /** delete data from the table: "parameter" */
  delete_parameter?: Maybe<Parameter_Mutation_Response>;
  /** delete single row from the table: "parameter" */
  delete_parameter_by_pk?: Maybe<Parameter>;
  /** delete data from the table: "parameter_key" */
  delete_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** delete single row from the table: "parameter_key" */
  delete_parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  delete_profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "promo" */
  delete_promo?: Maybe<Promo_Mutation_Response>;
  /** delete single row from the table: "promo" */
  delete_promo_by_pk?: Maybe<Promo>;
  /** delete data from the table: "_promo_title" */
  delete_promo_title?: Maybe<Promo_Title_Mutation_Response>;
  /** delete data from the table: "purchase" */
  delete_purchase?: Maybe<Purchase_Mutation_Response>;
  /** delete single row from the table: "purchase" */
  delete_purchase_by_pk?: Maybe<Purchase>;
  /** delete data from the table: "question" */
  delete_question?: Maybe<Question_Mutation_Response>;
  /** delete single row from the table: "question" */
  delete_question_by_pk?: Maybe<Question>;
  /** delete data from the table: "question_screen" */
  delete_question_screen?: Maybe<Question_Screen_Mutation_Response>;
  /** delete single row from the table: "question_screen" */
  delete_question_screen_by_pk?: Maybe<Question_Screen>;
  /** delete data from the table: "relative_position_enum" */
  delete_relative_position_enum?: Maybe<Relative_Position_Enum_Mutation_Response>;
  /** delete single row from the table: "relative_position_enum" */
  delete_relative_position_enum_by_pk?: Maybe<Relative_Position_Enum>;
  /** delete data from the table: "_required_access_for_discount" */
  delete_required_access_for_discount?: Maybe<Required_Access_For_Discount_Mutation_Response>;
  /** delete data from the table: "session" */
  delete_session?: Maybe<Session_Mutation_Response>;
  /** delete single row from the table: "session" */
  delete_session_by_pk?: Maybe<Session>;
  /** delete data from the table: "sound" */
  delete_sound?: Maybe<Sound_Mutation_Response>;
  /** delete single row from the table: "sound" */
  delete_sound_by_pk?: Maybe<Sound>;
  /** delete data from the table: "title" */
  delete_title?: Maybe<Title_Mutation_Response>;
  /** delete single row from the table: "title" */
  delete_title_by_pk?: Maybe<Title>;
  /** delete data from the table: "track" */
  delete_track?: Maybe<Track_Mutation_Response>;
  /** delete single row from the table: "track" */
  delete_track_by_pk?: Maybe<Track>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "verification" */
  delete_verification?: Maybe<Verification_Mutation_Response>;
  /** delete single row from the table: "verification" */
  delete_verification_by_pk?: Maybe<Verification>;
  /** delete data from the table: "verification_type" */
  delete_verification_type?: Maybe<Verification_Type_Mutation_Response>;
  /** delete single row from the table: "verification_type" */
  delete_verification_type_by_pk?: Maybe<Verification_Type>;
  /** execute VOLATILE function "increment_listening_count" which returns "listening" */
  increment_listening_count?: Maybe<Listening>;
  /** insert data into the table: "access" */
  insert_access?: Maybe<Access_Mutation_Response>;
  /** insert a single row into the table: "access" */
  insert_access_one?: Maybe<Access>;
  /** insert data into the table: "_access_title" */
  insert_access_title?: Maybe<Access_Title_Mutation_Response>;
  /** insert a single row into the table: "_access_title" */
  insert_access_title_one?: Maybe<Access_Title>;
  /** insert data into the table: "activation" */
  insert_activation?: Maybe<Activation_Mutation_Response>;
  /** insert a single row into the table: "activation" */
  insert_activation_one?: Maybe<Activation>;
  /** insert data into the table: "_activation_title" */
  insert_activation_title?: Maybe<Activation_Title_Mutation_Response>;
  /** insert a single row into the table: "_activation_title" */
  insert_activation_title_one?: Maybe<Activation_Title>;
  /** insert data into the table: "code" */
  insert_code?: Maybe<Code_Mutation_Response>;
  /** insert a single row into the table: "code" */
  insert_code_one?: Maybe<Code>;
  /** insert data into the table: "collection" */
  insert_collection?: Maybe<Collection_Mutation_Response>;
  /** insert a single row into the table: "collection" */
  insert_collection_one?: Maybe<Collection>;
  /** insert data into the table: "_collection_title" */
  insert_collection_title?: Maybe<Collection_Title_Mutation_Response>;
  /** insert a single row into the table: "_collection_title" */
  insert_collection_title_one?: Maybe<Collection_Title>;
  /** insert data into the table: "_disabled_access_for_discount" */
  insert_disabled_access_for_discount?: Maybe<Disabled_Access_For_Discount_Mutation_Response>;
  /** insert a single row into the table: "_disabled_access_for_discount" */
  insert_disabled_access_for_discount_one?: Maybe<Disabled_Access_For_Discount>;
  /** insert data into the table: "discount" */
  insert_discount?: Maybe<Discount_Mutation_Response>;
  /** insert a single row into the table: "discount" */
  insert_discount_one?: Maybe<Discount>;
  /** insert data into the table: "discount_proposal" */
  insert_discount_proposal?: Maybe<Discount_Proposal_Mutation_Response>;
  /** insert a single row into the table: "discount_proposal" */
  insert_discount_proposal_one?: Maybe<Discount_Proposal>;
  /** insert data into the table: "favorite" */
  insert_favorite?: Maybe<Favorite_Mutation_Response>;
  /** insert a single row into the table: "favorite" */
  insert_favorite_one?: Maybe<Favorite>;
  /** insert data into the table: "feedback" */
  insert_feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  insert_feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "in_app_purchase" */
  insert_in_app_purchase?: Maybe<In_App_Purchase_Mutation_Response>;
  /** insert data into the table: "in_app_purchase_activation" */
  insert_in_app_purchase_activation?: Maybe<In_App_Purchase_Activation_Mutation_Response>;
  /** insert a single row into the table: "in_app_purchase_activation" */
  insert_in_app_purchase_activation_one?: Maybe<In_App_Purchase_Activation>;
  /** insert a single row into the table: "in_app_purchase" */
  insert_in_app_purchase_one?: Maybe<In_App_Purchase>;
  /** insert data into the table: "in_app_purchase_platform" */
  insert_in_app_purchase_platform?: Maybe<In_App_Purchase_Platform_Mutation_Response>;
  /** insert a single row into the table: "in_app_purchase_platform" */
  insert_in_app_purchase_platform_one?: Maybe<In_App_Purchase_Platform>;
  /** insert data into the table: "listening" */
  insert_listening?: Maybe<Listening_Mutation_Response>;
  /** insert a single row into the table: "listening" */
  insert_listening_one?: Maybe<Listening>;
  /** insert data into the table: "magenta_subscription" */
  insert_magenta_subscription?: Maybe<Magenta_Subscription_Mutation_Response>;
  /** insert a single row into the table: "magenta_subscription" */
  insert_magenta_subscription_one?: Maybe<Magenta_Subscription>;
  /** insert data into the table: "opening" */
  insert_opening?: Maybe<Opening_Mutation_Response>;
  /** insert a single row into the table: "opening" */
  insert_opening_one?: Maybe<Opening>;
  /** insert data into the table: "parameter" */
  insert_parameter?: Maybe<Parameter_Mutation_Response>;
  /** insert data into the table: "parameter_key" */
  insert_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** insert a single row into the table: "parameter_key" */
  insert_parameter_key_one?: Maybe<Parameter_Key>;
  /** insert a single row into the table: "parameter" */
  insert_parameter_one?: Maybe<Parameter>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "promo" */
  insert_promo?: Maybe<Promo_Mutation_Response>;
  /** insert a single row into the table: "promo" */
  insert_promo_one?: Maybe<Promo>;
  /** insert data into the table: "_promo_title" */
  insert_promo_title?: Maybe<Promo_Title_Mutation_Response>;
  /** insert a single row into the table: "_promo_title" */
  insert_promo_title_one?: Maybe<Promo_Title>;
  /** insert data into the table: "purchase" */
  insert_purchase?: Maybe<Purchase_Mutation_Response>;
  /** insert a single row into the table: "purchase" */
  insert_purchase_one?: Maybe<Purchase>;
  /** insert data into the table: "question" */
  insert_question?: Maybe<Question_Mutation_Response>;
  /** insert a single row into the table: "question" */
  insert_question_one?: Maybe<Question>;
  /** insert data into the table: "question_screen" */
  insert_question_screen?: Maybe<Question_Screen_Mutation_Response>;
  /** insert a single row into the table: "question_screen" */
  insert_question_screen_one?: Maybe<Question_Screen>;
  /** insert data into the table: "relative_position_enum" */
  insert_relative_position_enum?: Maybe<Relative_Position_Enum_Mutation_Response>;
  /** insert a single row into the table: "relative_position_enum" */
  insert_relative_position_enum_one?: Maybe<Relative_Position_Enum>;
  /** insert data into the table: "_required_access_for_discount" */
  insert_required_access_for_discount?: Maybe<Required_Access_For_Discount_Mutation_Response>;
  /** insert a single row into the table: "_required_access_for_discount" */
  insert_required_access_for_discount_one?: Maybe<Required_Access_For_Discount>;
  /** insert data into the table: "session" */
  insert_session?: Maybe<Session_Mutation_Response>;
  /** insert a single row into the table: "session" */
  insert_session_one?: Maybe<Session>;
  /** insert data into the table: "sound" */
  insert_sound?: Maybe<Sound_Mutation_Response>;
  /** insert a single row into the table: "sound" */
  insert_sound_one?: Maybe<Sound>;
  /** insert data into the table: "title" */
  insert_title?: Maybe<Title_Mutation_Response>;
  /** insert a single row into the table: "title" */
  insert_title_one?: Maybe<Title>;
  /** insert data into the table: "track" */
  insert_track?: Maybe<Track_Mutation_Response>;
  /** insert a single row into the table: "track" */
  insert_track_one?: Maybe<Track>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "verification" */
  insert_verification?: Maybe<Verification_Mutation_Response>;
  /** insert a single row into the table: "verification" */
  insert_verification_one?: Maybe<Verification>;
  /** insert data into the table: "verification_type" */
  insert_verification_type?: Maybe<Verification_Type_Mutation_Response>;
  /** insert a single row into the table: "verification_type" */
  insert_verification_type_one?: Maybe<Verification_Type>;
  login: AdminLoginPayload;
  login_with_anonymous_id: AuthPayload;
  login_with_verification: AuthPayload;
  /** execute VOLATILE function "merge_anonymous_profile" which returns "profile" */
  merge_anonymous_profile?: Maybe<Profile>;
  send_verification: SendVerificationResponse;
  /** execute VOLATILE function "set_last_played" which returns "opening" */
  set_last_played?: Maybe<Opening>;
  token: AuthPayload;
  /** update data of the table: "access" */
  update_access?: Maybe<Access_Mutation_Response>;
  /** update single row of the table: "access" */
  update_access_by_pk?: Maybe<Access>;
  /** update multiples rows of table: "access" */
  update_access_many?: Maybe<Array<Maybe<Access_Mutation_Response>>>;
  /** update data of the table: "_access_title" */
  update_access_title?: Maybe<Access_Title_Mutation_Response>;
  /** update multiples rows of table: "_access_title" */
  update_access_title_many?: Maybe<Array<Maybe<Access_Title_Mutation_Response>>>;
  /** update data of the table: "activation" */
  update_activation?: Maybe<Activation_Mutation_Response>;
  /** update single row of the table: "activation" */
  update_activation_by_pk?: Maybe<Activation>;
  /** update multiples rows of table: "activation" */
  update_activation_many?: Maybe<Array<Maybe<Activation_Mutation_Response>>>;
  /** update data of the table: "_activation_title" */
  update_activation_title?: Maybe<Activation_Title_Mutation_Response>;
  /** update multiples rows of table: "_activation_title" */
  update_activation_title_many?: Maybe<Array<Maybe<Activation_Title_Mutation_Response>>>;
  /** update data of the table: "code" */
  update_code?: Maybe<Code_Mutation_Response>;
  /** update single row of the table: "code" */
  update_code_by_pk?: Maybe<Code>;
  /** update multiples rows of table: "code" */
  update_code_many?: Maybe<Array<Maybe<Code_Mutation_Response>>>;
  /** update data of the table: "collection" */
  update_collection?: Maybe<Collection_Mutation_Response>;
  /** update single row of the table: "collection" */
  update_collection_by_pk?: Maybe<Collection>;
  /** update multiples rows of table: "collection" */
  update_collection_many?: Maybe<Array<Maybe<Collection_Mutation_Response>>>;
  /** update data of the table: "_collection_title" */
  update_collection_title?: Maybe<Collection_Title_Mutation_Response>;
  /** update multiples rows of table: "_collection_title" */
  update_collection_title_many?: Maybe<Array<Maybe<Collection_Title_Mutation_Response>>>;
  /** update data of the table: "_disabled_access_for_discount" */
  update_disabled_access_for_discount?: Maybe<Disabled_Access_For_Discount_Mutation_Response>;
  /** update multiples rows of table: "_disabled_access_for_discount" */
  update_disabled_access_for_discount_many?: Maybe<Array<Maybe<Disabled_Access_For_Discount_Mutation_Response>>>;
  /** update data of the table: "discount" */
  update_discount?: Maybe<Discount_Mutation_Response>;
  /** update single row of the table: "discount" */
  update_discount_by_pk?: Maybe<Discount>;
  /** update multiples rows of table: "discount" */
  update_discount_many?: Maybe<Array<Maybe<Discount_Mutation_Response>>>;
  /** update data of the table: "discount_proposal" */
  update_discount_proposal?: Maybe<Discount_Proposal_Mutation_Response>;
  /** update single row of the table: "discount_proposal" */
  update_discount_proposal_by_pk?: Maybe<Discount_Proposal>;
  /** update multiples rows of table: "discount_proposal" */
  update_discount_proposal_many?: Maybe<Array<Maybe<Discount_Proposal_Mutation_Response>>>;
  /** update data of the table: "favorite" */
  update_favorite?: Maybe<Favorite_Mutation_Response>;
  /** update single row of the table: "favorite" */
  update_favorite_by_pk?: Maybe<Favorite>;
  /** update multiples rows of table: "favorite" */
  update_favorite_many?: Maybe<Array<Maybe<Favorite_Mutation_Response>>>;
  /** update data of the table: "feedback" */
  update_feedback?: Maybe<Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  update_feedback_by_pk?: Maybe<Feedback>;
  /** update multiples rows of table: "feedback" */
  update_feedback_many?: Maybe<Array<Maybe<Feedback_Mutation_Response>>>;
  /** update data of the table: "file" */
  update_file?: Maybe<File_Mutation_Response>;
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<File>;
  /** update multiples rows of table: "file" */
  update_file_many?: Maybe<Array<Maybe<File_Mutation_Response>>>;
  /** update data of the table: "in_app_purchase" */
  update_in_app_purchase?: Maybe<In_App_Purchase_Mutation_Response>;
  /** update data of the table: "in_app_purchase_activation" */
  update_in_app_purchase_activation?: Maybe<In_App_Purchase_Activation_Mutation_Response>;
  /** update single row of the table: "in_app_purchase_activation" */
  update_in_app_purchase_activation_by_pk?: Maybe<In_App_Purchase_Activation>;
  /** update multiples rows of table: "in_app_purchase_activation" */
  update_in_app_purchase_activation_many?: Maybe<Array<Maybe<In_App_Purchase_Activation_Mutation_Response>>>;
  /** update single row of the table: "in_app_purchase" */
  update_in_app_purchase_by_pk?: Maybe<In_App_Purchase>;
  /** update multiples rows of table: "in_app_purchase" */
  update_in_app_purchase_many?: Maybe<Array<Maybe<In_App_Purchase_Mutation_Response>>>;
  /** update data of the table: "in_app_purchase_platform" */
  update_in_app_purchase_platform?: Maybe<In_App_Purchase_Platform_Mutation_Response>;
  /** update single row of the table: "in_app_purchase_platform" */
  update_in_app_purchase_platform_by_pk?: Maybe<In_App_Purchase_Platform>;
  /** update multiples rows of table: "in_app_purchase_platform" */
  update_in_app_purchase_platform_many?: Maybe<Array<Maybe<In_App_Purchase_Platform_Mutation_Response>>>;
  /** update data of the table: "listening" */
  update_listening?: Maybe<Listening_Mutation_Response>;
  /** update single row of the table: "listening" */
  update_listening_by_pk?: Maybe<Listening>;
  /** update multiples rows of table: "listening" */
  update_listening_many?: Maybe<Array<Maybe<Listening_Mutation_Response>>>;
  /** update data of the table: "magenta_subscription" */
  update_magenta_subscription?: Maybe<Magenta_Subscription_Mutation_Response>;
  /** update single row of the table: "magenta_subscription" */
  update_magenta_subscription_by_pk?: Maybe<Magenta_Subscription>;
  /** update multiples rows of table: "magenta_subscription" */
  update_magenta_subscription_many?: Maybe<Array<Maybe<Magenta_Subscription_Mutation_Response>>>;
  /** update data of the table: "opening" */
  update_opening?: Maybe<Opening_Mutation_Response>;
  /** update single row of the table: "opening" */
  update_opening_by_pk?: Maybe<Opening>;
  /** update multiples rows of table: "opening" */
  update_opening_many?: Maybe<Array<Maybe<Opening_Mutation_Response>>>;
  /** update data of the table: "parameter" */
  update_parameter?: Maybe<Parameter_Mutation_Response>;
  /** update single row of the table: "parameter" */
  update_parameter_by_pk?: Maybe<Parameter>;
  /** update data of the table: "parameter_key" */
  update_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** update single row of the table: "parameter_key" */
  update_parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** update multiples rows of table: "parameter_key" */
  update_parameter_key_many?: Maybe<Array<Maybe<Parameter_Key_Mutation_Response>>>;
  /** update multiples rows of table: "parameter" */
  update_parameter_many?: Maybe<Array<Maybe<Parameter_Mutation_Response>>>;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  update_profile_by_pk?: Maybe<Profile>;
  /** update multiples rows of table: "profile" */
  update_profile_many?: Maybe<Array<Maybe<Profile_Mutation_Response>>>;
  /** update data of the table: "promo" */
  update_promo?: Maybe<Promo_Mutation_Response>;
  /** update single row of the table: "promo" */
  update_promo_by_pk?: Maybe<Promo>;
  /** update multiples rows of table: "promo" */
  update_promo_many?: Maybe<Array<Maybe<Promo_Mutation_Response>>>;
  /** update data of the table: "_promo_title" */
  update_promo_title?: Maybe<Promo_Title_Mutation_Response>;
  /** update multiples rows of table: "_promo_title" */
  update_promo_title_many?: Maybe<Array<Maybe<Promo_Title_Mutation_Response>>>;
  /** update data of the table: "purchase" */
  update_purchase?: Maybe<Purchase_Mutation_Response>;
  /** update single row of the table: "purchase" */
  update_purchase_by_pk?: Maybe<Purchase>;
  /** update multiples rows of table: "purchase" */
  update_purchase_many?: Maybe<Array<Maybe<Purchase_Mutation_Response>>>;
  /** update data of the table: "question" */
  update_question?: Maybe<Question_Mutation_Response>;
  /** update single row of the table: "question" */
  update_question_by_pk?: Maybe<Question>;
  /** update multiples rows of table: "question" */
  update_question_many?: Maybe<Array<Maybe<Question_Mutation_Response>>>;
  /** update data of the table: "question_screen" */
  update_question_screen?: Maybe<Question_Screen_Mutation_Response>;
  /** update single row of the table: "question_screen" */
  update_question_screen_by_pk?: Maybe<Question_Screen>;
  /** update multiples rows of table: "question_screen" */
  update_question_screen_many?: Maybe<Array<Maybe<Question_Screen_Mutation_Response>>>;
  /** update data of the table: "relative_position_enum" */
  update_relative_position_enum?: Maybe<Relative_Position_Enum_Mutation_Response>;
  /** update single row of the table: "relative_position_enum" */
  update_relative_position_enum_by_pk?: Maybe<Relative_Position_Enum>;
  /** update multiples rows of table: "relative_position_enum" */
  update_relative_position_enum_many?: Maybe<Array<Maybe<Relative_Position_Enum_Mutation_Response>>>;
  /** update data of the table: "_required_access_for_discount" */
  update_required_access_for_discount?: Maybe<Required_Access_For_Discount_Mutation_Response>;
  /** update multiples rows of table: "_required_access_for_discount" */
  update_required_access_for_discount_many?: Maybe<Array<Maybe<Required_Access_For_Discount_Mutation_Response>>>;
  /** update data of the table: "session" */
  update_session?: Maybe<Session_Mutation_Response>;
  /** update single row of the table: "session" */
  update_session_by_pk?: Maybe<Session>;
  /** update multiples rows of table: "session" */
  update_session_many?: Maybe<Array<Maybe<Session_Mutation_Response>>>;
  /** update data of the table: "sound" */
  update_sound?: Maybe<Sound_Mutation_Response>;
  /** update single row of the table: "sound" */
  update_sound_by_pk?: Maybe<Sound>;
  /** update multiples rows of table: "sound" */
  update_sound_many?: Maybe<Array<Maybe<Sound_Mutation_Response>>>;
  /** update data of the table: "title" */
  update_title?: Maybe<Title_Mutation_Response>;
  /** update single row of the table: "title" */
  update_title_by_pk?: Maybe<Title>;
  /** update multiples rows of table: "title" */
  update_title_many?: Maybe<Array<Maybe<Title_Mutation_Response>>>;
  /** update data of the table: "track" */
  update_track?: Maybe<Track_Mutation_Response>;
  /** update single row of the table: "track" */
  update_track_by_pk?: Maybe<Track>;
  /** update multiples rows of table: "track" */
  update_track_many?: Maybe<Array<Maybe<Track_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "verification" */
  update_verification?: Maybe<Verification_Mutation_Response>;
  /** update single row of the table: "verification" */
  update_verification_by_pk?: Maybe<Verification>;
  /** update multiples rows of table: "verification" */
  update_verification_many?: Maybe<Array<Maybe<Verification_Mutation_Response>>>;
  /** update data of the table: "verification_type" */
  update_verification_type?: Maybe<Verification_Type_Mutation_Response>;
  /** update single row of the table: "verification_type" */
  update_verification_type_by_pk?: Maybe<Verification_Type>;
  /** update multiples rows of table: "verification_type" */
  update_verification_type_many?: Maybe<Array<Maybe<Verification_Type_Mutation_Response>>>;
  upload: UploadFilePayload;
};


/** mutation root */
export type Mutation_RootApplySubscriptionArgs = {
  email: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AccessArgs = {
  where: Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Access_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Access_TitleArgs = {
  where: Access_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ActivationArgs = {
  where: Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Activation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Activation_TitleArgs = {
  where: Activation_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CodeArgs = {
  where: Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Code_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CollectionArgs = {
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collection_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Collection_TitleArgs = {
  where: Collection_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Disabled_Access_For_DiscountArgs = {
  where: Disabled_Access_For_Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DiscountArgs = {
  where: Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discount_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Discount_ProposalArgs = {
  where: Discount_Proposal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discount_Proposal_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FavoriteArgs = {
  where: Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Favorite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackArgs = {
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_In_App_PurchaseArgs = {
  where: In_App_Purchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_In_App_Purchase_ActivationArgs = {
  where: In_App_Purchase_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_In_App_Purchase_Activation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_In_App_Purchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_In_App_Purchase_PlatformArgs = {
  where: In_App_Purchase_Platform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_In_App_Purchase_Platform_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ListeningArgs = {
  where: Listening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Listening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Magenta_SubscriptionArgs = {
  where: Magenta_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Magenta_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OpeningArgs = {
  where: Opening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Opening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ParameterArgs = {
  where: Parameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parameter_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Parameter_KeyArgs = {
  where: Parameter_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parameter_Key_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PromoArgs = {
  where: Promo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Promo_TitleArgs = {
  where: Promo_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PurchaseArgs = {
  where: Purchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionArgs = {
  where: Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Question_ScreenArgs = {
  where: Question_Screen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Screen_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Relative_Position_EnumArgs = {
  where: Relative_Position_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Relative_Position_Enum_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Required_Access_For_DiscountArgs = {
  where: Required_Access_For_Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SoundArgs = {
  where: Sound_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sound_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TitleArgs = {
  where: Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Title_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TrackArgs = {
  where: Track_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Track_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VerificationArgs = {
  where: Verification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Verification_TypeArgs = {
  where: Verification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootIncrement_Listening_CountArgs = {
  args: Increment_Listening_Count_Args;
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsert_AccessArgs = {
  objects: Array<Access_Insert_Input>;
  on_conflict?: InputMaybe<Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Access_OneArgs = {
  object: Access_Insert_Input;
  on_conflict?: InputMaybe<Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Access_TitleArgs = {
  objects: Array<Access_Title_Insert_Input>;
  on_conflict?: InputMaybe<Access_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Access_Title_OneArgs = {
  object: Access_Title_Insert_Input;
  on_conflict?: InputMaybe<Access_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ActivationArgs = {
  objects: Array<Activation_Insert_Input>;
  on_conflict?: InputMaybe<Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activation_OneArgs = {
  object: Activation_Insert_Input;
  on_conflict?: InputMaybe<Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activation_TitleArgs = {
  objects: Array<Activation_Title_Insert_Input>;
  on_conflict?: InputMaybe<Activation_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Activation_Title_OneArgs = {
  object: Activation_Title_Insert_Input;
  on_conflict?: InputMaybe<Activation_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CodeArgs = {
  objects: Array<Code_Insert_Input>;
  on_conflict?: InputMaybe<Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Code_OneArgs = {
  object: Code_Insert_Input;
  on_conflict?: InputMaybe<Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CollectionArgs = {
  objects: Array<Collection_Insert_Input>;
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_OneArgs = {
  object: Collection_Insert_Input;
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_TitleArgs = {
  objects: Array<Collection_Title_Insert_Input>;
  on_conflict?: InputMaybe<Collection_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_Title_OneArgs = {
  object: Collection_Title_Insert_Input;
  on_conflict?: InputMaybe<Collection_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Disabled_Access_For_DiscountArgs = {
  objects: Array<Disabled_Access_For_Discount_Insert_Input>;
  on_conflict?: InputMaybe<Disabled_Access_For_Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Disabled_Access_For_Discount_OneArgs = {
  object: Disabled_Access_For_Discount_Insert_Input;
  on_conflict?: InputMaybe<Disabled_Access_For_Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiscountArgs = {
  objects: Array<Discount_Insert_Input>;
  on_conflict?: InputMaybe<Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_OneArgs = {
  object: Discount_Insert_Input;
  on_conflict?: InputMaybe<Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_ProposalArgs = {
  objects: Array<Discount_Proposal_Insert_Input>;
  on_conflict?: InputMaybe<Discount_Proposal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_Proposal_OneArgs = {
  object: Discount_Proposal_Insert_Input;
  on_conflict?: InputMaybe<Discount_Proposal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FavoriteArgs = {
  objects: Array<Favorite_Insert_Input>;
  on_conflict?: InputMaybe<Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Favorite_OneArgs = {
  object: Favorite_Insert_Input;
  on_conflict?: InputMaybe<Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: Array<Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_PurchaseArgs = {
  objects: Array<In_App_Purchase_Insert_Input>;
  on_conflict?: InputMaybe<In_App_Purchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_Purchase_ActivationArgs = {
  objects: Array<In_App_Purchase_Activation_Insert_Input>;
  on_conflict?: InputMaybe<In_App_Purchase_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_Purchase_Activation_OneArgs = {
  object: In_App_Purchase_Activation_Insert_Input;
  on_conflict?: InputMaybe<In_App_Purchase_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_Purchase_OneArgs = {
  object: In_App_Purchase_Insert_Input;
  on_conflict?: InputMaybe<In_App_Purchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_Purchase_PlatformArgs = {
  objects: Array<In_App_Purchase_Platform_Insert_Input>;
  on_conflict?: InputMaybe<In_App_Purchase_Platform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_App_Purchase_Platform_OneArgs = {
  object: In_App_Purchase_Platform_Insert_Input;
  on_conflict?: InputMaybe<In_App_Purchase_Platform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ListeningArgs = {
  objects: Array<Listening_Insert_Input>;
  on_conflict?: InputMaybe<Listening_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Listening_OneArgs = {
  object: Listening_Insert_Input;
  on_conflict?: InputMaybe<Listening_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Magenta_SubscriptionArgs = {
  objects: Array<Magenta_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Magenta_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Magenta_Subscription_OneArgs = {
  object: Magenta_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Magenta_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OpeningArgs = {
  objects: Array<Opening_Insert_Input>;
  on_conflict?: InputMaybe<Opening_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opening_OneArgs = {
  object: Opening_Insert_Input;
  on_conflict?: InputMaybe<Opening_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParameterArgs = {
  objects: Array<Parameter_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parameter_KeyArgs = {
  objects: Array<Parameter_Key_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parameter_Key_OneArgs = {
  object: Parameter_Key_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parameter_OneArgs = {
  object: Parameter_Insert_Input;
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromoArgs = {
  objects: Array<Promo_Insert_Input>;
  on_conflict?: InputMaybe<Promo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_OneArgs = {
  object: Promo_Insert_Input;
  on_conflict?: InputMaybe<Promo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_TitleArgs = {
  objects: Array<Promo_Title_Insert_Input>;
  on_conflict?: InputMaybe<Promo_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_Title_OneArgs = {
  object: Promo_Title_Insert_Input;
  on_conflict?: InputMaybe<Promo_Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseArgs = {
  objects: Array<Purchase_Insert_Input>;
  on_conflict?: InputMaybe<Purchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_OneArgs = {
  object: Purchase_Insert_Input;
  on_conflict?: InputMaybe<Purchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionArgs = {
  objects: Array<Question_Insert_Input>;
  on_conflict?: InputMaybe<Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_OneArgs = {
  object: Question_Insert_Input;
  on_conflict?: InputMaybe<Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_ScreenArgs = {
  objects: Array<Question_Screen_Insert_Input>;
  on_conflict?: InputMaybe<Question_Screen_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Screen_OneArgs = {
  object: Question_Screen_Insert_Input;
  on_conflict?: InputMaybe<Question_Screen_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Relative_Position_EnumArgs = {
  objects: Array<Relative_Position_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Relative_Position_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Relative_Position_Enum_OneArgs = {
  object: Relative_Position_Enum_Insert_Input;
  on_conflict?: InputMaybe<Relative_Position_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Required_Access_For_DiscountArgs = {
  objects: Array<Required_Access_For_Discount_Insert_Input>;
  on_conflict?: InputMaybe<Required_Access_For_Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Required_Access_For_Discount_OneArgs = {
  object: Required_Access_For_Discount_Insert_Input;
  on_conflict?: InputMaybe<Required_Access_For_Discount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: InputMaybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: InputMaybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SoundArgs = {
  objects: Array<Sound_Insert_Input>;
  on_conflict?: InputMaybe<Sound_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sound_OneArgs = {
  object: Sound_Insert_Input;
  on_conflict?: InputMaybe<Sound_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TitleArgs = {
  objects: Array<Title_Insert_Input>;
  on_conflict?: InputMaybe<Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Title_OneArgs = {
  object: Title_Insert_Input;
  on_conflict?: InputMaybe<Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TrackArgs = {
  objects: Array<Track_Insert_Input>;
  on_conflict?: InputMaybe<Track_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_OneArgs = {
  object: Track_Insert_Input;
  on_conflict?: InputMaybe<Track_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VerificationArgs = {
  objects: Array<Verification_Insert_Input>;
  on_conflict?: InputMaybe<Verification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_OneArgs = {
  object: Verification_Insert_Input;
  on_conflict?: InputMaybe<Verification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_TypeArgs = {
  objects: Array<Verification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Verification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_Type_OneArgs = {
  object: Verification_Type_Insert_Input;
  on_conflict?: InputMaybe<Verification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootLogin_With_Anonymous_IdArgs = {
  anonymousId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootLogin_With_VerificationArgs = {
  email: Scalars['String']['input'];
  verification: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootMerge_Anonymous_ProfileArgs = {
  args: Merge_Anonymous_Profile_Args;
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootSend_VerificationArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSet_Last_PlayedArgs = {
  args: Set_Last_Played_Args;
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_AccessArgs = {
  _inc?: InputMaybe<Access_Inc_Input>;
  _set?: InputMaybe<Access_Set_Input>;
  where: Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Access_By_PkArgs = {
  _inc?: InputMaybe<Access_Inc_Input>;
  _set?: InputMaybe<Access_Set_Input>;
  pk_columns: Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Access_ManyArgs = {
  updates: Array<Access_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Access_TitleArgs = {
  _set?: InputMaybe<Access_Title_Set_Input>;
  where: Access_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Access_Title_ManyArgs = {
  updates: Array<Access_Title_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ActivationArgs = {
  _set?: InputMaybe<Activation_Set_Input>;
  where: Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activation_By_PkArgs = {
  _set?: InputMaybe<Activation_Set_Input>;
  pk_columns: Activation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Activation_ManyArgs = {
  updates: Array<Activation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Activation_TitleArgs = {
  _set?: InputMaybe<Activation_Title_Set_Input>;
  where: Activation_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Activation_Title_ManyArgs = {
  updates: Array<Activation_Title_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CodeArgs = {
  _set?: InputMaybe<Code_Set_Input>;
  where: Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Code_By_PkArgs = {
  _set?: InputMaybe<Code_Set_Input>;
  pk_columns: Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Code_ManyArgs = {
  updates: Array<Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CollectionArgs = {
  _inc?: InputMaybe<Collection_Inc_Input>;
  _set?: InputMaybe<Collection_Set_Input>;
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_By_PkArgs = {
  _inc?: InputMaybe<Collection_Inc_Input>;
  _set?: InputMaybe<Collection_Set_Input>;
  pk_columns: Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_ManyArgs = {
  updates: Array<Collection_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_TitleArgs = {
  _set?: InputMaybe<Collection_Title_Set_Input>;
  where: Collection_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_Title_ManyArgs = {
  updates: Array<Collection_Title_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Disabled_Access_For_DiscountArgs = {
  _set?: InputMaybe<Disabled_Access_For_Discount_Set_Input>;
  where: Disabled_Access_For_Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Disabled_Access_For_Discount_ManyArgs = {
  updates: Array<Disabled_Access_For_Discount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DiscountArgs = {
  _inc?: InputMaybe<Discount_Inc_Input>;
  _set?: InputMaybe<Discount_Set_Input>;
  where: Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_By_PkArgs = {
  _inc?: InputMaybe<Discount_Inc_Input>;
  _set?: InputMaybe<Discount_Set_Input>;
  pk_columns: Discount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_ManyArgs = {
  updates: Array<Discount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_ProposalArgs = {
  _set?: InputMaybe<Discount_Proposal_Set_Input>;
  where: Discount_Proposal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_Proposal_By_PkArgs = {
  _set?: InputMaybe<Discount_Proposal_Set_Input>;
  pk_columns: Discount_Proposal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_Proposal_ManyArgs = {
  updates: Array<Discount_Proposal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FavoriteArgs = {
  _set?: InputMaybe<Favorite_Set_Input>;
  where: Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Favorite_By_PkArgs = {
  _set?: InputMaybe<Favorite_Set_Input>;
  pk_columns: Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Favorite_ManyArgs = {
  updates: Array<Favorite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _set?: InputMaybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _set?: InputMaybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_ManyArgs = {
  updates: Array<Feedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _inc?: InputMaybe<File_Inc_Input>;
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _inc?: InputMaybe<File_Inc_Input>;
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_ManyArgs = {
  updates: Array<File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_PurchaseArgs = {
  _inc?: InputMaybe<In_App_Purchase_Inc_Input>;
  _set?: InputMaybe<In_App_Purchase_Set_Input>;
  where: In_App_Purchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_ActivationArgs = {
  _set?: InputMaybe<In_App_Purchase_Activation_Set_Input>;
  where: In_App_Purchase_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_Activation_By_PkArgs = {
  _set?: InputMaybe<In_App_Purchase_Activation_Set_Input>;
  pk_columns: In_App_Purchase_Activation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_Activation_ManyArgs = {
  updates: Array<In_App_Purchase_Activation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_By_PkArgs = {
  _inc?: InputMaybe<In_App_Purchase_Inc_Input>;
  _set?: InputMaybe<In_App_Purchase_Set_Input>;
  pk_columns: In_App_Purchase_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_ManyArgs = {
  updates: Array<In_App_Purchase_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_PlatformArgs = {
  _set?: InputMaybe<In_App_Purchase_Platform_Set_Input>;
  where: In_App_Purchase_Platform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_Platform_By_PkArgs = {
  _set?: InputMaybe<In_App_Purchase_Platform_Set_Input>;
  pk_columns: In_App_Purchase_Platform_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_In_App_Purchase_Platform_ManyArgs = {
  updates: Array<In_App_Purchase_Platform_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ListeningArgs = {
  _inc?: InputMaybe<Listening_Inc_Input>;
  _set?: InputMaybe<Listening_Set_Input>;
  where: Listening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Listening_By_PkArgs = {
  _inc?: InputMaybe<Listening_Inc_Input>;
  _set?: InputMaybe<Listening_Set_Input>;
  pk_columns: Listening_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Listening_ManyArgs = {
  updates: Array<Listening_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Magenta_SubscriptionArgs = {
  _set?: InputMaybe<Magenta_Subscription_Set_Input>;
  where: Magenta_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Magenta_Subscription_By_PkArgs = {
  _set?: InputMaybe<Magenta_Subscription_Set_Input>;
  pk_columns: Magenta_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Magenta_Subscription_ManyArgs = {
  updates: Array<Magenta_Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OpeningArgs = {
  _set?: InputMaybe<Opening_Set_Input>;
  where: Opening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Opening_By_PkArgs = {
  _set?: InputMaybe<Opening_Set_Input>;
  pk_columns: Opening_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Opening_ManyArgs = {
  updates: Array<Opening_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ParameterArgs = {
  _set?: InputMaybe<Parameter_Set_Input>;
  where: Parameter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parameter_By_PkArgs = {
  _set?: InputMaybe<Parameter_Set_Input>;
  pk_columns: Parameter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parameter_KeyArgs = {
  _set?: InputMaybe<Parameter_Key_Set_Input>;
  where: Parameter_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parameter_Key_By_PkArgs = {
  _set?: InputMaybe<Parameter_Key_Set_Input>;
  pk_columns: Parameter_Key_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parameter_Key_ManyArgs = {
  updates: Array<Parameter_Key_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Parameter_ManyArgs = {
  updates: Array<Parameter_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _set?: InputMaybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _set?: InputMaybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_ManyArgs = {
  updates: Array<Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromoArgs = {
  _inc?: InputMaybe<Promo_Inc_Input>;
  _set?: InputMaybe<Promo_Set_Input>;
  where: Promo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_By_PkArgs = {
  _inc?: InputMaybe<Promo_Inc_Input>;
  _set?: InputMaybe<Promo_Set_Input>;
  pk_columns: Promo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_ManyArgs = {
  updates: Array<Promo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_TitleArgs = {
  _set?: InputMaybe<Promo_Title_Set_Input>;
  where: Promo_Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Title_ManyArgs = {
  updates: Array<Promo_Title_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseArgs = {
  _set?: InputMaybe<Purchase_Set_Input>;
  where: Purchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_By_PkArgs = {
  _set?: InputMaybe<Purchase_Set_Input>;
  pk_columns: Purchase_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_ManyArgs = {
  updates: Array<Purchase_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionArgs = {
  _inc?: InputMaybe<Question_Inc_Input>;
  _set?: InputMaybe<Question_Set_Input>;
  where: Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_By_PkArgs = {
  _inc?: InputMaybe<Question_Inc_Input>;
  _set?: InputMaybe<Question_Set_Input>;
  pk_columns: Question_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_ManyArgs = {
  updates: Array<Question_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_ScreenArgs = {
  _set?: InputMaybe<Question_Screen_Set_Input>;
  where: Question_Screen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Screen_By_PkArgs = {
  _set?: InputMaybe<Question_Screen_Set_Input>;
  pk_columns: Question_Screen_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Screen_ManyArgs = {
  updates: Array<Question_Screen_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Relative_Position_EnumArgs = {
  _set?: InputMaybe<Relative_Position_Enum_Set_Input>;
  where: Relative_Position_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Relative_Position_Enum_By_PkArgs = {
  _set?: InputMaybe<Relative_Position_Enum_Set_Input>;
  pk_columns: Relative_Position_Enum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Relative_Position_Enum_ManyArgs = {
  updates: Array<Relative_Position_Enum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Required_Access_For_DiscountArgs = {
  _set?: InputMaybe<Required_Access_For_Discount_Set_Input>;
  where: Required_Access_For_Discount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Required_Access_For_Discount_ManyArgs = {
  updates: Array<Required_Access_For_Discount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _set?: InputMaybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _set?: InputMaybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Session_ManyArgs = {
  updates: Array<Session_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SoundArgs = {
  _inc?: InputMaybe<Sound_Inc_Input>;
  _set?: InputMaybe<Sound_Set_Input>;
  where: Sound_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sound_By_PkArgs = {
  _inc?: InputMaybe<Sound_Inc_Input>;
  _set?: InputMaybe<Sound_Set_Input>;
  pk_columns: Sound_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sound_ManyArgs = {
  updates: Array<Sound_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TitleArgs = {
  _inc?: InputMaybe<Title_Inc_Input>;
  _set?: InputMaybe<Title_Set_Input>;
  where: Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Title_By_PkArgs = {
  _inc?: InputMaybe<Title_Inc_Input>;
  _set?: InputMaybe<Title_Set_Input>;
  pk_columns: Title_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Title_ManyArgs = {
  updates: Array<Title_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TrackArgs = {
  _inc?: InputMaybe<Track_Inc_Input>;
  _set?: InputMaybe<Track_Set_Input>;
  where: Track_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Track_By_PkArgs = {
  _inc?: InputMaybe<Track_Inc_Input>;
  _set?: InputMaybe<Track_Set_Input>;
  pk_columns: Track_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Track_ManyArgs = {
  updates: Array<Track_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VerificationArgs = {
  _set?: InputMaybe<Verification_Set_Input>;
  where: Verification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_By_PkArgs = {
  _set?: InputMaybe<Verification_Set_Input>;
  pk_columns: Verification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_ManyArgs = {
  updates: Array<Verification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_TypeArgs = {
  _set?: InputMaybe<Verification_Type_Set_Input>;
  where: Verification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Type_By_PkArgs = {
  _set?: InputMaybe<Verification_Type_Set_Input>;
  pk_columns: Verification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Type_ManyArgs = {
  updates: Array<Verification_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUploadArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "opening" */
export type Opening = {
  __typename?: 'opening';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  last_played?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  title: Title;
  title_id: Scalars['uuid']['output'];
};

/** aggregated selection of "opening" */
export type Opening_Aggregate = {
  __typename?: 'opening_aggregate';
  aggregate?: Maybe<Opening_Aggregate_Fields>;
  nodes: Array<Opening>;
};

export type Opening_Aggregate_Bool_Exp = {
  count?: InputMaybe<Opening_Aggregate_Bool_Exp_Count>;
};

export type Opening_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Opening_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Opening_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "opening" */
export type Opening_Aggregate_Fields = {
  __typename?: 'opening_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Opening_Max_Fields>;
  min?: Maybe<Opening_Min_Fields>;
};


/** aggregate fields of "opening" */
export type Opening_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opening_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "opening" */
export type Opening_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opening_Max_Order_By>;
  min?: InputMaybe<Opening_Min_Order_By>;
};

/** input type for inserting array relation for remote table "opening" */
export type Opening_Arr_Rel_Insert_Input = {
  data: Array<Opening_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Opening_On_Conflict>;
};

/** Boolean expression to filter rows from the table "opening". All fields are combined with a logical 'AND'. */
export type Opening_Bool_Exp = {
  _and?: InputMaybe<Array<Opening_Bool_Exp>>;
  _not?: InputMaybe<Opening_Bool_Exp>;
  _or?: InputMaybe<Array<Opening_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "opening" */
export enum Opening_Constraint {
  /** unique or primary key constraint on columns "id" */
  OpeningPkey = 'opening_pkey'
}

/** input type for inserting data into table "opening" */
export type Opening_Insert_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Opening_Max_Fields = {
  __typename?: 'opening_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "opening" */
export type Opening_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opening_Min_Fields = {
  __typename?: 'opening_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "opening" */
export type Opening_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "opening" */
export type Opening_Mutation_Response = {
  __typename?: 'opening_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Opening>;
};

/** on_conflict condition type for table "opening" */
export type Opening_On_Conflict = {
  constraint: Opening_Constraint;
  update_columns?: Array<Opening_Update_Column>;
  where?: InputMaybe<Opening_Bool_Exp>;
};

/** Ordering options when selecting data from "opening". */
export type Opening_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Title_Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: opening */
export type Opening_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "opening" */
export enum Opening_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TitleId = 'title_id'
}

/** input type for updating data in table "opening" */
export type Opening_Set_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "opening" */
export type Opening_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Opening_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Opening_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "opening" */
export enum Opening_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TitleId = 'title_id'
}

export type Opening_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Opening_Set_Input>;
  /** filter the rows which have to be updated */
  where: Opening_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "parameter" */
export type Parameter = {
  __typename?: 'parameter';
  id: Scalars['uuid']['output'];
  key?: Maybe<Parameter_Key_Enum>;
  key_prisma: Scalars['parameter_key_prisma']['output'];
  not_used?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  parameter_key?: Maybe<Parameter_Key>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "parameter" */
export type Parameter_Aggregate = {
  __typename?: 'parameter_aggregate';
  aggregate?: Maybe<Parameter_Aggregate_Fields>;
  nodes: Array<Parameter>;
};

export type Parameter_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Parameter_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Parameter_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Parameter_Aggregate_Bool_Exp_Count>;
};

export type Parameter_Aggregate_Bool_Exp_Bool_And = {
  arguments: Parameter_Select_Column_Parameter_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Parameter_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Parameter_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Parameter_Select_Column_Parameter_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Parameter_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Parameter_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Parameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Parameter_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "parameter" */
export type Parameter_Aggregate_Fields = {
  __typename?: 'parameter_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Parameter_Max_Fields>;
  min?: Maybe<Parameter_Min_Fields>;
};


/** aggregate fields of "parameter" */
export type Parameter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "parameter" */
export type Parameter_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Max_Order_By>;
  min?: InputMaybe<Parameter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "parameter" */
export type Parameter_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};

/** Boolean expression to filter rows from the table "parameter". All fields are combined with a logical 'AND'. */
export type Parameter_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<Parameter_Key_Enum_Comparison_Exp>;
  key_prisma?: InputMaybe<Parameter_Key_Prisma_Comparison_Exp>;
  not_used?: InputMaybe<Boolean_Comparison_Exp>;
  parameter_key?: InputMaybe<Parameter_Key_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter" */
export enum Parameter_Constraint {
  /** unique or primary key constraint on columns "key_prisma" */
  ParameterKeyPrismaUnique = 'parameter_key_prisma_unique',
  /** unique or primary key constraint on columns "id" */
  ParameterPkey = 'parameter_pkey'
}

/** input type for inserting data into table "parameter" */
export type Parameter_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Parameter_Key_Enum>;
  key_prisma?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  not_used?: InputMaybe<Scalars['Boolean']['input']>;
  parameter_key?: InputMaybe<Parameter_Key_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "parameter_key" */
export type Parameter_Key = {
  __typename?: 'parameter_key';
  /** An array relationship */
  parameters: Array<Parameter>;
  /** An aggregate relationship */
  parameters_aggregate: Parameter_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParametersArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};

/** aggregated selection of "parameter_key" */
export type Parameter_Key_Aggregate = {
  __typename?: 'parameter_key_aggregate';
  aggregate?: Maybe<Parameter_Key_Aggregate_Fields>;
  nodes: Array<Parameter_Key>;
};

/** aggregate fields of "parameter_key" */
export type Parameter_Key_Aggregate_Fields = {
  __typename?: 'parameter_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Parameter_Key_Max_Fields>;
  min?: Maybe<Parameter_Key_Min_Fields>;
};


/** aggregate fields of "parameter_key" */
export type Parameter_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "parameter_key". All fields are combined with a logical 'AND'. */
export type Parameter_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Key_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Key_Bool_Exp>>;
  parameters?: InputMaybe<Parameter_Bool_Exp>;
  parameters_aggregate?: InputMaybe<Parameter_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_key" */
export enum Parameter_Key_Constraint {
  /** unique or primary key constraint on columns "value" */
  ParameterKeyPkey = 'parameter_key_pkey'
}

export enum Parameter_Key_Enum {
  AndroidSubscriptionMarkdown = 'android_subscription_markdown',
  CardColor = 'card_color',
  CodeVereficationText = 'code_verefication_text',
  ContactInfoText = 'contact_info_text',
  DaysAfterLastDiscount = 'days_after_last_discount',
  DeleteAccountButton = 'delete_account_button',
  DeleteAccountDescription = 'delete_account_description',
  DeleteAccountTitle = 'delete_account_title',
  EnableWebsiteSubscriptionEmail = 'enable_website_subscription_email',
  FeedbackEmail = 'feedback_email',
  FreeTitlesPopupDuration = 'free_titles_popup_duration',
  FreeTitlesPopupMarkdown = 'free_titles_popup_markdown',
  FreeTitlesPopupTitle = 'free_titles_popup_title',
  HeaderFontSize = 'header_font_size',
  HeaderText = 'header_text',
  IapSubscriptionUpgradeText = 'iap_subscription_upgrade_text',
  IapSubscriptionUpgradeTitle = 'iap_subscription_upgrade_title',
  IosSubscriptionMarkdown = 'ios_subscription_markdown',
  LoginAfterIapDescription = 'login_after_iap_description',
  LoginAfterIapHeader = 'login_after_iap_header',
  LoginConfirmationText = 'login_confirmation_text',
  LoginText = 'login_text',
  ManageSubscriptionsText = 'manage_subscriptions_text',
  MyCollectionRemoveDescription = 'my_collection_remove_description',
  MyCollectionRemoveHeader = 'my_collection_remove_header',
  OnboardingDescriptionSlide_1 = 'onboarding_description_slide_1',
  OnboardingDescriptionSlide_2 = 'onboarding_description_slide_2',
  OnboardingDescriptionSlide_3 = 'onboarding_description_slide_3',
  OnboardingDescriptionSlide_4 = 'onboarding_description_slide_4',
  OnboardingDescriptionSlide_5 = 'onboarding_description_slide_5',
  OnboardingTextSlide_1 = 'onboarding_text_slide_1',
  OnboardingTextSlide_2 = 'onboarding_text_slide_2',
  OnboardingTextSlide_3 = 'onboarding_text_slide_3',
  OnboardingTextSlide_4 = 'onboarding_text_slide_4',
  OnboardingTextSlide_5 = 'onboarding_text_slide_5',
  PadlockIcon = 'padlock_icon',
  PlayerIntroDescription = 'player_intro_description',
  PlayerIntroHeader = 'player_intro_header',
  PurchaseText = 'purchase_text',
  ShowPromo = 'show_promo',
  ShowTutorial = 'show_tutorial',
  SubscriptionReminderText = 'subscription_reminder_text',
  SubscriptionReminderTitle = 'subscription_reminder_title',
  WebsiteSubscriptionAllTitlesText = 'website_subscription_all_titles_text',
  WebsiteSubscriptionAllTitlesTitle = 'website_subscription_all_titles_title',
  WebsiteSubscriptionUpgradeText = 'website_subscription_upgrade_text',
  WebsiteSubscriptionUpgradeTitle = 'website_subscription_upgrade_title'
}

/** Boolean expression to compare columns of type "parameter_key_enum". All fields are combined with logical 'AND'. */
export type Parameter_Key_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Parameter_Key_Enum>;
  _in?: InputMaybe<Array<Parameter_Key_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Parameter_Key_Enum>;
  _nin?: InputMaybe<Array<Parameter_Key_Enum>>;
};

/** input type for inserting data into table "parameter_key" */
export type Parameter_Key_Insert_Input = {
  parameters?: InputMaybe<Parameter_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Parameter_Key_Max_Fields = {
  __typename?: 'parameter_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Parameter_Key_Min_Fields = {
  __typename?: 'parameter_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "parameter_key" */
export type Parameter_Key_Mutation_Response = {
  __typename?: 'parameter_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Key>;
};

/** input type for inserting object relation for remote table "parameter_key" */
export type Parameter_Key_Obj_Rel_Insert_Input = {
  data: Parameter_Key_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};

/** on_conflict condition type for table "parameter_key" */
export type Parameter_Key_On_Conflict = {
  constraint: Parameter_Key_Constraint;
  update_columns?: Array<Parameter_Key_Update_Column>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_key". */
export type Parameter_Key_Order_By = {
  parameters_aggregate?: InputMaybe<Parameter_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_key */
export type Parameter_Key_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "parameter_key_prisma". All fields are combined with logical 'AND'. */
export type Parameter_Key_Prisma_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _gt?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _gte?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _in?: InputMaybe<Array<Scalars['parameter_key_prisma']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _lte?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _neq?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  _nin?: InputMaybe<Array<Scalars['parameter_key_prisma']['input']>>;
};

/** select columns of table "parameter_key" */
export enum Parameter_Key_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "parameter_key" */
export type Parameter_Key_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "parameter_key" */
export type Parameter_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parameter_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parameter_Key_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "parameter_key" */
export enum Parameter_Key_Update_Column {
  /** column name */
  Value = 'value'
}

export type Parameter_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parameter_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parameter_Key_Bool_Exp;
};

/** aggregate max on columns */
export type Parameter_Max_Fields = {
  __typename?: 'parameter_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key_prisma?: Maybe<Scalars['parameter_key_prisma']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "parameter" */
export type Parameter_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  key_prisma?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Min_Fields = {
  __typename?: 'parameter_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key_prisma?: Maybe<Scalars['parameter_key_prisma']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "parameter" */
export type Parameter_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  key_prisma?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter" */
export type Parameter_Mutation_Response = {
  __typename?: 'parameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter>;
};

/** on_conflict condition type for table "parameter" */
export type Parameter_On_Conflict = {
  constraint: Parameter_Constraint;
  update_columns?: Array<Parameter_Update_Column>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter". */
export type Parameter_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  key_prisma?: InputMaybe<Order_By>;
  not_used?: InputMaybe<Order_By>;
  parameter_key?: InputMaybe<Parameter_Key_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter */
export type Parameter_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "parameter" */
export enum Parameter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  KeyPrisma = 'key_prisma',
  /** column name */
  NotUsed = 'not_used',
  /** column name */
  Value = 'value'
}

/** select "parameter_aggregate_bool_exp_bool_and_arguments_columns" columns of table "parameter" */
export enum Parameter_Select_Column_Parameter_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  NotUsed = 'not_used'
}

/** select "parameter_aggregate_bool_exp_bool_or_arguments_columns" columns of table "parameter" */
export enum Parameter_Select_Column_Parameter_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  NotUsed = 'not_used'
}

/** input type for updating data in table "parameter" */
export type Parameter_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Parameter_Key_Enum>;
  key_prisma?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  not_used?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "parameter" */
export type Parameter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parameter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parameter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Parameter_Key_Enum>;
  key_prisma?: InputMaybe<Scalars['parameter_key_prisma']['input']>;
  not_used?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "parameter" */
export enum Parameter_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  KeyPrisma = 'key_prisma',
  /** column name */
  NotUsed = 'not_used',
  /** column name */
  Value = 'value'
}

export type Parameter_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parameter_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parameter_Bool_Exp;
};

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An array relationship */
  activations: Array<Activation>;
  /** An aggregate relationship */
  activations_aggregate: Activation_Aggregate;
  anonymous_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  discount_proposal?: Maybe<Discount_Proposal>;
  email?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  favorites: Array<Favorite>;
  /** An aggregate relationship */
  favorites_aggregate: Favorite_Aggregate;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  listenings: Array<Listening>;
  /** An aggregate relationship */
  listenings_aggregate: Listening_Aggregate;
  /** An array relationship */
  openings: Array<Opening>;
  /** An aggregate relationship */
  openings_aggregate: Opening_Aggregate;
  /** An array relationship */
  purchases: Array<Purchase>;
  /** An aggregate relationship */
  purchases_aggregate: Purchase_Aggregate;
  /** An array relationship */
  sessions: Array<Session>;
  /** An aggregate relationship */
  sessions_aggregate: Session_Aggregate;
  uid?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  verifications: Array<Verification>;
  /** An aggregate relationship */
  verifications_aggregate: Verification_Aggregate;
};


/** columns and relationships of "profile" */
export type ProfileActivationsArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileFavoritesArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileFavorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileListeningsArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileListenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileOpeningsArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileOpenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfilePurchasesArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfilePurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileSessionsArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileVerificationsArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileVerifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Bool_Exp>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Bool_Exp>>;
  activations?: InputMaybe<Activation_Bool_Exp>;
  activations_aggregate?: InputMaybe<Activation_Aggregate_Bool_Exp>;
  anonymous_id?: InputMaybe<String_Comparison_Exp>;
  discount_proposal?: InputMaybe<Discount_Proposal_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  favorites?: InputMaybe<Favorite_Bool_Exp>;
  favorites_aggregate?: InputMaybe<Favorite_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  listenings?: InputMaybe<Listening_Bool_Exp>;
  listenings_aggregate?: InputMaybe<Listening_Aggregate_Bool_Exp>;
  openings?: InputMaybe<Opening_Bool_Exp>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Bool_Exp>;
  purchases?: InputMaybe<Purchase_Bool_Exp>;
  purchases_aggregate?: InputMaybe<Purchase_Aggregate_Bool_Exp>;
  sessions?: InputMaybe<Session_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Bool_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  verifications?: InputMaybe<Verification_Bool_Exp>;
  verifications_aggregate?: InputMaybe<Verification_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint on columns "anonymous_id" */
  ProfileAnonymousIdUnique = 'profile_anonymous_id_unique',
  /** unique or primary key constraint on columns "email" */
  ProfileEmailUnique = 'profile_email_unique',
  /** unique or primary key constraint on columns "id" */
  ProfilePkey = 'profile_pkey',
  /** unique or primary key constraint on columns "uid" */
  ProfileUidUnique = 'profile_uid_unique'
}

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  activations?: InputMaybe<Activation_Arr_Rel_Insert_Input>;
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  discount_proposal?: InputMaybe<Discount_Proposal_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']['input']>;
  favorites?: InputMaybe<Favorite_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  listenings?: InputMaybe<Listening_Arr_Rel_Insert_Input>;
  openings?: InputMaybe<Opening_Arr_Rel_Insert_Input>;
  purchases?: InputMaybe<Purchase_Arr_Rel_Insert_Input>;
  sessions?: InputMaybe<Session_Arr_Rel_Insert_Input>;
  uid?: InputMaybe<Scalars['String']['input']>;
  verifications?: InputMaybe<Verification_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  anonymous_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  anonymous_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** on_conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  activations_aggregate?: InputMaybe<Activation_Aggregate_Order_By>;
  anonymous_id?: InputMaybe<Order_By>;
  discount_proposal?: InputMaybe<Discount_Proposal_Order_By>;
  email?: InputMaybe<Order_By>;
  favorites_aggregate?: InputMaybe<Favorite_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  listenings_aggregate?: InputMaybe<Listening_Aggregate_Order_By>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Order_By>;
  purchases_aggregate?: InputMaybe<Purchase_Aggregate_Order_By>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Order_By>;
  uid?: InputMaybe<Order_By>;
  verifications_aggregate?: InputMaybe<Verification_Aggregate_Order_By>;
};

/** primary key columns input for table: profile */
export type Profile_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  AnonymousId = 'anonymous_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Uid = 'uid'
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "profile" */
export type Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Stream_Cursor_Value_Input = {
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_View = {
  __typename?: 'profile_titles_view';
  /** An array relationship */
  accesses: Array<Title_Accesses_View>;
  /** An aggregate relationship */
  accesses_aggregate: Title_Accesses_View_Aggregate;
  /** An array relationship */
  activations: Array<Title_Activations_View>;
  /** An aggregate relationship */
  activations_aggregate: Title_Activations_View_Aggregate;
  adults_only?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  collections: Array<Title_Collections_View>;
  /** An aggregate relationship */
  collections_aggregate: Title_Collections_View_Aggregate;
  /** An object relationship */
  default_sound?: Maybe<Sound>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "profile_title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "profile_title_is_available" */
  is_available?: Maybe<Scalars['Boolean']['output']>;
  is_free?: Maybe<Scalars['Boolean']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  openings: Array<Opening>;
  /** An aggregate relationship */
  openings_aggregate: Opening_Aggregate;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  promos: Array<Title_Promos_View>;
  /** An aggregate relationship */
  promos_aggregate: Title_Promos_View_Aggregate;
  purchase_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  purchased_by: Array<Purchase>;
  /** An aggregate relationship */
  purchased_by_aggregate: Purchase_Aggregate;
  show_winner?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: Track_Aggregate;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewAccessesArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewAccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewActivationsArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewCollectionsArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewCollections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewOpeningsArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewOpenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewPromosArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewPromos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewPurchased_ByArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewPurchased_By_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewTracksArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


/** columns and relationships of "profile_titles_view" */
export type Profile_Titles_ViewTracks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};

/** aggregated selection of "profile_titles_view" */
export type Profile_Titles_View_Aggregate = {
  __typename?: 'profile_titles_view_aggregate';
  aggregate?: Maybe<Profile_Titles_View_Aggregate_Fields>;
  nodes: Array<Profile_Titles_View>;
};

/** aggregate fields of "profile_titles_view" */
export type Profile_Titles_View_Aggregate_Fields = {
  __typename?: 'profile_titles_view_aggregate_fields';
  avg?: Maybe<Profile_Titles_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Profile_Titles_View_Max_Fields>;
  min?: Maybe<Profile_Titles_View_Min_Fields>;
  stddev?: Maybe<Profile_Titles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Titles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Titles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Titles_View_Sum_Fields>;
  var_pop?: Maybe<Profile_Titles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Titles_View_Var_Samp_Fields>;
  variance?: Maybe<Profile_Titles_View_Variance_Fields>;
};


/** aggregate fields of "profile_titles_view" */
export type Profile_Titles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Profile_Titles_View_Avg_Fields = {
  __typename?: 'profile_titles_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "profile_titles_view". All fields are combined with a logical 'AND'. */
export type Profile_Titles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Titles_View_Bool_Exp>>;
  _not?: InputMaybe<Profile_Titles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Titles_View_Bool_Exp>>;
  accesses?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  accesses_aggregate?: InputMaybe<Title_Accesses_View_Aggregate_Bool_Exp>;
  activations?: InputMaybe<Title_Activations_View_Bool_Exp>;
  activations_aggregate?: InputMaybe<Title_Activations_View_Aggregate_Bool_Exp>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  collections?: InputMaybe<Title_Collections_View_Bool_Exp>;
  collections_aggregate?: InputMaybe<Title_Collections_View_Aggregate_Bool_Exp>;
  default_sound?: InputMaybe<Sound_Bool_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expires?: InputMaybe<Timestamp_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_available?: InputMaybe<Boolean_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  openings?: InputMaybe<Opening_Bool_Exp>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Bool_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  promos?: InputMaybe<Title_Promos_View_Bool_Exp>;
  promos_aggregate?: InputMaybe<Title_Promos_View_Aggregate_Bool_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  purchased_by?: InputMaybe<Purchase_Bool_Exp>;
  purchased_by_aggregate?: InputMaybe<Purchase_Aggregate_Bool_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  tracks?: InputMaybe<Track_Bool_Exp>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Profile_Titles_View_Max_Fields = {
  __typename?: 'profile_titles_view_max_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "profile_title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Profile_Titles_View_Min_Fields = {
  __typename?: 'profile_titles_view_min_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "profile_title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "profile_titles_view". */
export type Profile_Titles_View_Order_By = {
  accesses_aggregate?: InputMaybe<Title_Accesses_View_Aggregate_Order_By>;
  activations_aggregate?: InputMaybe<Title_Activations_View_Aggregate_Order_By>;
  adults_only?: InputMaybe<Order_By>;
  collections_aggregate?: InputMaybe<Title_Collections_View_Aggregate_Order_By>;
  default_sound?: InputMaybe<Sound_Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expires?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_available?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promos_aggregate?: InputMaybe<Title_Promos_View_Aggregate_Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  purchased_by_aggregate?: InputMaybe<Purchase_Aggregate_Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Order_By>;
};

/** select columns of table "profile_titles_view" */
export enum Profile_Titles_View_Select_Column {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** aggregate stddev on columns */
export type Profile_Titles_View_Stddev_Fields = {
  __typename?: 'profile_titles_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Titles_View_Stddev_Pop_Fields = {
  __typename?: 'profile_titles_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Titles_View_Stddev_Samp_Fields = {
  __typename?: 'profile_titles_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "profile_titles_view" */
export type Profile_Titles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Titles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Titles_View_Stream_Cursor_Value_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Profile_Titles_View_Sum_Fields = {
  __typename?: 'profile_titles_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type Profile_Titles_View_Var_Pop_Fields = {
  __typename?: 'profile_titles_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Profile_Titles_View_Var_Samp_Fields = {
  __typename?: 'profile_titles_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Profile_Titles_View_Variance_Fields = {
  __typename?: 'profile_titles_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  AnonymousId = 'anonymous_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Uid = 'uid'
}

export type Profile_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profile_Bool_Exp;
};

/** columns and relationships of "promo" */
export type Promo = {
  __typename?: 'promo';
  /** An object relationship */
  access?: Maybe<Access>;
  access_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  activations: Array<Activation>;
  /** An aggregate relationship */
  activations_aggregate: Activation_Aggregate;
  /** An array relationship */
  codes: Array<Code>;
  /** An aggregate relationship */
  codes_aggregate: Code_Aggregate;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  titles: Array<Promo_Titles_View>;
  /** An aggregate relationship */
  titles_aggregate: Promo_Titles_View_Aggregate;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "promo" */
export type PromoActivationsArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "promo" */
export type PromoActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


/** columns and relationships of "promo" */
export type PromoCodesArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


/** columns and relationships of "promo" */
export type PromoCodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


/** columns and relationships of "promo" */
export type PromoTitlesArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


/** columns and relationships of "promo" */
export type PromoTitles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};

/** aggregated selection of "promo" */
export type Promo_Aggregate = {
  __typename?: 'promo_aggregate';
  aggregate?: Maybe<Promo_Aggregate_Fields>;
  nodes: Array<Promo>;
};

/** aggregate fields of "promo" */
export type Promo_Aggregate_Fields = {
  __typename?: 'promo_aggregate_fields';
  avg?: Maybe<Promo_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Promo_Max_Fields>;
  min?: Maybe<Promo_Min_Fields>;
  stddev?: Maybe<Promo_Stddev_Fields>;
  stddev_pop?: Maybe<Promo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promo_Stddev_Samp_Fields>;
  sum?: Maybe<Promo_Sum_Fields>;
  var_pop?: Maybe<Promo_Var_Pop_Fields>;
  var_samp?: Maybe<Promo_Var_Samp_Fields>;
  variance?: Maybe<Promo_Variance_Fields>;
};


/** aggregate fields of "promo" */
export type Promo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Promo_Avg_Fields = {
  __typename?: 'promo_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "promo". All fields are combined with a logical 'AND'. */
export type Promo_Bool_Exp = {
  _and?: InputMaybe<Array<Promo_Bool_Exp>>;
  _not?: InputMaybe<Promo_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  activations?: InputMaybe<Activation_Bool_Exp>;
  activations_aggregate?: InputMaybe<Activation_Aggregate_Bool_Exp>;
  codes?: InputMaybe<Code_Bool_Exp>;
  codes_aggregate?: InputMaybe<Code_Aggregate_Bool_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  template?: InputMaybe<String_Comparison_Exp>;
  titles?: InputMaybe<Promo_Titles_View_Bool_Exp>;
  titles_aggregate?: InputMaybe<Promo_Titles_View_Aggregate_Bool_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo" */
export enum Promo_Constraint {
  /** unique or primary key constraint on columns "access_id" */
  PromoAccessIdUnique = 'promo_access_id_unique',
  /** unique or primary key constraint on columns "name" */
  PromoNameUnique = 'promo_name_unique',
  /** unique or primary key constraint on columns "id" */
  PromoPkey = 'promo_pkey',
  /** unique or primary key constraint on columns "sku" */
  PromoSkuUnique = 'promo_sku_unique'
}

/** input type for incrementing numeric columns in table "promo" */
export type Promo_Inc_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "promo" */
export type Promo_Insert_Input = {
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activations?: InputMaybe<Activation_Arr_Rel_Insert_Input>;
  codes?: InputMaybe<Code_Arr_Rel_Insert_Input>;
  count?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  titles?: InputMaybe<Promo_Titles_View_Arr_Rel_Insert_Input>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Promo_Max_Fields = {
  __typename?: 'promo_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Promo_Min_Fields = {
  __typename?: 'promo_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "promo" */
export type Promo_Mutation_Response = {
  __typename?: 'promo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Promo>;
};

/** input type for inserting object relation for remote table "promo" */
export type Promo_Obj_Rel_Insert_Input = {
  data: Promo_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Promo_On_Conflict>;
};

/** on_conflict condition type for table "promo" */
export type Promo_On_Conflict = {
  constraint: Promo_Constraint;
  update_columns?: Array<Promo_Update_Column>;
  where?: InputMaybe<Promo_Bool_Exp>;
};

/** Ordering options when selecting data from "promo". */
export type Promo_Order_By = {
  access?: InputMaybe<Access_Order_By>;
  access_id?: InputMaybe<Order_By>;
  activations_aggregate?: InputMaybe<Activation_Aggregate_Order_By>;
  codes_aggregate?: InputMaybe<Code_Aggregate_Order_By>;
  count?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  titles_aggregate?: InputMaybe<Promo_Titles_View_Aggregate_Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo */
export type Promo_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "promo" */
export enum Promo_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Count = 'count',
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  Sku = 'sku',
  /** column name */
  Template = 'template',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** input type for updating data in table "promo" */
export type Promo_Set_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Promo_Stddev_Fields = {
  __typename?: 'promo_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Promo_Stddev_Pop_Fields = {
  __typename?: 'promo_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Promo_Stddev_Samp_Fields = {
  __typename?: 'promo_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "promo" */
export type Promo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Promo_Sum_Fields = {
  __typename?: 'promo_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "_promo_title" */
export type Promo_Title = {
  __typename?: 'promo_title';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  promo: Promo;
  /** An object relationship */
  title: Title;
};

/** aggregated selection of "_promo_title" */
export type Promo_Title_Aggregate = {
  __typename?: 'promo_title_aggregate';
  aggregate?: Maybe<Promo_Title_Aggregate_Fields>;
  nodes: Array<Promo_Title>;
};

/** aggregate fields of "_promo_title" */
export type Promo_Title_Aggregate_Fields = {
  __typename?: 'promo_title_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Promo_Title_Max_Fields>;
  min?: Maybe<Promo_Title_Min_Fields>;
};


/** aggregate fields of "_promo_title" */
export type Promo_Title_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_promo_title". All fields are combined with a logical 'AND'. */
export type Promo_Title_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Promo_Title_Bool_Exp>>;
  _not?: InputMaybe<Promo_Title_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Title_Bool_Exp>>;
  promo?: InputMaybe<Promo_Bool_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
};

/** unique or primary key constraints on table "_promo_title" */
export enum Promo_Title_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  PromoTitleAbUnique = '_promo_title_AB_unique'
}

/** input type for inserting data into table "_promo_title" */
export type Promo_Title_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  promo?: InputMaybe<Promo_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Promo_Title_Max_Fields = {
  __typename?: 'promo_title_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Promo_Title_Min_Fields = {
  __typename?: 'promo_title_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_promo_title" */
export type Promo_Title_Mutation_Response = {
  __typename?: 'promo_title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Title>;
};

/** on_conflict condition type for table "_promo_title" */
export type Promo_Title_On_Conflict = {
  constraint: Promo_Title_Constraint;
  update_columns?: Array<Promo_Title_Update_Column>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};

/** Ordering options when selecting data from "_promo_title". */
export type Promo_Title_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  promo?: InputMaybe<Promo_Order_By>;
  title?: InputMaybe<Title_Order_By>;
};

/** select columns of table "_promo_title" */
export enum Promo_Title_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_promo_title" */
export type Promo_Title_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "promo_title" */
export type Promo_Title_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Title_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Title_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_promo_title" */
export enum Promo_Title_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Promo_Title_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promo_Title_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promo_Title_Bool_Exp;
};

/** columns and relationships of "promo_titles_view" */
export type Promo_Titles_View = {
  __typename?: 'promo_titles_view';
  adults_only?: Maybe<Scalars['Boolean']['output']>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  is_free?: Maybe<Scalars['Boolean']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  show_winner?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "promo_titles_view" */
export type Promo_Titles_View_Aggregate = {
  __typename?: 'promo_titles_view_aggregate';
  aggregate?: Maybe<Promo_Titles_View_Aggregate_Fields>;
  nodes: Array<Promo_Titles_View>;
};

export type Promo_Titles_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Promo_Titles_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Promo_Titles_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Promo_Titles_View_Aggregate_Bool_Exp_Count>;
};

export type Promo_Titles_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Promo_Titles_View_Select_Column_Promo_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Promo_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Promo_Titles_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Promo_Titles_View_Select_Column_Promo_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Promo_Titles_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Promo_Titles_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Promo_Titles_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "promo_titles_view" */
export type Promo_Titles_View_Aggregate_Fields = {
  __typename?: 'promo_titles_view_aggregate_fields';
  avg?: Maybe<Promo_Titles_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Promo_Titles_View_Max_Fields>;
  min?: Maybe<Promo_Titles_View_Min_Fields>;
  stddev?: Maybe<Promo_Titles_View_Stddev_Fields>;
  stddev_pop?: Maybe<Promo_Titles_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promo_Titles_View_Stddev_Samp_Fields>;
  sum?: Maybe<Promo_Titles_View_Sum_Fields>;
  var_pop?: Maybe<Promo_Titles_View_Var_Pop_Fields>;
  var_samp?: Maybe<Promo_Titles_View_Var_Samp_Fields>;
  variance?: Maybe<Promo_Titles_View_Variance_Fields>;
};


/** aggregate fields of "promo_titles_view" */
export type Promo_Titles_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "promo_titles_view" */
export type Promo_Titles_View_Aggregate_Order_By = {
  avg?: InputMaybe<Promo_Titles_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Promo_Titles_View_Max_Order_By>;
  min?: InputMaybe<Promo_Titles_View_Min_Order_By>;
  stddev?: InputMaybe<Promo_Titles_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Promo_Titles_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Promo_Titles_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Promo_Titles_View_Sum_Order_By>;
  var_pop?: InputMaybe<Promo_Titles_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Promo_Titles_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Promo_Titles_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "promo_titles_view" */
export type Promo_Titles_View_Arr_Rel_Insert_Input = {
  data: Array<Promo_Titles_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Promo_Titles_View_Avg_Fields = {
  __typename?: 'promo_titles_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "promo_titles_view". All fields are combined with a logical 'AND'. */
export type Promo_Titles_View_Bool_Exp = {
  _and?: InputMaybe<Array<Promo_Titles_View_Bool_Exp>>;
  _not?: InputMaybe<Promo_Titles_View_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Titles_View_Bool_Exp>>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  promo_id?: InputMaybe<Uuid_Comparison_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "promo_titles_view" */
export type Promo_Titles_View_Insert_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Promo_Titles_View_Max_Fields = {
  __typename?: 'promo_titles_view_max_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Max_Order_By = {
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Promo_Titles_View_Min_Fields = {
  __typename?: 'promo_titles_view_min_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Min_Order_By = {
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "promo_titles_view". */
export type Promo_Titles_View_Order_By = {
  adults_only?: InputMaybe<Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** select columns of table "promo_titles_view" */
export enum Promo_Titles_View_Select_Column {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** select "promo_titles_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "promo_titles_view" */
export enum Promo_Titles_View_Select_Column_Promo_Titles_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** select "promo_titles_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "promo_titles_view" */
export enum Promo_Titles_View_Select_Column_Promo_Titles_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** aggregate stddev on columns */
export type Promo_Titles_View_Stddev_Fields = {
  __typename?: 'promo_titles_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Promo_Titles_View_Stddev_Pop_Fields = {
  __typename?: 'promo_titles_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Promo_Titles_View_Stddev_Samp_Fields = {
  __typename?: 'promo_titles_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "promo_titles_view" */
export type Promo_Titles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Titles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Titles_View_Stream_Cursor_Value_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Promo_Titles_View_Sum_Fields = {
  __typename?: 'promo_titles_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Promo_Titles_View_Var_Pop_Fields = {
  __typename?: 'promo_titles_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Promo_Titles_View_Var_Samp_Fields = {
  __typename?: 'promo_titles_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Promo_Titles_View_Variance_Fields = {
  __typename?: 'promo_titles_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "promo_titles_view" */
export type Promo_Titles_View_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "promo" */
export enum Promo_Update_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Count = 'count',
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  Sku = 'sku',
  /** column name */
  Template = 'template',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

export type Promo_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Promo_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Promo_Var_Pop_Fields = {
  __typename?: 'promo_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Promo_Var_Samp_Fields = {
  __typename?: 'promo_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Promo_Variance_Fields = {
  __typename?: 'promo_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "purchase" */
export type Purchase = {
  __typename?: 'purchase';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  title: Title;
  title_id: Scalars['uuid']['output'];
};

/** aggregated selection of "purchase" */
export type Purchase_Aggregate = {
  __typename?: 'purchase_aggregate';
  aggregate?: Maybe<Purchase_Aggregate_Fields>;
  nodes: Array<Purchase>;
};

export type Purchase_Aggregate_Bool_Exp = {
  count?: InputMaybe<Purchase_Aggregate_Bool_Exp_Count>;
};

export type Purchase_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Purchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Purchase_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "purchase" */
export type Purchase_Aggregate_Fields = {
  __typename?: 'purchase_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Purchase_Max_Fields>;
  min?: Maybe<Purchase_Min_Fields>;
};


/** aggregate fields of "purchase" */
export type Purchase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Purchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "purchase" */
export type Purchase_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Purchase_Max_Order_By>;
  min?: InputMaybe<Purchase_Min_Order_By>;
};

/** input type for inserting array relation for remote table "purchase" */
export type Purchase_Arr_Rel_Insert_Input = {
  data: Array<Purchase_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Purchase_On_Conflict>;
};

/** Boolean expression to filter rows from the table "purchase". All fields are combined with a logical 'AND'. */
export type Purchase_Bool_Exp = {
  _and?: InputMaybe<Array<Purchase_Bool_Exp>>;
  _not?: InputMaybe<Purchase_Bool_Exp>;
  _or?: InputMaybe<Array<Purchase_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchase" */
export enum Purchase_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchasePkey = 'purchase_pkey'
}

/** input type for inserting data into table "purchase" */
export type Purchase_Insert_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Purchase_Max_Fields = {
  __typename?: 'purchase_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "purchase" */
export type Purchase_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Purchase_Min_Fields = {
  __typename?: 'purchase_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "purchase" */
export type Purchase_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "purchase" */
export type Purchase_Mutation_Response = {
  __typename?: 'purchase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchase>;
};

/** on_conflict condition type for table "purchase" */
export type Purchase_On_Conflict = {
  constraint: Purchase_Constraint;
  update_columns?: Array<Purchase_Update_Column>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};

/** Ordering options when selecting data from "purchase". */
export type Purchase_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Title_Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchase */
export type Purchase_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "purchase" */
export enum Purchase_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TitleId = 'title_id'
}

/** input type for updating data in table "purchase" */
export type Purchase_Set_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "purchase" */
export type Purchase_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Purchase_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Purchase_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "purchase" */
export enum Purchase_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TitleId = 'title_id'
}

export type Purchase_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Purchase_Set_Input>;
  /** filter the rows which have to be updated */
  where: Purchase_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "access" */
  access: Array<Access>;
  /** fetch aggregated fields from the table: "access" */
  access_aggregate: Access_Aggregate;
  /** fetch data from the table: "access" using primary key columns */
  access_by_pk?: Maybe<Access>;
  /** fetch data from the table: "_access_title" */
  access_title: Array<Access_Title>;
  /** fetch aggregated fields from the table: "_access_title" */
  access_title_aggregate: Access_Title_Aggregate;
  /** fetch data from the table: "access_titles_view" */
  access_titles_view: Array<Access_Titles_View>;
  /** fetch aggregated fields from the table: "access_titles_view" */
  access_titles_view_aggregate: Access_Titles_View_Aggregate;
  /** fetch data from the table: "activation" */
  activation: Array<Activation>;
  /** fetch aggregated fields from the table: "activation" */
  activation_aggregate: Activation_Aggregate;
  /** fetch data from the table: "activation" using primary key columns */
  activation_by_pk?: Maybe<Activation>;
  /** fetch data from the table: "_activation_title" */
  activation_title: Array<Activation_Title>;
  /** fetch aggregated fields from the table: "_activation_title" */
  activation_title_aggregate: Activation_Title_Aggregate;
  /** fetch data from the table: "activation_titles_view" */
  activation_titles_view: Array<Activation_Titles_View>;
  /** fetch aggregated fields from the table: "activation_titles_view" */
  activation_titles_view_aggregate: Activation_Titles_View_Aggregate;
  /** fetch data from the table: "code" */
  code: Array<Code>;
  /** fetch aggregated fields from the table: "code" */
  code_aggregate: Code_Aggregate;
  /** fetch data from the table: "code" using primary key columns */
  code_by_pk?: Maybe<Code>;
  /** fetch data from the table: "collection" */
  collection: Array<Collection>;
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table: "_collection_title" */
  collection_title: Array<Collection_Title>;
  /** fetch aggregated fields from the table: "_collection_title" */
  collection_title_aggregate: Collection_Title_Aggregate;
  /** fetch data from the table: "collection_titles_view" */
  collection_titles_view: Array<Collection_Titles_View>;
  /** fetch aggregated fields from the table: "collection_titles_view" */
  collection_titles_view_aggregate: Collection_Titles_View_Aggregate;
  /** fetch data from the table: "disabled_access_discounts_view" */
  disabled_access_discounts_view: Array<Disabled_Access_Discounts_View>;
  /** fetch aggregated fields from the table: "disabled_access_discounts_view" */
  disabled_access_discounts_view_aggregate: Disabled_Access_Discounts_View_Aggregate;
  /** fetch data from the table: "_disabled_access_for_discount" */
  disabled_access_for_discount: Array<Disabled_Access_For_Discount>;
  /** fetch aggregated fields from the table: "_disabled_access_for_discount" */
  disabled_access_for_discount_aggregate: Disabled_Access_For_Discount_Aggregate;
  /** fetch data from the table: "disabled_discount_accesses_view" */
  disabled_discount_accesses_view: Array<Disabled_Discount_Accesses_View>;
  /** fetch aggregated fields from the table: "disabled_discount_accesses_view" */
  disabled_discount_accesses_view_aggregate: Disabled_Discount_Accesses_View_Aggregate;
  /** fetch data from the table: "discount" */
  discount: Array<Discount>;
  /** fetch aggregated fields from the table: "discount" */
  discount_aggregate: Discount_Aggregate;
  /** fetch data from the table: "discount" using primary key columns */
  discount_by_pk?: Maybe<Discount>;
  /** fetch data from the table: "discount_proposal" */
  discount_proposal: Array<Discount_Proposal>;
  /** fetch aggregated fields from the table: "discount_proposal" */
  discount_proposal_aggregate: Discount_Proposal_Aggregate;
  /** fetch data from the table: "discount_proposal" using primary key columns */
  discount_proposal_by_pk?: Maybe<Discount_Proposal>;
  /** fetch data from the table: "favorite" */
  favorite: Array<Favorite>;
  /** fetch aggregated fields from the table: "favorite" */
  favorite_aggregate: Favorite_Aggregate;
  /** fetch data from the table: "favorite" using primary key columns */
  favorite_by_pk?: Maybe<Favorite>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  fileUrl: Scalars['String']['output'];
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "in_app_purchase" */
  in_app_purchase: Array<In_App_Purchase>;
  /** fetch data from the table: "in_app_purchase_activation" */
  in_app_purchase_activation: Array<In_App_Purchase_Activation>;
  /** fetch aggregated fields from the table: "in_app_purchase_activation" */
  in_app_purchase_activation_aggregate: In_App_Purchase_Activation_Aggregate;
  /** fetch data from the table: "in_app_purchase_activation" using primary key columns */
  in_app_purchase_activation_by_pk?: Maybe<In_App_Purchase_Activation>;
  /** fetch aggregated fields from the table: "in_app_purchase" */
  in_app_purchase_aggregate: In_App_Purchase_Aggregate;
  /** fetch data from the table: "in_app_purchase" using primary key columns */
  in_app_purchase_by_pk?: Maybe<In_App_Purchase>;
  /** fetch data from the table: "in_app_purchase_platform" */
  in_app_purchase_platform: Array<In_App_Purchase_Platform>;
  /** fetch aggregated fields from the table: "in_app_purchase_platform" */
  in_app_purchase_platform_aggregate: In_App_Purchase_Platform_Aggregate;
  /** fetch data from the table: "in_app_purchase_platform" using primary key columns */
  in_app_purchase_platform_by_pk?: Maybe<In_App_Purchase_Platform>;
  /** fetch data from the table: "listening" */
  listening: Array<Listening>;
  /** fetch aggregated fields from the table: "listening" */
  listening_aggregate: Listening_Aggregate;
  /** fetch data from the table: "listening" using primary key columns */
  listening_by_pk?: Maybe<Listening>;
  /** fetch data from the table: "magenta_subscription" */
  magenta_subscription: Array<Magenta_Subscription>;
  /** fetch aggregated fields from the table: "magenta_subscription" */
  magenta_subscription_aggregate: Magenta_Subscription_Aggregate;
  /** fetch data from the table: "magenta_subscription" using primary key columns */
  magenta_subscription_by_pk?: Maybe<Magenta_Subscription>;
  /** fetch data from the table: "opening" */
  opening: Array<Opening>;
  /** fetch aggregated fields from the table: "opening" */
  opening_aggregate: Opening_Aggregate;
  /** fetch data from the table: "opening" using primary key columns */
  opening_by_pk?: Maybe<Opening>;
  /** fetch data from the table: "parameter" */
  parameter: Array<Parameter>;
  /** fetch aggregated fields from the table: "parameter" */
  parameter_aggregate: Parameter_Aggregate;
  /** fetch data from the table: "parameter" using primary key columns */
  parameter_by_pk?: Maybe<Parameter>;
  /** fetch data from the table: "parameter_key" */
  parameter_key: Array<Parameter_Key>;
  /** fetch aggregated fields from the table: "parameter_key" */
  parameter_key_aggregate: Parameter_Key_Aggregate;
  /** fetch data from the table: "parameter_key" using primary key columns */
  parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "profile_titles_view" */
  profile_titles_view: Array<Profile_Titles_View>;
  /** fetch aggregated fields from the table: "profile_titles_view" */
  profile_titles_view_aggregate: Profile_Titles_View_Aggregate;
  /** fetch data from the table: "promo" */
  promo: Array<Promo>;
  /** fetch aggregated fields from the table: "promo" */
  promo_aggregate: Promo_Aggregate;
  /** fetch data from the table: "promo" using primary key columns */
  promo_by_pk?: Maybe<Promo>;
  /** fetch data from the table: "_promo_title" */
  promo_title: Array<Promo_Title>;
  /** fetch aggregated fields from the table: "_promo_title" */
  promo_title_aggregate: Promo_Title_Aggregate;
  /** fetch data from the table: "promo_titles_view" */
  promo_titles_view: Array<Promo_Titles_View>;
  /** fetch aggregated fields from the table: "promo_titles_view" */
  promo_titles_view_aggregate: Promo_Titles_View_Aggregate;
  /** fetch data from the table: "purchase" */
  purchase: Array<Purchase>;
  /** fetch aggregated fields from the table: "purchase" */
  purchase_aggregate: Purchase_Aggregate;
  /** fetch data from the table: "purchase" using primary key columns */
  purchase_by_pk?: Maybe<Purchase>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: Question_Aggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table: "question_screen" */
  question_screen: Array<Question_Screen>;
  /** fetch aggregated fields from the table: "question_screen" */
  question_screen_aggregate: Question_Screen_Aggregate;
  /** fetch data from the table: "question_screen" using primary key columns */
  question_screen_by_pk?: Maybe<Question_Screen>;
  /** fetch data from the table: "relative_position_enum" */
  relative_position_enum: Array<Relative_Position_Enum>;
  /** fetch aggregated fields from the table: "relative_position_enum" */
  relative_position_enum_aggregate: Relative_Position_Enum_Aggregate;
  /** fetch data from the table: "relative_position_enum" using primary key columns */
  relative_position_enum_by_pk?: Maybe<Relative_Position_Enum>;
  /** fetch data from the table: "required_access_discounts_view" */
  required_access_discounts_view: Array<Required_Access_Discounts_View>;
  /** fetch aggregated fields from the table: "required_access_discounts_view" */
  required_access_discounts_view_aggregate: Required_Access_Discounts_View_Aggregate;
  /** fetch data from the table: "_required_access_for_discount" */
  required_access_for_discount: Array<Required_Access_For_Discount>;
  /** fetch aggregated fields from the table: "_required_access_for_discount" */
  required_access_for_discount_aggregate: Required_Access_For_Discount_Aggregate;
  /** fetch data from the table: "required_discount_accesses_view" */
  required_discount_accesses_view: Array<Required_Discount_Accesses_View>;
  /** fetch aggregated fields from the table: "required_discount_accesses_view" */
  required_discount_accesses_view_aggregate: Required_Discount_Accesses_View_Aggregate;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "sound" */
  sound: Array<Sound>;
  /** fetch aggregated fields from the table: "sound" */
  sound_aggregate: Sound_Aggregate;
  /** fetch data from the table: "sound" using primary key columns */
  sound_by_pk?: Maybe<Sound>;
  /** fetch data from the table: "title" */
  title: Array<Title>;
  /** fetch data from the table: "title_accesses_view" */
  title_accesses_view: Array<Title_Accesses_View>;
  /** fetch aggregated fields from the table: "title_accesses_view" */
  title_accesses_view_aggregate: Title_Accesses_View_Aggregate;
  /** fetch data from the table: "title_activations_view" */
  title_activations_view: Array<Title_Activations_View>;
  /** fetch aggregated fields from the table: "title_activations_view" */
  title_activations_view_aggregate: Title_Activations_View_Aggregate;
  /** fetch aggregated fields from the table: "title" */
  title_aggregate: Title_Aggregate;
  /** fetch data from the table: "title" using primary key columns */
  title_by_pk?: Maybe<Title>;
  /** fetch data from the table: "title_collections_view" */
  title_collections_view: Array<Title_Collections_View>;
  /** fetch aggregated fields from the table: "title_collections_view" */
  title_collections_view_aggregate: Title_Collections_View_Aggregate;
  /** fetch data from the table: "title_promos_view" */
  title_promos_view: Array<Title_Promos_View>;
  /** fetch aggregated fields from the table: "title_promos_view" */
  title_promos_view_aggregate: Title_Promos_View_Aggregate;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: Track_Aggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "verification" */
  verification: Array<Verification>;
  /** fetch aggregated fields from the table: "verification" */
  verification_aggregate: Verification_Aggregate;
  /** fetch data from the table: "verification" using primary key columns */
  verification_by_pk?: Maybe<Verification>;
  /** fetch data from the table: "verification_type" */
  verification_type: Array<Verification_Type>;
  /** fetch aggregated fields from the table: "verification_type" */
  verification_type_aggregate: Verification_Type_Aggregate;
  /** fetch data from the table: "verification_type" using primary key columns */
  verification_type_by_pk?: Maybe<Verification_Type>;
};


export type Query_RootAccessArgs = {
  distinct_on?: InputMaybe<Array<Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Order_By>>;
  where?: InputMaybe<Access_Bool_Exp>;
};


export type Query_RootAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Order_By>>;
  where?: InputMaybe<Access_Bool_Exp>;
};


export type Query_RootAccess_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAccess_TitleArgs = {
  distinct_on?: InputMaybe<Array<Access_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Title_Order_By>>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};


export type Query_RootAccess_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Title_Order_By>>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};


export type Query_RootAccess_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


export type Query_RootAccess_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


export type Query_RootActivationArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


export type Query_RootActivation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


export type Query_RootActivation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootActivation_TitleArgs = {
  distinct_on?: InputMaybe<Array<Activation_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Title_Order_By>>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};


export type Query_RootActivation_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Title_Order_By>>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};


export type Query_RootActivation_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


export type Query_RootActivation_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


export type Query_RootCodeArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


export type Query_RootCode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


export type Query_RootCode_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCollectionArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCollection_TitleArgs = {
  distinct_on?: InputMaybe<Array<Collection_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Title_Order_By>>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};


export type Query_RootCollection_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Title_Order_By>>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};


export type Query_RootCollection_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


export type Query_RootCollection_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


export type Query_RootDisabled_Access_Discounts_ViewArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
};


export type Query_RootDisabled_Access_Discounts_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
};


export type Query_RootDisabled_Access_For_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};


export type Query_RootDisabled_Access_For_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};


export type Query_RootDisabled_Discount_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


export type Query_RootDisabled_Discount_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


export type Query_RootDiscountArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


export type Query_RootDiscount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


export type Query_RootDiscount_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDiscount_ProposalArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


export type Query_RootDiscount_Proposal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


export type Query_RootDiscount_Proposal_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFavoriteArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


export type Query_RootFavorite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


export type Query_RootFavorite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFeedbackArgs = {
  distinct_on?: InputMaybe<Array<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFileUrlArgs = {
  name: Scalars['String']['input'];
  short_id: Scalars['String']['input'];
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootIn_App_PurchaseArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_ActivationArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_Activation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_Activation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootIn_App_Purchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootIn_App_Purchase_PlatformArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Platform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Platform_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_Platform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Platform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Platform_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};


export type Query_RootIn_App_Purchase_Platform_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootListeningArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


export type Query_RootListening_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


export type Query_RootListening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMagenta_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Magenta_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Magenta_Subscription_Order_By>>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};


export type Query_RootMagenta_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magenta_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Magenta_Subscription_Order_By>>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};


export type Query_RootMagenta_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOpeningArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


export type Query_RootOpening_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


export type Query_RootOpening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootParameterArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type Query_RootParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type Query_RootParameter_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootParameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type Query_RootParameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type Query_RootParameter_Key_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProfile_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Profile_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Titles_View_Order_By>>;
  where?: InputMaybe<Profile_Titles_View_Bool_Exp>;
};


export type Query_RootProfile_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Titles_View_Order_By>>;
  where?: InputMaybe<Profile_Titles_View_Bool_Exp>;
};


export type Query_RootPromoArgs = {
  distinct_on?: InputMaybe<Array<Promo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Order_By>>;
  where?: InputMaybe<Promo_Bool_Exp>;
};


export type Query_RootPromo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Order_By>>;
  where?: InputMaybe<Promo_Bool_Exp>;
};


export type Query_RootPromo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPromo_TitleArgs = {
  distinct_on?: InputMaybe<Array<Promo_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Title_Order_By>>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};


export type Query_RootPromo_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Title_Order_By>>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};


export type Query_RootPromo_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


export type Query_RootPromo_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


export type Query_RootPurchaseArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


export type Query_RootPurchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


export type Query_RootPurchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestionArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


export type Query_RootQuestion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


export type Query_RootQuestion_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuestion_ScreenArgs = {
  distinct_on?: InputMaybe<Array<Question_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Screen_Order_By>>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};


export type Query_RootQuestion_Screen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Screen_Order_By>>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};


export type Query_RootQuestion_Screen_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootRelative_Position_EnumArgs = {
  distinct_on?: InputMaybe<Array<Relative_Position_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relative_Position_Enum_Order_By>>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};


export type Query_RootRelative_Position_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relative_Position_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relative_Position_Enum_Order_By>>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};


export type Query_RootRelative_Position_Enum_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootRequired_Access_Discounts_ViewArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


export type Query_RootRequired_Access_Discounts_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


export type Query_RootRequired_Access_For_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};


export type Query_RootRequired_Access_For_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};


export type Query_RootRequired_Discount_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


export type Query_RootRequired_Discount_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


export type Query_RootSessionArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSoundArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


export type Query_RootSound_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


export type Query_RootSound_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTitleArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


export type Query_RootTitle_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


export type Query_RootTitle_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


export type Query_RootTitle_Activations_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


export type Query_RootTitle_Activations_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


export type Query_RootTitle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


export type Query_RootTitle_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTitle_Collections_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


export type Query_RootTitle_Collections_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


export type Query_RootTitle_Promos_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


export type Query_RootTitle_Promos_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


export type Query_RootTrackArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


export type Query_RootTrack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


export type Query_RootTrack_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVerificationArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


export type Query_RootVerification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


export type Query_RootVerification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVerification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Type_Order_By>>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};


export type Query_RootVerification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Type_Order_By>>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};


export type Query_RootVerification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "question" */
export type Question = {
  __typename?: 'question';
  body: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  parent?: Maybe<Question>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  question_screen?: Maybe<Question_Screen>;
  /** An array relationship */
  questions: Array<Question>;
  /** An aggregate relationship */
  questions_aggregate: Question_Aggregate;
  screen?: Maybe<Question_Screen_Enum>;
  screen_prisma: Scalars['question_screen_prisma']['output'];
};


/** columns and relationships of "question" */
export type QuestionQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


/** columns and relationships of "question" */
export type QuestionQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};

/** aggregated selection of "question" */
export type Question_Aggregate = {
  __typename?: 'question_aggregate';
  aggregate?: Maybe<Question_Aggregate_Fields>;
  nodes: Array<Question>;
};

export type Question_Aggregate_Bool_Exp = {
  count?: InputMaybe<Question_Aggregate_Bool_Exp_Count>;
};

export type Question_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "question" */
export type Question_Aggregate_Fields = {
  __typename?: 'question_aggregate_fields';
  avg?: Maybe<Question_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Question_Max_Fields>;
  min?: Maybe<Question_Min_Fields>;
  stddev?: Maybe<Question_Stddev_Fields>;
  stddev_pop?: Maybe<Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Question_Stddev_Samp_Fields>;
  sum?: Maybe<Question_Sum_Fields>;
  var_pop?: Maybe<Question_Var_Pop_Fields>;
  var_samp?: Maybe<Question_Var_Samp_Fields>;
  variance?: Maybe<Question_Variance_Fields>;
};


/** aggregate fields of "question" */
export type Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question" */
export type Question_Aggregate_Order_By = {
  avg?: InputMaybe<Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Question_Max_Order_By>;
  min?: InputMaybe<Question_Min_Order_By>;
  stddev?: InputMaybe<Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Question_Sum_Order_By>;
  var_pop?: InputMaybe<Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Question_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "question" */
export type Question_Arr_Rel_Insert_Input = {
  data: Array<Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_On_Conflict>;
};

/** aggregate avg on columns */
export type Question_Avg_Fields = {
  __typename?: 'question_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question" */
export type Question_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export type Question_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Bool_Exp>>;
  _not?: InputMaybe<Question_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parent?: InputMaybe<Question_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  question_screen?: InputMaybe<Question_Screen_Bool_Exp>;
  questions?: InputMaybe<Question_Bool_Exp>;
  questions_aggregate?: InputMaybe<Question_Aggregate_Bool_Exp>;
  screen?: InputMaybe<Question_Screen_Enum_Comparison_Exp>;
  screen_prisma?: InputMaybe<Question_Screen_Prisma_Comparison_Exp>;
};

/** unique or primary key constraints on table "question" */
export enum Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionPkey = 'question_pkey'
}

/** input type for incrementing numeric columns in table "question" */
export type Question_Inc_Input = {
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "question" */
export type Question_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent?: InputMaybe<Question_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  question_screen?: InputMaybe<Question_Screen_Obj_Rel_Insert_Input>;
  questions?: InputMaybe<Question_Arr_Rel_Insert_Input>;
  screen?: InputMaybe<Question_Screen_Enum>;
  screen_prisma?: InputMaybe<Scalars['question_screen_prisma']['input']>;
};

/** aggregate max on columns */
export type Question_Max_Fields = {
  __typename?: 'question_max_fields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  screen_prisma?: Maybe<Scalars['question_screen_prisma']['output']>;
};

/** order by max() on columns of table "question" */
export type Question_Max_Order_By = {
  body?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  screen_prisma?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Question_Min_Fields = {
  __typename?: 'question_min_fields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  screen_prisma?: Maybe<Scalars['question_screen_prisma']['output']>;
};

/** order by min() on columns of table "question" */
export type Question_Min_Order_By = {
  body?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  screen_prisma?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "question" */
export type Question_Mutation_Response = {
  __typename?: 'question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Question>;
};

/** input type for inserting object relation for remote table "question" */
export type Question_Obj_Rel_Insert_Input = {
  data: Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_On_Conflict>;
};

/** on_conflict condition type for table "question" */
export type Question_On_Conflict = {
  constraint: Question_Constraint;
  update_columns?: Array<Question_Update_Column>;
  where?: InputMaybe<Question_Bool_Exp>;
};

/** Ordering options when selecting data from "question". */
export type Question_Order_By = {
  body?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Question_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  question_screen?: InputMaybe<Question_Screen_Order_By>;
  questions_aggregate?: InputMaybe<Question_Aggregate_Order_By>;
  screen?: InputMaybe<Order_By>;
  screen_prisma?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question */
export type Question_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "question_screen" */
export type Question_Screen = {
  __typename?: 'question_screen';
  /** An array relationship */
  questions: Array<Question>;
  /** An aggregate relationship */
  questions_aggregate: Question_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "question_screen" */
export type Question_ScreenQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


/** columns and relationships of "question_screen" */
export type Question_ScreenQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};

/** aggregated selection of "question_screen" */
export type Question_Screen_Aggregate = {
  __typename?: 'question_screen_aggregate';
  aggregate?: Maybe<Question_Screen_Aggregate_Fields>;
  nodes: Array<Question_Screen>;
};

/** aggregate fields of "question_screen" */
export type Question_Screen_Aggregate_Fields = {
  __typename?: 'question_screen_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Question_Screen_Max_Fields>;
  min?: Maybe<Question_Screen_Min_Fields>;
};


/** aggregate fields of "question_screen" */
export type Question_Screen_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Screen_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "question_screen". All fields are combined with a logical 'AND'. */
export type Question_Screen_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Screen_Bool_Exp>>;
  _not?: InputMaybe<Question_Screen_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Screen_Bool_Exp>>;
  questions?: InputMaybe<Question_Bool_Exp>;
  questions_aggregate?: InputMaybe<Question_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_screen" */
export enum Question_Screen_Constraint {
  /** unique or primary key constraint on columns "value" */
  QuestionScreenPkey = 'question_screen_pkey'
}

export enum Question_Screen_Enum {
  More = 'more',
  Story = 'story'
}

/** Boolean expression to compare columns of type "question_screen_enum". All fields are combined with logical 'AND'. */
export type Question_Screen_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Question_Screen_Enum>;
  _in?: InputMaybe<Array<Question_Screen_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Question_Screen_Enum>;
  _nin?: InputMaybe<Array<Question_Screen_Enum>>;
};

/** input type for inserting data into table "question_screen" */
export type Question_Screen_Insert_Input = {
  questions?: InputMaybe<Question_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Question_Screen_Max_Fields = {
  __typename?: 'question_screen_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Question_Screen_Min_Fields = {
  __typename?: 'question_screen_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "question_screen" */
export type Question_Screen_Mutation_Response = {
  __typename?: 'question_screen_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Screen>;
};

/** input type for inserting object relation for remote table "question_screen" */
export type Question_Screen_Obj_Rel_Insert_Input = {
  data: Question_Screen_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Screen_On_Conflict>;
};

/** on_conflict condition type for table "question_screen" */
export type Question_Screen_On_Conflict = {
  constraint: Question_Screen_Constraint;
  update_columns?: Array<Question_Screen_Update_Column>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};

/** Ordering options when selecting data from "question_screen". */
export type Question_Screen_Order_By = {
  questions_aggregate?: InputMaybe<Question_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: question_screen */
export type Question_Screen_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "question_screen_prisma". All fields are combined with logical 'AND'. */
export type Question_Screen_Prisma_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _gt?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _gte?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _in?: InputMaybe<Array<Scalars['question_screen_prisma']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _lte?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _neq?: InputMaybe<Scalars['question_screen_prisma']['input']>;
  _nin?: InputMaybe<Array<Scalars['question_screen_prisma']['input']>>;
};

/** select columns of table "question_screen" */
export enum Question_Screen_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "question_screen" */
export type Question_Screen_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "question_screen" */
export type Question_Screen_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Screen_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Screen_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "question_screen" */
export enum Question_Screen_Update_Column {
  /** column name */
  Value = 'value'
}

export type Question_Screen_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Screen_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Screen_Bool_Exp;
};

/** select columns of table "question" */
export enum Question_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Position = 'position',
  /** column name */
  Screen = 'screen',
  /** column name */
  ScreenPrisma = 'screen_prisma'
}

/** input type for updating data in table "question" */
export type Question_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  screen?: InputMaybe<Question_Screen_Enum>;
  screen_prisma?: InputMaybe<Scalars['question_screen_prisma']['input']>;
};

/** aggregate stddev on columns */
export type Question_Stddev_Fields = {
  __typename?: 'question_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question" */
export type Question_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Question_Stddev_Pop_Fields = {
  __typename?: 'question_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question" */
export type Question_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Question_Stddev_Samp_Fields = {
  __typename?: 'question_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question" */
export type Question_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "question" */
export type Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  screen?: InputMaybe<Question_Screen_Enum>;
  screen_prisma?: InputMaybe<Scalars['question_screen_prisma']['input']>;
};

/** aggregate sum on columns */
export type Question_Sum_Fields = {
  __typename?: 'question_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "question" */
export type Question_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "question" */
export enum Question_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Position = 'position',
  /** column name */
  Screen = 'screen',
  /** column name */
  ScreenPrisma = 'screen_prisma'
}

export type Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Question_Var_Pop_Fields = {
  __typename?: 'question_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question" */
export type Question_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Question_Var_Samp_Fields = {
  __typename?: 'question_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question" */
export type Question_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Question_Variance_Fields = {
  __typename?: 'question_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question" */
export type Question_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** columns and relationships of "relative_position_enum" */
export type Relative_Position_Enum = {
  __typename?: 'relative_position_enum';
  /** An array relationship */
  discounts: Array<Discount>;
  /** An aggregate relationship */
  discounts_aggregate: Discount_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "relative_position_enum" */
export type Relative_Position_EnumDiscountsArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


/** columns and relationships of "relative_position_enum" */
export type Relative_Position_EnumDiscounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};

/** aggregated selection of "relative_position_enum" */
export type Relative_Position_Enum_Aggregate = {
  __typename?: 'relative_position_enum_aggregate';
  aggregate?: Maybe<Relative_Position_Enum_Aggregate_Fields>;
  nodes: Array<Relative_Position_Enum>;
};

/** aggregate fields of "relative_position_enum" */
export type Relative_Position_Enum_Aggregate_Fields = {
  __typename?: 'relative_position_enum_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Relative_Position_Enum_Max_Fields>;
  min?: Maybe<Relative_Position_Enum_Min_Fields>;
};


/** aggregate fields of "relative_position_enum" */
export type Relative_Position_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Relative_Position_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "relative_position_enum". All fields are combined with a logical 'AND'. */
export type Relative_Position_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<Relative_Position_Enum_Bool_Exp>>;
  _not?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<Relative_Position_Enum_Bool_Exp>>;
  discounts?: InputMaybe<Discount_Bool_Exp>;
  discounts_aggregate?: InputMaybe<Discount_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "relative_position_enum" */
export enum Relative_Position_Enum_Constraint {
  /** unique or primary key constraint on columns "value" */
  RelativePositionEnumPkey = 'relative_position_enum_pkey'
}

export enum Relative_Position_Enum_Enum {
  AfterSubscriptionEnd = 'after_subscription_end',
  AfterSubscriptionStart = 'after_subscription_start',
  BeforeSubscriptionEnd = 'before_subscription_end'
}

/** Boolean expression to compare columns of type "relative_position_enum_enum". All fields are combined with logical 'AND'. */
export type Relative_Position_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Relative_Position_Enum_Enum>;
  _in?: InputMaybe<Array<Relative_Position_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Relative_Position_Enum_Enum>;
  _nin?: InputMaybe<Array<Relative_Position_Enum_Enum>>;
};

/** input type for inserting data into table "relative_position_enum" */
export type Relative_Position_Enum_Insert_Input = {
  discounts?: InputMaybe<Discount_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Relative_Position_Enum_Max_Fields = {
  __typename?: 'relative_position_enum_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Relative_Position_Enum_Min_Fields = {
  __typename?: 'relative_position_enum_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "relative_position_enum" */
export type Relative_Position_Enum_Mutation_Response = {
  __typename?: 'relative_position_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Relative_Position_Enum>;
};

/** input type for inserting object relation for remote table "relative_position_enum" */
export type Relative_Position_Enum_Obj_Rel_Insert_Input = {
  data: Relative_Position_Enum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Relative_Position_Enum_On_Conflict>;
};

/** on_conflict condition type for table "relative_position_enum" */
export type Relative_Position_Enum_On_Conflict = {
  constraint: Relative_Position_Enum_Constraint;
  update_columns?: Array<Relative_Position_Enum_Update_Column>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};

/** Ordering options when selecting data from "relative_position_enum". */
export type Relative_Position_Enum_Order_By = {
  discounts_aggregate?: InputMaybe<Discount_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: relative_position_enum */
export type Relative_Position_Enum_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "relative_position_enum_prisma". All fields are combined with logical 'AND'. */
export type Relative_Position_Enum_Prisma_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _gt?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _gte?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _in?: InputMaybe<Array<Scalars['relative_position_enum_prisma']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _lte?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _neq?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
  _nin?: InputMaybe<Array<Scalars['relative_position_enum_prisma']['input']>>;
};

/** select columns of table "relative_position_enum" */
export enum Relative_Position_Enum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "relative_position_enum" */
export type Relative_Position_Enum_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "relative_position_enum" */
export type Relative_Position_Enum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Relative_Position_Enum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Relative_Position_Enum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "relative_position_enum" */
export enum Relative_Position_Enum_Update_Column {
  /** column name */
  Value = 'value'
}

export type Relative_Position_Enum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Relative_Position_Enum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Relative_Position_Enum_Bool_Exp;
};

/** columns and relationships of "required_access_discounts_view" */
export type Required_Access_Discounts_View = {
  __typename?: 'required_access_discounts_view';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** aggregated selection of "required_access_discounts_view" */
export type Required_Access_Discounts_View_Aggregate = {
  __typename?: 'required_access_discounts_view_aggregate';
  aggregate?: Maybe<Required_Access_Discounts_View_Aggregate_Fields>;
  nodes: Array<Required_Access_Discounts_View>;
};

export type Required_Access_Discounts_View_Aggregate_Bool_Exp = {
  count?: InputMaybe<Required_Access_Discounts_View_Aggregate_Bool_Exp_Count>;
};

export type Required_Access_Discounts_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "required_access_discounts_view" */
export type Required_Access_Discounts_View_Aggregate_Fields = {
  __typename?: 'required_access_discounts_view_aggregate_fields';
  avg?: Maybe<Required_Access_Discounts_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Required_Access_Discounts_View_Max_Fields>;
  min?: Maybe<Required_Access_Discounts_View_Min_Fields>;
  stddev?: Maybe<Required_Access_Discounts_View_Stddev_Fields>;
  stddev_pop?: Maybe<Required_Access_Discounts_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Required_Access_Discounts_View_Stddev_Samp_Fields>;
  sum?: Maybe<Required_Access_Discounts_View_Sum_Fields>;
  var_pop?: Maybe<Required_Access_Discounts_View_Var_Pop_Fields>;
  var_samp?: Maybe<Required_Access_Discounts_View_Var_Samp_Fields>;
  variance?: Maybe<Required_Access_Discounts_View_Variance_Fields>;
};


/** aggregate fields of "required_access_discounts_view" */
export type Required_Access_Discounts_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Aggregate_Order_By = {
  avg?: InputMaybe<Required_Access_Discounts_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Required_Access_Discounts_View_Max_Order_By>;
  min?: InputMaybe<Required_Access_Discounts_View_Min_Order_By>;
  stddev?: InputMaybe<Required_Access_Discounts_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Required_Access_Discounts_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Required_Access_Discounts_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Required_Access_Discounts_View_Sum_Order_By>;
  var_pop?: InputMaybe<Required_Access_Discounts_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Required_Access_Discounts_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Required_Access_Discounts_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Arr_Rel_Insert_Input = {
  data: Array<Required_Access_Discounts_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Required_Access_Discounts_View_Avg_Fields = {
  __typename?: 'required_access_discounts_view_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Avg_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "required_access_discounts_view". All fields are combined with a logical 'AND'. */
export type Required_Access_Discounts_View_Bool_Exp = {
  _and?: InputMaybe<Array<Required_Access_Discounts_View_Bool_Exp>>;
  _not?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
  _or?: InputMaybe<Array<Required_Access_Discounts_View_Bool_Exp>>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  days?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  relative_position?: InputMaybe<String_Comparison_Exp>;
  relative_position_prisma?: InputMaybe<Relative_Position_Enum_Prisma_Comparison_Exp>;
};

/** input type for inserting data into table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Insert_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Scalars['String']['input']>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate max on columns */
export type Required_Access_Discounts_View_Max_Fields = {
  __typename?: 'required_access_discounts_view_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** order by max() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Max_Order_By = {
  access_id?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Required_Access_Discounts_View_Min_Fields = {
  __typename?: 'required_access_discounts_view_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  relative_position?: Maybe<Scalars['String']['output']>;
  relative_position_prisma?: Maybe<Scalars['relative_position_enum_prisma']['output']>;
};

/** order by min() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Min_Order_By = {
  access_id?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "required_access_discounts_view". */
export type Required_Access_Discounts_View_Order_By = {
  access_id?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  relative_position?: InputMaybe<Order_By>;
  relative_position_prisma?: InputMaybe<Order_By>;
};

/** select columns of table "required_access_discounts_view" */
export enum Required_Access_Discounts_View_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RelativePosition = 'relative_position',
  /** column name */
  RelativePositionPrisma = 'relative_position_prisma'
}

/** aggregate stddev on columns */
export type Required_Access_Discounts_View_Stddev_Fields = {
  __typename?: 'required_access_discounts_view_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Stddev_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Required_Access_Discounts_View_Stddev_Pop_Fields = {
  __typename?: 'required_access_discounts_view_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Stddev_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Required_Access_Discounts_View_Stddev_Samp_Fields = {
  __typename?: 'required_access_discounts_view_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Stddev_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Required_Access_Discounts_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Required_Access_Discounts_View_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  relative_position?: InputMaybe<Scalars['String']['input']>;
  relative_position_prisma?: InputMaybe<Scalars['relative_position_enum_prisma']['input']>;
};

/** aggregate sum on columns */
export type Required_Access_Discounts_View_Sum_Fields = {
  __typename?: 'required_access_discounts_view_sum_fields';
  days?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Sum_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Required_Access_Discounts_View_Var_Pop_Fields = {
  __typename?: 'required_access_discounts_view_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Var_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Required_Access_Discounts_View_Var_Samp_Fields = {
  __typename?: 'required_access_discounts_view_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Var_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Required_Access_Discounts_View_Variance_Fields = {
  __typename?: 'required_access_discounts_view_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "required_access_discounts_view" */
export type Required_Access_Discounts_View_Variance_Order_By = {
  days?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "_required_access_for_discount" */
export type Required_Access_For_Discount = {
  __typename?: 'required_access_for_discount';
  A: Scalars['uuid']['output'];
  B: Scalars['uuid']['output'];
  /** An object relationship */
  access: Access;
  /** An object relationship */
  discount: Discount;
};

/** aggregated selection of "_required_access_for_discount" */
export type Required_Access_For_Discount_Aggregate = {
  __typename?: 'required_access_for_discount_aggregate';
  aggregate?: Maybe<Required_Access_For_Discount_Aggregate_Fields>;
  nodes: Array<Required_Access_For_Discount>;
};

/** aggregate fields of "_required_access_for_discount" */
export type Required_Access_For_Discount_Aggregate_Fields = {
  __typename?: 'required_access_for_discount_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Required_Access_For_Discount_Max_Fields>;
  min?: Maybe<Required_Access_For_Discount_Min_Fields>;
};


/** aggregate fields of "_required_access_for_discount" */
export type Required_Access_For_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Required_Access_For_Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "_required_access_for_discount". All fields are combined with a logical 'AND'. */
export type Required_Access_For_Discount_Bool_Exp = {
  A?: InputMaybe<Uuid_Comparison_Exp>;
  B?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Required_Access_For_Discount_Bool_Exp>>;
  _not?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
  _or?: InputMaybe<Array<Required_Access_For_Discount_Bool_Exp>>;
  access?: InputMaybe<Access_Bool_Exp>;
  discount?: InputMaybe<Discount_Bool_Exp>;
};

/** unique or primary key constraints on table "_required_access_for_discount" */
export enum Required_Access_For_Discount_Constraint {
  /** unique or primary key constraint on columns "B", "A" */
  RequiredAccessForDiscountAbUnique = '_required_access_for_discount_AB_unique'
}

/** input type for inserting data into table "_required_access_for_discount" */
export type Required_Access_For_Discount_Insert_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
  access?: InputMaybe<Access_Obj_Rel_Insert_Input>;
  discount?: InputMaybe<Discount_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Required_Access_For_Discount_Max_Fields = {
  __typename?: 'required_access_for_discount_max_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Required_Access_For_Discount_Min_Fields = {
  __typename?: 'required_access_for_discount_min_fields';
  A?: Maybe<Scalars['uuid']['output']>;
  B?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "_required_access_for_discount" */
export type Required_Access_For_Discount_Mutation_Response = {
  __typename?: 'required_access_for_discount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Required_Access_For_Discount>;
};

/** on_conflict condition type for table "_required_access_for_discount" */
export type Required_Access_For_Discount_On_Conflict = {
  constraint: Required_Access_For_Discount_Constraint;
  update_columns?: Array<Required_Access_For_Discount_Update_Column>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "_required_access_for_discount". */
export type Required_Access_For_Discount_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  access?: InputMaybe<Access_Order_By>;
  discount?: InputMaybe<Discount_Order_By>;
};

/** select columns of table "_required_access_for_discount" */
export enum Required_Access_For_Discount_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "_required_access_for_discount" */
export type Required_Access_For_Discount_Set_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "required_access_for_discount" */
export type Required_Access_For_Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Required_Access_For_Discount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Required_Access_For_Discount_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['uuid']['input']>;
  B?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "_required_access_for_discount" */
export enum Required_Access_For_Discount_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Required_Access_For_Discount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Required_Access_For_Discount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Required_Access_For_Discount_Bool_Exp;
};

/** columns and relationships of "required_discount_accesses_view" */
export type Required_Discount_Accesses_View = {
  __typename?: 'required_discount_accesses_view';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Aggregate = {
  __typename?: 'required_discount_accesses_view_aggregate';
  aggregate?: Maybe<Required_Discount_Accesses_View_Aggregate_Fields>;
  nodes: Array<Required_Discount_Accesses_View>;
};

export type Required_Discount_Accesses_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Required_Discount_Accesses_View_Aggregate_Bool_Exp_Count>;
};

export type Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Required_Discount_Accesses_View_Select_Column_Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Required_Discount_Accesses_View_Select_Column_Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Required_Discount_Accesses_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Aggregate_Fields = {
  __typename?: 'required_discount_accesses_view_aggregate_fields';
  avg?: Maybe<Required_Discount_Accesses_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Required_Discount_Accesses_View_Max_Fields>;
  min?: Maybe<Required_Discount_Accesses_View_Min_Fields>;
  stddev?: Maybe<Required_Discount_Accesses_View_Stddev_Fields>;
  stddev_pop?: Maybe<Required_Discount_Accesses_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Required_Discount_Accesses_View_Stddev_Samp_Fields>;
  sum?: Maybe<Required_Discount_Accesses_View_Sum_Fields>;
  var_pop?: Maybe<Required_Discount_Accesses_View_Var_Pop_Fields>;
  var_samp?: Maybe<Required_Discount_Accesses_View_Var_Samp_Fields>;
  variance?: Maybe<Required_Discount_Accesses_View_Variance_Fields>;
};


/** aggregate fields of "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Aggregate_Order_By = {
  avg?: InputMaybe<Required_Discount_Accesses_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Required_Discount_Accesses_View_Max_Order_By>;
  min?: InputMaybe<Required_Discount_Accesses_View_Min_Order_By>;
  stddev?: InputMaybe<Required_Discount_Accesses_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Required_Discount_Accesses_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Required_Discount_Accesses_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Required_Discount_Accesses_View_Sum_Order_By>;
  var_pop?: InputMaybe<Required_Discount_Accesses_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Required_Discount_Accesses_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Required_Discount_Accesses_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Arr_Rel_Insert_Input = {
  data: Array<Required_Discount_Accesses_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Required_Discount_Accesses_View_Avg_Fields = {
  __typename?: 'required_discount_accesses_view_avg_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Avg_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "required_discount_accesses_view". All fields are combined with a logical 'AND'. */
export type Required_Discount_Accesses_View_Bool_Exp = {
  _and?: InputMaybe<Array<Required_Discount_Accesses_View_Bool_Exp>>;
  _not?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
  _or?: InputMaybe<Array<Required_Discount_Accesses_View_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount_id?: InputMaybe<Uuid_Comparison_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remind_after_subscription?: InputMaybe<String_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Required_Discount_Accesses_View_Max_Fields = {
  __typename?: 'required_discount_accesses_view_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Required_Discount_Accesses_View_Min_Fields = {
  __typename?: 'required_discount_accesses_view_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "required_discount_accesses_view". */
export type Required_Discount_Accesses_View_Order_By = {
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** select columns of table "required_discount_accesses_view" */
export enum Required_Discount_Accesses_View_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RemindAfterSubscription = 'remind_after_subscription',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** select "required_discount_accesses_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "required_discount_accesses_view" */
export enum Required_Discount_Accesses_View_Select_Column_Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "required_discount_accesses_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "required_discount_accesses_view" */
export enum Required_Discount_Accesses_View_Select_Column_Required_Discount_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** aggregate stddev on columns */
export type Required_Discount_Accesses_View_Stddev_Fields = {
  __typename?: 'required_discount_accesses_view_stddev_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Stddev_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Required_Discount_Accesses_View_Stddev_Pop_Fields = {
  __typename?: 'required_discount_accesses_view_stddev_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Stddev_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Required_Discount_Accesses_View_Stddev_Samp_Fields = {
  __typename?: 'required_discount_accesses_view_stddev_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Stddev_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Required_Discount_Accesses_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Required_Discount_Accesses_View_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Required_Discount_Accesses_View_Sum_Fields = {
  __typename?: 'required_discount_accesses_view_sum_fields';
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Sum_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Required_Discount_Accesses_View_Var_Pop_Fields = {
  __typename?: 'required_discount_accesses_view_var_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Var_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Required_Discount_Accesses_View_Var_Samp_Fields = {
  __typename?: 'required_discount_accesses_view_var_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Var_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Required_Discount_Accesses_View_Variance_Fields = {
  __typename?: 'required_discount_accesses_view_variance_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "required_discount_accesses_view" */
export type Required_Discount_Accesses_View_Variance_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** columns and relationships of "session" */
export type Session = {
  __typename?: 'session';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "session" */
export type Session_Aggregate = {
  __typename?: 'session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

export type Session_Aggregate_Bool_Exp = {
  count?: InputMaybe<Session_Aggregate_Bool_Exp_Count>;
};

export type Session_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Session_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "session" */
export type Session_Aggregate_Fields = {
  __typename?: 'session_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
};


/** aggregate fields of "session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "session" */
export type Session_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Session_Max_Order_By>;
  min?: InputMaybe<Session_Min_Order_By>;
};

/** input type for inserting array relation for remote table "session" */
export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Session_On_Conflict>;
};

/** Boolean expression to filter rows from the table "session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  _and?: InputMaybe<Array<Session_Bool_Exp>>;
  _not?: InputMaybe<Session_Bool_Exp>;
  _or?: InputMaybe<Array<Session_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "session" */
export enum Session_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionPkey = 'session_pkey',
  /** unique or primary key constraint on columns "token" */
  SessionTokenUnique = 'session_token_unique'
}

/** input type for inserting data into table "session" */
export type Session_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: 'session_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "session" */
export type Session_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: 'session_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "session" */
export type Session_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "session" */
export type Session_Mutation_Response = {
  __typename?: 'session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** on_conflict condition type for table "session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns?: Array<Session_Update_Column>;
  where?: InputMaybe<Session_Bool_Exp>;
};

/** Ordering options when selecting data from "session". */
export type Session_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: session */
export type Session_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "session" */
export enum Session_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "session" */
export type Session_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "session" */
export type Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Session_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "session" */
export enum Session_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id'
}

export type Session_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Session_Bool_Exp;
};

export type Set_Last_Played_Args = {
  _last_played?: InputMaybe<Scalars['timestamp']['input']>;
  _title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "sound" */
export type Sound = {
  __typename?: 'sound';
  /** An object relationship */
  file: File;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  titles: Array<Title>;
  /** An aggregate relationship */
  titles_aggregate: Title_Aggregate;
};


/** columns and relationships of "sound" */
export type SoundTitlesArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


/** columns and relationships of "sound" */
export type SoundTitles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};

/** aggregated selection of "sound" */
export type Sound_Aggregate = {
  __typename?: 'sound_aggregate';
  aggregate?: Maybe<Sound_Aggregate_Fields>;
  nodes: Array<Sound>;
};

export type Sound_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sound_Aggregate_Bool_Exp_Count>;
};

export type Sound_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sound_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sound_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sound" */
export type Sound_Aggregate_Fields = {
  __typename?: 'sound_aggregate_fields';
  avg?: Maybe<Sound_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sound_Max_Fields>;
  min?: Maybe<Sound_Min_Fields>;
  stddev?: Maybe<Sound_Stddev_Fields>;
  stddev_pop?: Maybe<Sound_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sound_Stddev_Samp_Fields>;
  sum?: Maybe<Sound_Sum_Fields>;
  var_pop?: Maybe<Sound_Var_Pop_Fields>;
  var_samp?: Maybe<Sound_Var_Samp_Fields>;
  variance?: Maybe<Sound_Variance_Fields>;
};


/** aggregate fields of "sound" */
export type Sound_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sound_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "sound" */
export type Sound_Aggregate_Order_By = {
  avg?: InputMaybe<Sound_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sound_Max_Order_By>;
  min?: InputMaybe<Sound_Min_Order_By>;
  stddev?: InputMaybe<Sound_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sound_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sound_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sound_Sum_Order_By>;
  var_pop?: InputMaybe<Sound_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sound_Var_Samp_Order_By>;
  variance?: InputMaybe<Sound_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sound" */
export type Sound_Arr_Rel_Insert_Input = {
  data: Array<Sound_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sound_On_Conflict>;
};

/** aggregate avg on columns */
export type Sound_Avg_Fields = {
  __typename?: 'sound_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "sound" */
export type Sound_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sound". All fields are combined with a logical 'AND'. */
export type Sound_Bool_Exp = {
  _and?: InputMaybe<Array<Sound_Bool_Exp>>;
  _not?: InputMaybe<Sound_Bool_Exp>;
  _or?: InputMaybe<Array<Sound_Bool_Exp>>;
  file?: InputMaybe<File_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  titles?: InputMaybe<Title_Bool_Exp>;
  titles_aggregate?: InputMaybe<Title_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "sound" */
export enum Sound_Constraint {
  /** unique or primary key constraint on columns "id" */
  SoundPkey = 'sound_pkey'
}

/** input type for incrementing numeric columns in table "sound" */
export type Sound_Inc_Input = {
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "sound" */
export type Sound_Insert_Input = {
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  titles?: InputMaybe<Title_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sound_Max_Fields = {
  __typename?: 'sound_max_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "sound" */
export type Sound_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sound_Min_Fields = {
  __typename?: 'sound_min_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "sound" */
export type Sound_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sound" */
export type Sound_Mutation_Response = {
  __typename?: 'sound_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sound>;
};

/** input type for inserting object relation for remote table "sound" */
export type Sound_Obj_Rel_Insert_Input = {
  data: Sound_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sound_On_Conflict>;
};

/** on_conflict condition type for table "sound" */
export type Sound_On_Conflict = {
  constraint: Sound_Constraint;
  update_columns?: Array<Sound_Update_Column>;
  where?: InputMaybe<Sound_Bool_Exp>;
};

/** Ordering options when selecting data from "sound". */
export type Sound_Order_By = {
  file?: InputMaybe<File_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  titles_aggregate?: InputMaybe<Title_Aggregate_Order_By>;
};

/** primary key columns input for table: sound */
export type Sound_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "sound" */
export enum Sound_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "sound" */
export type Sound_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Sound_Stddev_Fields = {
  __typename?: 'sound_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "sound" */
export type Sound_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sound_Stddev_Pop_Fields = {
  __typename?: 'sound_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "sound" */
export type Sound_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sound_Stddev_Samp_Fields = {
  __typename?: 'sound_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "sound" */
export type Sound_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sound" */
export type Sound_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sound_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sound_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Sound_Sum_Fields = {
  __typename?: 'sound_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "sound" */
export type Sound_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "sound" */
export enum Sound_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

export type Sound_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sound_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sound_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sound_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sound_Var_Pop_Fields = {
  __typename?: 'sound_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "sound" */
export type Sound_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sound_Var_Samp_Fields = {
  __typename?: 'sound_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "sound" */
export type Sound_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sound_Variance_Fields = {
  __typename?: 'sound_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "sound" */
export type Sound_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "access" */
  access: Array<Access>;
  /** fetch aggregated fields from the table: "access" */
  access_aggregate: Access_Aggregate;
  /** fetch data from the table: "access" using primary key columns */
  access_by_pk?: Maybe<Access>;
  /** fetch data from the table in a streaming manner: "access" */
  access_stream: Array<Access>;
  /** fetch data from the table: "_access_title" */
  access_title: Array<Access_Title>;
  /** fetch aggregated fields from the table: "_access_title" */
  access_title_aggregate: Access_Title_Aggregate;
  /** fetch data from the table in a streaming manner: "_access_title" */
  access_title_stream: Array<Access_Title>;
  /** fetch data from the table: "access_titles_view" */
  access_titles_view: Array<Access_Titles_View>;
  /** fetch aggregated fields from the table: "access_titles_view" */
  access_titles_view_aggregate: Access_Titles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "access_titles_view" */
  access_titles_view_stream: Array<Access_Titles_View>;
  /** fetch data from the table: "activation" */
  activation: Array<Activation>;
  /** fetch aggregated fields from the table: "activation" */
  activation_aggregate: Activation_Aggregate;
  /** fetch data from the table: "activation" using primary key columns */
  activation_by_pk?: Maybe<Activation>;
  /** fetch data from the table in a streaming manner: "activation" */
  activation_stream: Array<Activation>;
  /** fetch data from the table: "_activation_title" */
  activation_title: Array<Activation_Title>;
  /** fetch aggregated fields from the table: "_activation_title" */
  activation_title_aggregate: Activation_Title_Aggregate;
  /** fetch data from the table in a streaming manner: "_activation_title" */
  activation_title_stream: Array<Activation_Title>;
  /** fetch data from the table: "activation_titles_view" */
  activation_titles_view: Array<Activation_Titles_View>;
  /** fetch aggregated fields from the table: "activation_titles_view" */
  activation_titles_view_aggregate: Activation_Titles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "activation_titles_view" */
  activation_titles_view_stream: Array<Activation_Titles_View>;
  /** fetch data from the table: "code" */
  code: Array<Code>;
  /** fetch aggregated fields from the table: "code" */
  code_aggregate: Code_Aggregate;
  /** fetch data from the table: "code" using primary key columns */
  code_by_pk?: Maybe<Code>;
  /** fetch data from the table in a streaming manner: "code" */
  code_stream: Array<Code>;
  /** fetch data from the table: "collection" */
  collection: Array<Collection>;
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table in a streaming manner: "collection" */
  collection_stream: Array<Collection>;
  /** fetch data from the table: "_collection_title" */
  collection_title: Array<Collection_Title>;
  /** fetch aggregated fields from the table: "_collection_title" */
  collection_title_aggregate: Collection_Title_Aggregate;
  /** fetch data from the table in a streaming manner: "_collection_title" */
  collection_title_stream: Array<Collection_Title>;
  /** fetch data from the table: "collection_titles_view" */
  collection_titles_view: Array<Collection_Titles_View>;
  /** fetch aggregated fields from the table: "collection_titles_view" */
  collection_titles_view_aggregate: Collection_Titles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "collection_titles_view" */
  collection_titles_view_stream: Array<Collection_Titles_View>;
  /** fetch data from the table: "disabled_access_discounts_view" */
  disabled_access_discounts_view: Array<Disabled_Access_Discounts_View>;
  /** fetch aggregated fields from the table: "disabled_access_discounts_view" */
  disabled_access_discounts_view_aggregate: Disabled_Access_Discounts_View_Aggregate;
  /** fetch data from the table in a streaming manner: "disabled_access_discounts_view" */
  disabled_access_discounts_view_stream: Array<Disabled_Access_Discounts_View>;
  /** fetch data from the table: "_disabled_access_for_discount" */
  disabled_access_for_discount: Array<Disabled_Access_For_Discount>;
  /** fetch aggregated fields from the table: "_disabled_access_for_discount" */
  disabled_access_for_discount_aggregate: Disabled_Access_For_Discount_Aggregate;
  /** fetch data from the table in a streaming manner: "_disabled_access_for_discount" */
  disabled_access_for_discount_stream: Array<Disabled_Access_For_Discount>;
  /** fetch data from the table: "disabled_discount_accesses_view" */
  disabled_discount_accesses_view: Array<Disabled_Discount_Accesses_View>;
  /** fetch aggregated fields from the table: "disabled_discount_accesses_view" */
  disabled_discount_accesses_view_aggregate: Disabled_Discount_Accesses_View_Aggregate;
  /** fetch data from the table in a streaming manner: "disabled_discount_accesses_view" */
  disabled_discount_accesses_view_stream: Array<Disabled_Discount_Accesses_View>;
  /** fetch data from the table: "discount" */
  discount: Array<Discount>;
  /** fetch aggregated fields from the table: "discount" */
  discount_aggregate: Discount_Aggregate;
  /** fetch data from the table: "discount" using primary key columns */
  discount_by_pk?: Maybe<Discount>;
  /** fetch data from the table: "discount_proposal" */
  discount_proposal: Array<Discount_Proposal>;
  /** fetch aggregated fields from the table: "discount_proposal" */
  discount_proposal_aggregate: Discount_Proposal_Aggregate;
  /** fetch data from the table: "discount_proposal" using primary key columns */
  discount_proposal_by_pk?: Maybe<Discount_Proposal>;
  /** fetch data from the table in a streaming manner: "discount_proposal" */
  discount_proposal_stream: Array<Discount_Proposal>;
  /** fetch data from the table in a streaming manner: "discount" */
  discount_stream: Array<Discount>;
  /** fetch data from the table: "favorite" */
  favorite: Array<Favorite>;
  /** fetch aggregated fields from the table: "favorite" */
  favorite_aggregate: Favorite_Aggregate;
  /** fetch data from the table: "favorite" using primary key columns */
  favorite_by_pk?: Maybe<Favorite>;
  /** fetch data from the table in a streaming manner: "favorite" */
  favorite_stream: Array<Favorite>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table in a streaming manner: "feedback" */
  feedback_stream: Array<Feedback>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "in_app_purchase" */
  in_app_purchase: Array<In_App_Purchase>;
  /** fetch data from the table: "in_app_purchase_activation" */
  in_app_purchase_activation: Array<In_App_Purchase_Activation>;
  /** fetch aggregated fields from the table: "in_app_purchase_activation" */
  in_app_purchase_activation_aggregate: In_App_Purchase_Activation_Aggregate;
  /** fetch data from the table: "in_app_purchase_activation" using primary key columns */
  in_app_purchase_activation_by_pk?: Maybe<In_App_Purchase_Activation>;
  /** fetch data from the table in a streaming manner: "in_app_purchase_activation" */
  in_app_purchase_activation_stream: Array<In_App_Purchase_Activation>;
  /** fetch aggregated fields from the table: "in_app_purchase" */
  in_app_purchase_aggregate: In_App_Purchase_Aggregate;
  /** fetch data from the table: "in_app_purchase" using primary key columns */
  in_app_purchase_by_pk?: Maybe<In_App_Purchase>;
  /** fetch data from the table: "in_app_purchase_platform" */
  in_app_purchase_platform: Array<In_App_Purchase_Platform>;
  /** fetch aggregated fields from the table: "in_app_purchase_platform" */
  in_app_purchase_platform_aggregate: In_App_Purchase_Platform_Aggregate;
  /** fetch data from the table: "in_app_purchase_platform" using primary key columns */
  in_app_purchase_platform_by_pk?: Maybe<In_App_Purchase_Platform>;
  /** fetch data from the table in a streaming manner: "in_app_purchase_platform" */
  in_app_purchase_platform_stream: Array<In_App_Purchase_Platform>;
  /** fetch data from the table in a streaming manner: "in_app_purchase" */
  in_app_purchase_stream: Array<In_App_Purchase>;
  /** fetch data from the table: "listening" */
  listening: Array<Listening>;
  /** fetch aggregated fields from the table: "listening" */
  listening_aggregate: Listening_Aggregate;
  /** fetch data from the table: "listening" using primary key columns */
  listening_by_pk?: Maybe<Listening>;
  /** fetch data from the table in a streaming manner: "listening" */
  listening_stream: Array<Listening>;
  /** fetch data from the table: "magenta_subscription" */
  magenta_subscription: Array<Magenta_Subscription>;
  /** fetch aggregated fields from the table: "magenta_subscription" */
  magenta_subscription_aggregate: Magenta_Subscription_Aggregate;
  /** fetch data from the table: "magenta_subscription" using primary key columns */
  magenta_subscription_by_pk?: Maybe<Magenta_Subscription>;
  /** fetch data from the table in a streaming manner: "magenta_subscription" */
  magenta_subscription_stream: Array<Magenta_Subscription>;
  /** fetch data from the table: "opening" */
  opening: Array<Opening>;
  /** fetch aggregated fields from the table: "opening" */
  opening_aggregate: Opening_Aggregate;
  /** fetch data from the table: "opening" using primary key columns */
  opening_by_pk?: Maybe<Opening>;
  /** fetch data from the table in a streaming manner: "opening" */
  opening_stream: Array<Opening>;
  /** fetch data from the table: "parameter" */
  parameter: Array<Parameter>;
  /** fetch aggregated fields from the table: "parameter" */
  parameter_aggregate: Parameter_Aggregate;
  /** fetch data from the table: "parameter" using primary key columns */
  parameter_by_pk?: Maybe<Parameter>;
  /** fetch data from the table: "parameter_key" */
  parameter_key: Array<Parameter_Key>;
  /** fetch aggregated fields from the table: "parameter_key" */
  parameter_key_aggregate: Parameter_Key_Aggregate;
  /** fetch data from the table: "parameter_key" using primary key columns */
  parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** fetch data from the table in a streaming manner: "parameter_key" */
  parameter_key_stream: Array<Parameter_Key>;
  /** fetch data from the table in a streaming manner: "parameter" */
  parameter_stream: Array<Parameter>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table in a streaming manner: "profile" */
  profile_stream: Array<Profile>;
  /** fetch data from the table: "profile_titles_view" */
  profile_titles_view: Array<Profile_Titles_View>;
  /** fetch aggregated fields from the table: "profile_titles_view" */
  profile_titles_view_aggregate: Profile_Titles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "profile_titles_view" */
  profile_titles_view_stream: Array<Profile_Titles_View>;
  /** fetch data from the table: "promo" */
  promo: Array<Promo>;
  /** fetch aggregated fields from the table: "promo" */
  promo_aggregate: Promo_Aggregate;
  /** fetch data from the table: "promo" using primary key columns */
  promo_by_pk?: Maybe<Promo>;
  /** fetch data from the table in a streaming manner: "promo" */
  promo_stream: Array<Promo>;
  /** fetch data from the table: "_promo_title" */
  promo_title: Array<Promo_Title>;
  /** fetch aggregated fields from the table: "_promo_title" */
  promo_title_aggregate: Promo_Title_Aggregate;
  /** fetch data from the table in a streaming manner: "_promo_title" */
  promo_title_stream: Array<Promo_Title>;
  /** fetch data from the table: "promo_titles_view" */
  promo_titles_view: Array<Promo_Titles_View>;
  /** fetch aggregated fields from the table: "promo_titles_view" */
  promo_titles_view_aggregate: Promo_Titles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "promo_titles_view" */
  promo_titles_view_stream: Array<Promo_Titles_View>;
  /** fetch data from the table: "purchase" */
  purchase: Array<Purchase>;
  /** fetch aggregated fields from the table: "purchase" */
  purchase_aggregate: Purchase_Aggregate;
  /** fetch data from the table: "purchase" using primary key columns */
  purchase_by_pk?: Maybe<Purchase>;
  /** fetch data from the table in a streaming manner: "purchase" */
  purchase_stream: Array<Purchase>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: Question_Aggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table: "question_screen" */
  question_screen: Array<Question_Screen>;
  /** fetch aggregated fields from the table: "question_screen" */
  question_screen_aggregate: Question_Screen_Aggregate;
  /** fetch data from the table: "question_screen" using primary key columns */
  question_screen_by_pk?: Maybe<Question_Screen>;
  /** fetch data from the table in a streaming manner: "question_screen" */
  question_screen_stream: Array<Question_Screen>;
  /** fetch data from the table in a streaming manner: "question" */
  question_stream: Array<Question>;
  /** fetch data from the table: "relative_position_enum" */
  relative_position_enum: Array<Relative_Position_Enum>;
  /** fetch aggregated fields from the table: "relative_position_enum" */
  relative_position_enum_aggregate: Relative_Position_Enum_Aggregate;
  /** fetch data from the table: "relative_position_enum" using primary key columns */
  relative_position_enum_by_pk?: Maybe<Relative_Position_Enum>;
  /** fetch data from the table in a streaming manner: "relative_position_enum" */
  relative_position_enum_stream: Array<Relative_Position_Enum>;
  /** fetch data from the table: "required_access_discounts_view" */
  required_access_discounts_view: Array<Required_Access_Discounts_View>;
  /** fetch aggregated fields from the table: "required_access_discounts_view" */
  required_access_discounts_view_aggregate: Required_Access_Discounts_View_Aggregate;
  /** fetch data from the table in a streaming manner: "required_access_discounts_view" */
  required_access_discounts_view_stream: Array<Required_Access_Discounts_View>;
  /** fetch data from the table: "_required_access_for_discount" */
  required_access_for_discount: Array<Required_Access_For_Discount>;
  /** fetch aggregated fields from the table: "_required_access_for_discount" */
  required_access_for_discount_aggregate: Required_Access_For_Discount_Aggregate;
  /** fetch data from the table in a streaming manner: "_required_access_for_discount" */
  required_access_for_discount_stream: Array<Required_Access_For_Discount>;
  /** fetch data from the table: "required_discount_accesses_view" */
  required_discount_accesses_view: Array<Required_Discount_Accesses_View>;
  /** fetch aggregated fields from the table: "required_discount_accesses_view" */
  required_discount_accesses_view_aggregate: Required_Discount_Accesses_View_Aggregate;
  /** fetch data from the table in a streaming manner: "required_discount_accesses_view" */
  required_discount_accesses_view_stream: Array<Required_Discount_Accesses_View>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table in a streaming manner: "session" */
  session_stream: Array<Session>;
  /** fetch data from the table: "sound" */
  sound: Array<Sound>;
  /** fetch aggregated fields from the table: "sound" */
  sound_aggregate: Sound_Aggregate;
  /** fetch data from the table: "sound" using primary key columns */
  sound_by_pk?: Maybe<Sound>;
  /** fetch data from the table in a streaming manner: "sound" */
  sound_stream: Array<Sound>;
  /** fetch data from the table: "title" */
  title: Array<Title>;
  /** fetch data from the table: "title_accesses_view" */
  title_accesses_view: Array<Title_Accesses_View>;
  /** fetch aggregated fields from the table: "title_accesses_view" */
  title_accesses_view_aggregate: Title_Accesses_View_Aggregate;
  /** fetch data from the table in a streaming manner: "title_accesses_view" */
  title_accesses_view_stream: Array<Title_Accesses_View>;
  /** fetch data from the table: "title_activations_view" */
  title_activations_view: Array<Title_Activations_View>;
  /** fetch aggregated fields from the table: "title_activations_view" */
  title_activations_view_aggregate: Title_Activations_View_Aggregate;
  /** fetch data from the table in a streaming manner: "title_activations_view" */
  title_activations_view_stream: Array<Title_Activations_View>;
  /** fetch aggregated fields from the table: "title" */
  title_aggregate: Title_Aggregate;
  /** fetch data from the table: "title" using primary key columns */
  title_by_pk?: Maybe<Title>;
  /** fetch data from the table: "title_collections_view" */
  title_collections_view: Array<Title_Collections_View>;
  /** fetch aggregated fields from the table: "title_collections_view" */
  title_collections_view_aggregate: Title_Collections_View_Aggregate;
  /** fetch data from the table in a streaming manner: "title_collections_view" */
  title_collections_view_stream: Array<Title_Collections_View>;
  /** fetch data from the table: "title_promos_view" */
  title_promos_view: Array<Title_Promos_View>;
  /** fetch aggregated fields from the table: "title_promos_view" */
  title_promos_view_aggregate: Title_Promos_View_Aggregate;
  /** fetch data from the table in a streaming manner: "title_promos_view" */
  title_promos_view_stream: Array<Title_Promos_View>;
  /** fetch data from the table in a streaming manner: "title" */
  title_stream: Array<Title>;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: Track_Aggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** fetch data from the table in a streaming manner: "track" */
  track_stream: Array<Track>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "verification" */
  verification: Array<Verification>;
  /** fetch aggregated fields from the table: "verification" */
  verification_aggregate: Verification_Aggregate;
  /** fetch data from the table: "verification" using primary key columns */
  verification_by_pk?: Maybe<Verification>;
  /** fetch data from the table in a streaming manner: "verification" */
  verification_stream: Array<Verification>;
  /** fetch data from the table: "verification_type" */
  verification_type: Array<Verification_Type>;
  /** fetch aggregated fields from the table: "verification_type" */
  verification_type_aggregate: Verification_Type_Aggregate;
  /** fetch data from the table: "verification_type" using primary key columns */
  verification_type_by_pk?: Maybe<Verification_Type>;
  /** fetch data from the table in a streaming manner: "verification_type" */
  verification_type_stream: Array<Verification_Type>;
};


export type Subscription_RootAccessArgs = {
  distinct_on?: InputMaybe<Array<Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Order_By>>;
  where?: InputMaybe<Access_Bool_Exp>;
};


export type Subscription_RootAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Order_By>>;
  where?: InputMaybe<Access_Bool_Exp>;
};


export type Subscription_RootAccess_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAccess_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Access_Bool_Exp>;
};


export type Subscription_RootAccess_TitleArgs = {
  distinct_on?: InputMaybe<Array<Access_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Title_Order_By>>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};


export type Subscription_RootAccess_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Title_Order_By>>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};


export type Subscription_RootAccess_Title_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Access_Title_Stream_Cursor_Input>>;
  where?: InputMaybe<Access_Title_Bool_Exp>;
};


export type Subscription_RootAccess_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


export type Subscription_RootAccess_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Access_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Access_Titles_View_Order_By>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


export type Subscription_RootAccess_Titles_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Access_Titles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Access_Titles_View_Bool_Exp>;
};


export type Subscription_RootActivationArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


export type Subscription_RootActivation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Order_By>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


export type Subscription_RootActivation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootActivation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Activation_Stream_Cursor_Input>>;
  where?: InputMaybe<Activation_Bool_Exp>;
};


export type Subscription_RootActivation_TitleArgs = {
  distinct_on?: InputMaybe<Array<Activation_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Title_Order_By>>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};


export type Subscription_RootActivation_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Title_Order_By>>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};


export type Subscription_RootActivation_Title_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Activation_Title_Stream_Cursor_Input>>;
  where?: InputMaybe<Activation_Title_Bool_Exp>;
};


export type Subscription_RootActivation_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


export type Subscription_RootActivation_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activation_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Activation_Titles_View_Order_By>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


export type Subscription_RootActivation_Titles_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Activation_Titles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Activation_Titles_View_Bool_Exp>;
};


export type Subscription_RootCodeArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


export type Subscription_RootCode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Code_Order_By>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


export type Subscription_RootCode_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCode_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Code_Bool_Exp>;
};


export type Subscription_RootCollectionArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCollection_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Collection_Stream_Cursor_Input>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_TitleArgs = {
  distinct_on?: InputMaybe<Array<Collection_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Title_Order_By>>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};


export type Subscription_RootCollection_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Title_Order_By>>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};


export type Subscription_RootCollection_Title_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Collection_Title_Stream_Cursor_Input>>;
  where?: InputMaybe<Collection_Title_Bool_Exp>;
};


export type Subscription_RootCollection_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


export type Subscription_RootCollection_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Collection_Titles_View_Order_By>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


export type Subscription_RootCollection_Titles_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Collection_Titles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Collection_Titles_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_Discounts_ViewArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_Discounts_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_Discounts_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Disabled_Access_Discounts_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Disabled_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_For_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_For_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootDisabled_Access_For_Discount_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Disabled_Access_For_Discount_Stream_Cursor_Input>>;
  where?: InputMaybe<Disabled_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootDisabled_Discount_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Discount_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Disabled_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Disabled_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootDisabled_Discount_Accesses_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Disabled_Discount_Accesses_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Disabled_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootDiscountArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


export type Subscription_RootDiscount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Order_By>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


export type Subscription_RootDiscount_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDiscount_ProposalArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


export type Subscription_RootDiscount_Proposal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discount_Proposal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Discount_Proposal_Order_By>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


export type Subscription_RootDiscount_Proposal_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDiscount_Proposal_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Discount_Proposal_Stream_Cursor_Input>>;
  where?: InputMaybe<Discount_Proposal_Bool_Exp>;
};


export type Subscription_RootDiscount_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Discount_Stream_Cursor_Input>>;
  where?: InputMaybe<Discount_Bool_Exp>;
};


export type Subscription_RootFavoriteArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


export type Subscription_RootFavorite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


export type Subscription_RootFavorite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFavorite_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Favorite_Stream_Cursor_Input>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


export type Subscription_RootFeedbackArgs = {
  distinct_on?: InputMaybe<Array<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeedback_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootIn_App_PurchaseArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_ActivationArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_Activation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Activation_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_Activation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootIn_App_Purchase_Activation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<In_App_Purchase_Activation_Stream_Cursor_Input>>;
  where?: InputMaybe<In_App_Purchase_Activation_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootIn_App_Purchase_PlatformArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Platform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Platform_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_Platform_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_App_Purchase_Platform_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_App_Purchase_Platform_Order_By>>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_Platform_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootIn_App_Purchase_Platform_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<In_App_Purchase_Platform_Stream_Cursor_Input>>;
  where?: InputMaybe<In_App_Purchase_Platform_Bool_Exp>;
};


export type Subscription_RootIn_App_Purchase_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<In_App_Purchase_Stream_Cursor_Input>>;
  where?: InputMaybe<In_App_Purchase_Bool_Exp>;
};


export type Subscription_RootListeningArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


export type Subscription_RootListening_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


export type Subscription_RootListening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootListening_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Listening_Stream_Cursor_Input>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


export type Subscription_RootMagenta_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Magenta_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Magenta_Subscription_Order_By>>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};


export type Subscription_RootMagenta_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magenta_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Magenta_Subscription_Order_By>>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};


export type Subscription_RootMagenta_Subscription_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMagenta_Subscription_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Magenta_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Magenta_Subscription_Bool_Exp>;
};


export type Subscription_RootOpeningArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


export type Subscription_RootOpening_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


export type Subscription_RootOpening_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOpening_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Opening_Stream_Cursor_Input>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


export type Subscription_RootParameterArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type Subscription_RootParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type Subscription_RootParameter_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootParameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type Subscription_RootParameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type Subscription_RootParameter_Key_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootParameter_Key_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parameter_Key_Stream_Cursor_Input>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type Subscription_RootParameter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parameter_Stream_Cursor_Input>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProfile_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Profile_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Titles_View_Order_By>>;
  where?: InputMaybe<Profile_Titles_View_Bool_Exp>;
};


export type Subscription_RootProfile_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profile_Titles_View_Order_By>>;
  where?: InputMaybe<Profile_Titles_View_Bool_Exp>;
};


export type Subscription_RootProfile_Titles_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Profile_Titles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Titles_View_Bool_Exp>;
};


export type Subscription_RootPromoArgs = {
  distinct_on?: InputMaybe<Array<Promo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Order_By>>;
  where?: InputMaybe<Promo_Bool_Exp>;
};


export type Subscription_RootPromo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Order_By>>;
  where?: InputMaybe<Promo_Bool_Exp>;
};


export type Subscription_RootPromo_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPromo_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Promo_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Bool_Exp>;
};


export type Subscription_RootPromo_TitleArgs = {
  distinct_on?: InputMaybe<Array<Promo_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Title_Order_By>>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};


export type Subscription_RootPromo_Title_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Title_Order_By>>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};


export type Subscription_RootPromo_Title_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Promo_Title_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Title_Bool_Exp>;
};


export type Subscription_RootPromo_Titles_ViewArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


export type Subscription_RootPromo_Titles_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Titles_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Promo_Titles_View_Order_By>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


export type Subscription_RootPromo_Titles_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Promo_Titles_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Titles_View_Bool_Exp>;
};


export type Subscription_RootPurchaseArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


export type Subscription_RootPurchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


export type Subscription_RootPurchase_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPurchase_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Purchase_Stream_Cursor_Input>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


export type Subscription_RootQuestionArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


export type Subscription_RootQuestion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


export type Subscription_RootQuestion_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestion_ScreenArgs = {
  distinct_on?: InputMaybe<Array<Question_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Screen_Order_By>>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};


export type Subscription_RootQuestion_Screen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Screen_Order_By>>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};


export type Subscription_RootQuestion_Screen_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootQuestion_Screen_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Question_Screen_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Screen_Bool_Exp>;
};


export type Subscription_RootQuestion_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Bool_Exp>;
};


export type Subscription_RootRelative_Position_EnumArgs = {
  distinct_on?: InputMaybe<Array<Relative_Position_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relative_Position_Enum_Order_By>>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};


export type Subscription_RootRelative_Position_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relative_Position_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relative_Position_Enum_Order_By>>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};


export type Subscription_RootRelative_Position_Enum_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootRelative_Position_Enum_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Relative_Position_Enum_Stream_Cursor_Input>>;
  where?: InputMaybe<Relative_Position_Enum_Bool_Exp>;
};


export type Subscription_RootRequired_Access_Discounts_ViewArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootRequired_Access_Discounts_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_Discounts_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_Discounts_View_Order_By>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootRequired_Access_Discounts_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Required_Access_Discounts_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Required_Access_Discounts_View_Bool_Exp>;
};


export type Subscription_RootRequired_Access_For_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootRequired_Access_For_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Access_For_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Access_For_Discount_Order_By>>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootRequired_Access_For_Discount_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Required_Access_For_Discount_Stream_Cursor_Input>>;
  where?: InputMaybe<Required_Access_For_Discount_Bool_Exp>;
};


export type Subscription_RootRequired_Discount_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootRequired_Discount_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Required_Discount_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Required_Discount_Accesses_View_Order_By>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootRequired_Discount_Accesses_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Required_Discount_Accesses_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Required_Discount_Accesses_View_Bool_Exp>;
};


export type Subscription_RootSessionArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSession_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


export type Subscription_RootSoundArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


export type Subscription_RootSound_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sound_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sound_Order_By>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


export type Subscription_RootSound_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSound_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sound_Stream_Cursor_Input>>;
  where?: InputMaybe<Sound_Bool_Exp>;
};


export type Subscription_RootTitleArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


export type Subscription_RootTitle_Accesses_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


export type Subscription_RootTitle_Accesses_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


export type Subscription_RootTitle_Accesses_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Title_Accesses_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


export type Subscription_RootTitle_Activations_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


export type Subscription_RootTitle_Activations_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


export type Subscription_RootTitle_Activations_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Title_Activations_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


export type Subscription_RootTitle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Order_By>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


export type Subscription_RootTitle_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTitle_Collections_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


export type Subscription_RootTitle_Collections_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


export type Subscription_RootTitle_Collections_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Title_Collections_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


export type Subscription_RootTitle_Promos_ViewArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


export type Subscription_RootTitle_Promos_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


export type Subscription_RootTitle_Promos_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Title_Promos_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


export type Subscription_RootTitle_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Title_Stream_Cursor_Input>>;
  where?: InputMaybe<Title_Bool_Exp>;
};


export type Subscription_RootTrackArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


export type Subscription_RootTrack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


export type Subscription_RootTrack_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTrack_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Track_Stream_Cursor_Input>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootVerificationArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


export type Subscription_RootVerification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


export type Subscription_RootVerification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVerification_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Verification_Stream_Cursor_Input>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


export type Subscription_RootVerification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Type_Order_By>>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};


export type Subscription_RootVerification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Type_Order_By>>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};


export type Subscription_RootVerification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootVerification_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Verification_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** columns and relationships of "title" */
export type Title = {
  __typename?: 'title';
  /** An array relationship */
  accesses: Array<Title_Accesses_View>;
  /** An aggregate relationship */
  accesses_aggregate: Title_Accesses_View_Aggregate;
  /** An array relationship */
  activations: Array<Title_Activations_View>;
  /** An aggregate relationship */
  activations_aggregate: Title_Activations_View_Aggregate;
  adults_only: Scalars['Boolean']['output'];
  /** An array relationship */
  collections: Array<Title_Collections_View>;
  /** An aggregate relationship */
  collections_aggregate: Title_Collections_View_Aggregate;
  /** An object relationship */
  default_sound?: Maybe<Sound>;
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** A computed field, executes function "title_is_available" */
  is_available?: Maybe<Scalars['Boolean']['output']>;
  is_free: Scalars['Boolean']['output'];
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  openings: Array<Opening>;
  /** An aggregate relationship */
  openings_aggregate: Opening_Aggregate;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  promos: Array<Title_Promos_View>;
  /** An aggregate relationship */
  promos_aggregate: Title_Promos_View_Aggregate;
  purchase_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  purchased_by: Array<Purchase>;
  /** An aggregate relationship */
  purchased_by_aggregate: Purchase_Aggregate;
  show_winner: Scalars['Boolean']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: Track_Aggregate;
};


/** columns and relationships of "title" */
export type TitleAccessesArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleAccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Accesses_View_Order_By>>;
  where?: InputMaybe<Title_Accesses_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleActivationsArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Activations_View_Order_By>>;
  where?: InputMaybe<Title_Activations_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleCollectionsArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleCollections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Collections_View_Order_By>>;
  where?: InputMaybe<Title_Collections_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleOpeningsArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleOpenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Opening_Order_By>>;
  where?: InputMaybe<Opening_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitlePromosArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitlePromos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Title_Promos_View_Order_By>>;
  where?: InputMaybe<Title_Promos_View_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitlePurchased_ByArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitlePurchased_By_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Purchase_Order_By>>;
  where?: InputMaybe<Purchase_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleTracksArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleTracks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Track_Order_By>>;
  where?: InputMaybe<Track_Bool_Exp>;
};

/** columns and relationships of "title_accesses_view" */
export type Title_Accesses_View = {
  __typename?: 'title_accesses_view';
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "title_accesses_view" */
export type Title_Accesses_View_Aggregate = {
  __typename?: 'title_accesses_view_aggregate';
  aggregate?: Maybe<Title_Accesses_View_Aggregate_Fields>;
  nodes: Array<Title_Accesses_View>;
};

export type Title_Accesses_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Title_Accesses_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Title_Accesses_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Title_Accesses_View_Aggregate_Bool_Exp_Count>;
};

export type Title_Accesses_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Title_Accesses_View_Select_Column_Title_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Accesses_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Title_Accesses_View_Select_Column_Title_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Accesses_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "title_accesses_view" */
export type Title_Accesses_View_Aggregate_Fields = {
  __typename?: 'title_accesses_view_aggregate_fields';
  avg?: Maybe<Title_Accesses_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Title_Accesses_View_Max_Fields>;
  min?: Maybe<Title_Accesses_View_Min_Fields>;
  stddev?: Maybe<Title_Accesses_View_Stddev_Fields>;
  stddev_pop?: Maybe<Title_Accesses_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Title_Accesses_View_Stddev_Samp_Fields>;
  sum?: Maybe<Title_Accesses_View_Sum_Fields>;
  var_pop?: Maybe<Title_Accesses_View_Var_Pop_Fields>;
  var_samp?: Maybe<Title_Accesses_View_Var_Samp_Fields>;
  variance?: Maybe<Title_Accesses_View_Variance_Fields>;
};


/** aggregate fields of "title_accesses_view" */
export type Title_Accesses_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Title_Accesses_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "title_accesses_view" */
export type Title_Accesses_View_Aggregate_Order_By = {
  avg?: InputMaybe<Title_Accesses_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Title_Accesses_View_Max_Order_By>;
  min?: InputMaybe<Title_Accesses_View_Min_Order_By>;
  stddev?: InputMaybe<Title_Accesses_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Title_Accesses_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Title_Accesses_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Title_Accesses_View_Sum_Order_By>;
  var_pop?: InputMaybe<Title_Accesses_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Title_Accesses_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Title_Accesses_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "title_accesses_view" */
export type Title_Accesses_View_Arr_Rel_Insert_Input = {
  data: Array<Title_Accesses_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Title_Accesses_View_Avg_Fields = {
  __typename?: 'title_accesses_view_avg_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Avg_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "title_accesses_view". All fields are combined with a logical 'AND'. */
export type Title_Accesses_View_Bool_Exp = {
  _and?: InputMaybe<Array<Title_Accesses_View_Bool_Exp>>;
  _not?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  _or?: InputMaybe<Array<Title_Accesses_View_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remind_after_subscription?: InputMaybe<String_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "title_accesses_view" */
export type Title_Accesses_View_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Title_Accesses_View_Max_Fields = {
  __typename?: 'title_accesses_view_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Accesses_View_Min_Fields = {
  __typename?: 'title_accesses_view_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remind_after_subscription?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "title_accesses_view". */
export type Title_Accesses_View_Order_By = {
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remind_after_subscription?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** select columns of table "title_accesses_view" */
export enum Title_Accesses_View_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RemindAfterSubscription = 'remind_after_subscription',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** select "title_accesses_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "title_accesses_view" */
export enum Title_Accesses_View_Select_Column_Title_Accesses_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "title_accesses_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "title_accesses_view" */
export enum Title_Accesses_View_Select_Column_Title_Accesses_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** aggregate stddev on columns */
export type Title_Accesses_View_Stddev_Fields = {
  __typename?: 'title_accesses_view_stddev_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Stddev_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Title_Accesses_View_Stddev_Pop_Fields = {
  __typename?: 'title_accesses_view_stddev_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Stddev_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Title_Accesses_View_Stddev_Samp_Fields = {
  __typename?: 'title_accesses_view_stddev_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Stddev_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "title_accesses_view" */
export type Title_Accesses_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Title_Accesses_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Title_Accesses_View_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remind_after_subscription?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Title_Accesses_View_Sum_Fields = {
  __typename?: 'title_accesses_view_sum_fields';
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Sum_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Title_Accesses_View_Var_Pop_Fields = {
  __typename?: 'title_accesses_view_var_pop_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Var_Pop_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Title_Accesses_View_Var_Samp_Fields = {
  __typename?: 'title_accesses_view_var_samp_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Var_Samp_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Title_Accesses_View_Variance_Fields = {
  __typename?: 'title_accesses_view_variance_fields';
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "title_accesses_view" */
export type Title_Accesses_View_Variance_Order_By = {
  expiration?: InputMaybe<Order_By>;
};

/** columns and relationships of "title_activations_view" */
export type Title_Activations_View = {
  __typename?: 'title_activations_view';
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at?: Maybe<Scalars['timestamp']['output']>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "title_activations_view" */
export type Title_Activations_View_Aggregate = {
  __typename?: 'title_activations_view_aggregate';
  aggregate?: Maybe<Title_Activations_View_Aggregate_Fields>;
  nodes: Array<Title_Activations_View>;
};

export type Title_Activations_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Title_Activations_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Title_Activations_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Title_Activations_View_Aggregate_Bool_Exp_Count>;
};

export type Title_Activations_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Title_Activations_View_Select_Column_Title_Activations_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Activations_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Activations_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Title_Activations_View_Select_Column_Title_Activations_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Activations_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Activations_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Activations_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "title_activations_view" */
export type Title_Activations_View_Aggregate_Fields = {
  __typename?: 'title_activations_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Title_Activations_View_Max_Fields>;
  min?: Maybe<Title_Activations_View_Min_Fields>;
};


/** aggregate fields of "title_activations_view" */
export type Title_Activations_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Title_Activations_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "title_activations_view" */
export type Title_Activations_View_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Title_Activations_View_Max_Order_By>;
  min?: InputMaybe<Title_Activations_View_Min_Order_By>;
};

/** input type for inserting array relation for remote table "title_activations_view" */
export type Title_Activations_View_Arr_Rel_Insert_Input = {
  data: Array<Title_Activations_View_Insert_Input>;
};

/** Boolean expression to filter rows from the table "title_activations_view". All fields are combined with a logical 'AND'. */
export type Title_Activations_View_Bool_Exp = {
  _and?: InputMaybe<Array<Title_Activations_View_Bool_Exp>>;
  _not?: InputMaybe<Title_Activations_View_Bool_Exp>;
  _or?: InputMaybe<Array<Title_Activations_View_Bool_Exp>>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  activated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  code_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  promo_id?: InputMaybe<Uuid_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "title_activations_view" */
export type Title_Activations_View_Insert_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activated_at?: InputMaybe<Scalars['timestamp']['input']>;
  code_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Title_Activations_View_Max_Fields = {
  __typename?: 'title_activations_view_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at?: Maybe<Scalars['timestamp']['output']>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "title_activations_view" */
export type Title_Activations_View_Max_Order_By = {
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Activations_View_Min_Fields = {
  __typename?: 'title_activations_view_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  activated_at?: Maybe<Scalars['timestamp']['output']>;
  code_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['uuid']['output']>;
  promo_id?: Maybe<Scalars['uuid']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "title_activations_view" */
export type Title_Activations_View_Min_Order_By = {
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "title_activations_view". */
export type Title_Activations_View_Order_By = {
  access_id?: InputMaybe<Order_By>;
  activated_at?: InputMaybe<Order_By>;
  code_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  promo_id?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
};

/** select columns of table "title_activations_view" */
export enum Title_Activations_View_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  ActivatedAt = 'activated_at',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PromoId = 'promo_id',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "title_activations_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "title_activations_view" */
export enum Title_Activations_View_Select_Column_Title_Activations_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "title_activations_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "title_activations_view" */
export enum Title_Activations_View_Select_Column_Title_Activations_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** Streaming cursor of the table "title_activations_view" */
export type Title_Activations_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Title_Activations_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Title_Activations_View_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  activated_at?: InputMaybe<Scalars['timestamp']['input']>;
  code_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['uuid']['input']>;
  promo_id?: InputMaybe<Scalars['uuid']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregated selection of "title" */
export type Title_Aggregate = {
  __typename?: 'title_aggregate';
  aggregate?: Maybe<Title_Aggregate_Fields>;
  nodes: Array<Title>;
};

export type Title_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Title_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Title_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Title_Aggregate_Bool_Exp_Count>;
};

export type Title_Aggregate_Bool_Exp_Bool_And = {
  arguments: Title_Select_Column_Title_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Title_Select_Column_Title_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "title" */
export type Title_Aggregate_Fields = {
  __typename?: 'title_aggregate_fields';
  avg?: Maybe<Title_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Title_Max_Fields>;
  min?: Maybe<Title_Min_Fields>;
  stddev?: Maybe<Title_Stddev_Fields>;
  stddev_pop?: Maybe<Title_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Title_Stddev_Samp_Fields>;
  sum?: Maybe<Title_Sum_Fields>;
  var_pop?: Maybe<Title_Var_Pop_Fields>;
  var_samp?: Maybe<Title_Var_Samp_Fields>;
  variance?: Maybe<Title_Variance_Fields>;
};


/** aggregate fields of "title" */
export type Title_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Title_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "title" */
export type Title_Aggregate_Order_By = {
  avg?: InputMaybe<Title_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Title_Max_Order_By>;
  min?: InputMaybe<Title_Min_Order_By>;
  stddev?: InputMaybe<Title_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Title_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Title_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Title_Sum_Order_By>;
  var_pop?: InputMaybe<Title_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Title_Var_Samp_Order_By>;
  variance?: InputMaybe<Title_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "title" */
export type Title_Arr_Rel_Insert_Input = {
  data: Array<Title_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Title_On_Conflict>;
};

/** aggregate avg on columns */
export type Title_Avg_Fields = {
  __typename?: 'title_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "title" */
export type Title_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "title". All fields are combined with a logical 'AND'. */
export type Title_Bool_Exp = {
  _and?: InputMaybe<Array<Title_Bool_Exp>>;
  _not?: InputMaybe<Title_Bool_Exp>;
  _or?: InputMaybe<Array<Title_Bool_Exp>>;
  accesses?: InputMaybe<Title_Accesses_View_Bool_Exp>;
  accesses_aggregate?: InputMaybe<Title_Accesses_View_Aggregate_Bool_Exp>;
  activations?: InputMaybe<Title_Activations_View_Bool_Exp>;
  activations_aggregate?: InputMaybe<Title_Activations_View_Aggregate_Bool_Exp>;
  adults_only?: InputMaybe<Boolean_Comparison_Exp>;
  collections?: InputMaybe<Title_Collections_View_Bool_Exp>;
  collections_aggregate?: InputMaybe<Title_Collections_View_Aggregate_Bool_Exp>;
  default_sound?: InputMaybe<Sound_Bool_Exp>;
  default_sound_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expires?: InputMaybe<Timestamp_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_available?: InputMaybe<Boolean_Comparison_Exp>;
  is_free?: InputMaybe<Boolean_Comparison_Exp>;
  last_played?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  openings?: InputMaybe<Opening_Bool_Exp>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Bool_Exp>;
  paywall_id?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  promos?: InputMaybe<Title_Promos_View_Bool_Exp>;
  promos_aggregate?: InputMaybe<Title_Promos_View_Aggregate_Bool_Exp>;
  purchase_url?: InputMaybe<String_Comparison_Exp>;
  purchased_by?: InputMaybe<Purchase_Bool_Exp>;
  purchased_by_aggregate?: InputMaybe<Purchase_Aggregate_Bool_Exp>;
  show_winner?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  tracks?: InputMaybe<Track_Bool_Exp>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Bool_Exp>;
};

/** columns and relationships of "title_collections_view" */
export type Title_Collections_View = {
  __typename?: 'title_collections_view';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "title_collections_view" */
export type Title_Collections_View_Aggregate = {
  __typename?: 'title_collections_view_aggregate';
  aggregate?: Maybe<Title_Collections_View_Aggregate_Fields>;
  nodes: Array<Title_Collections_View>;
};

export type Title_Collections_View_Aggregate_Bool_Exp = {
  count?: InputMaybe<Title_Collections_View_Aggregate_Bool_Exp_Count>;
};

export type Title_Collections_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Collections_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "title_collections_view" */
export type Title_Collections_View_Aggregate_Fields = {
  __typename?: 'title_collections_view_aggregate_fields';
  avg?: Maybe<Title_Collections_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Title_Collections_View_Max_Fields>;
  min?: Maybe<Title_Collections_View_Min_Fields>;
  stddev?: Maybe<Title_Collections_View_Stddev_Fields>;
  stddev_pop?: Maybe<Title_Collections_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Title_Collections_View_Stddev_Samp_Fields>;
  sum?: Maybe<Title_Collections_View_Sum_Fields>;
  var_pop?: Maybe<Title_Collections_View_Var_Pop_Fields>;
  var_samp?: Maybe<Title_Collections_View_Var_Samp_Fields>;
  variance?: Maybe<Title_Collections_View_Variance_Fields>;
};


/** aggregate fields of "title_collections_view" */
export type Title_Collections_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Title_Collections_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "title_collections_view" */
export type Title_Collections_View_Aggregate_Order_By = {
  avg?: InputMaybe<Title_Collections_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Title_Collections_View_Max_Order_By>;
  min?: InputMaybe<Title_Collections_View_Min_Order_By>;
  stddev?: InputMaybe<Title_Collections_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Title_Collections_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Title_Collections_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Title_Collections_View_Sum_Order_By>;
  var_pop?: InputMaybe<Title_Collections_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Title_Collections_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Title_Collections_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "title_collections_view" */
export type Title_Collections_View_Arr_Rel_Insert_Input = {
  data: Array<Title_Collections_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Title_Collections_View_Avg_Fields = {
  __typename?: 'title_collections_view_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "title_collections_view" */
export type Title_Collections_View_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "title_collections_view". All fields are combined with a logical 'AND'. */
export type Title_Collections_View_Bool_Exp = {
  _and?: InputMaybe<Array<Title_Collections_View_Bool_Exp>>;
  _not?: InputMaybe<Title_Collections_View_Bool_Exp>;
  _or?: InputMaybe<Array<Title_Collections_View_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "title_collections_view" */
export type Title_Collections_View_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Title_Collections_View_Max_Fields = {
  __typename?: 'title_collections_view_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "title_collections_view" */
export type Title_Collections_View_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Collections_View_Min_Fields = {
  __typename?: 'title_collections_view_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "title_collections_view" */
export type Title_Collections_View_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "title_collections_view". */
export type Title_Collections_View_Order_By = {
  color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** select columns of table "title_collections_view" */
export enum Title_Collections_View_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  TitleId = 'title_id'
}

/** aggregate stddev on columns */
export type Title_Collections_View_Stddev_Fields = {
  __typename?: 'title_collections_view_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "title_collections_view" */
export type Title_Collections_View_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Title_Collections_View_Stddev_Pop_Fields = {
  __typename?: 'title_collections_view_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "title_collections_view" */
export type Title_Collections_View_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Title_Collections_View_Stddev_Samp_Fields = {
  __typename?: 'title_collections_view_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "title_collections_view" */
export type Title_Collections_View_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "title_collections_view" */
export type Title_Collections_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Title_Collections_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Title_Collections_View_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Title_Collections_View_Sum_Fields = {
  __typename?: 'title_collections_view_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "title_collections_view" */
export type Title_Collections_View_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Title_Collections_View_Var_Pop_Fields = {
  __typename?: 'title_collections_view_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "title_collections_view" */
export type Title_Collections_View_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Title_Collections_View_Var_Samp_Fields = {
  __typename?: 'title_collections_view_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "title_collections_view" */
export type Title_Collections_View_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Title_Collections_View_Variance_Fields = {
  __typename?: 'title_collections_view_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "title_collections_view" */
export type Title_Collections_View_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "title" */
export enum Title_Constraint {
  /** unique or primary key constraint on columns "id" */
  TitlePkey = 'title_pkey'
}

/** input type for incrementing numeric columns in table "title" */
export type Title_Inc_Input = {
  position?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "title" */
export type Title_Insert_Input = {
  accesses?: InputMaybe<Title_Accesses_View_Arr_Rel_Insert_Input>;
  activations?: InputMaybe<Title_Activations_View_Arr_Rel_Insert_Input>;
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  collections?: InputMaybe<Title_Collections_View_Arr_Rel_Insert_Input>;
  default_sound?: InputMaybe<Sound_Obj_Rel_Insert_Input>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openings?: InputMaybe<Opening_Arr_Rel_Insert_Input>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  promos?: InputMaybe<Title_Promos_View_Arr_Rel_Insert_Input>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  purchased_by?: InputMaybe<Purchase_Arr_Rel_Insert_Input>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  tracks?: InputMaybe<Track_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Title_Max_Fields = {
  __typename?: 'title_max_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "title" */
export type Title_Max_Order_By = {
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Min_Fields = {
  __typename?: 'title_min_fields';
  default_sound_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "title_expires" */
  expires?: Maybe<Scalars['timestamp']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_played?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paywall_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  purchase_url?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "title" */
export type Title_Min_Order_By = {
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "title" */
export type Title_Mutation_Response = {
  __typename?: 'title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Title>;
};

/** input type for inserting object relation for remote table "title" */
export type Title_Obj_Rel_Insert_Input = {
  data: Title_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Title_On_Conflict>;
};

/** on_conflict condition type for table "title" */
export type Title_On_Conflict = {
  constraint: Title_Constraint;
  update_columns?: Array<Title_Update_Column>;
  where?: InputMaybe<Title_Bool_Exp>;
};

/** Ordering options when selecting data from "title". */
export type Title_Order_By = {
  accesses_aggregate?: InputMaybe<Title_Accesses_View_Aggregate_Order_By>;
  activations_aggregate?: InputMaybe<Title_Activations_View_Aggregate_Order_By>;
  adults_only?: InputMaybe<Order_By>;
  collections_aggregate?: InputMaybe<Title_Collections_View_Aggregate_Order_By>;
  default_sound?: InputMaybe<Sound_Order_By>;
  default_sound_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expires?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_available?: InputMaybe<Order_By>;
  is_free?: InputMaybe<Order_By>;
  last_played?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  openings_aggregate?: InputMaybe<Opening_Aggregate_Order_By>;
  paywall_id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  promos_aggregate?: InputMaybe<Title_Promos_View_Aggregate_Order_By>;
  purchase_url?: InputMaybe<Order_By>;
  purchased_by_aggregate?: InputMaybe<Purchase_Aggregate_Order_By>;
  show_winner?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  tracks_aggregate?: InputMaybe<Track_Aggregate_Order_By>;
};

/** primary key columns input for table: title */
export type Title_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "title_promos_view" */
export type Title_Promos_View = {
  __typename?: 'title_promos_view';
  access_id?: Maybe<Scalars['uuid']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  show_in_my_collection?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_all_titles?: Maybe<Scalars['Boolean']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "title_promos_view" */
export type Title_Promos_View_Aggregate = {
  __typename?: 'title_promos_view_aggregate';
  aggregate?: Maybe<Title_Promos_View_Aggregate_Fields>;
  nodes: Array<Title_Promos_View>;
};

export type Title_Promos_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Title_Promos_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Title_Promos_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Title_Promos_View_Aggregate_Bool_Exp_Count>;
};

export type Title_Promos_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Title_Promos_View_Select_Column_Title_Promos_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Promos_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Promos_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Title_Promos_View_Select_Column_Title_Promos_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Promos_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Title_Promos_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Title_Promos_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "title_promos_view" */
export type Title_Promos_View_Aggregate_Fields = {
  __typename?: 'title_promos_view_aggregate_fields';
  avg?: Maybe<Title_Promos_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Title_Promos_View_Max_Fields>;
  min?: Maybe<Title_Promos_View_Min_Fields>;
  stddev?: Maybe<Title_Promos_View_Stddev_Fields>;
  stddev_pop?: Maybe<Title_Promos_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Title_Promos_View_Stddev_Samp_Fields>;
  sum?: Maybe<Title_Promos_View_Sum_Fields>;
  var_pop?: Maybe<Title_Promos_View_Var_Pop_Fields>;
  var_samp?: Maybe<Title_Promos_View_Var_Samp_Fields>;
  variance?: Maybe<Title_Promos_View_Variance_Fields>;
};


/** aggregate fields of "title_promos_view" */
export type Title_Promos_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Title_Promos_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "title_promos_view" */
export type Title_Promos_View_Aggregate_Order_By = {
  avg?: InputMaybe<Title_Promos_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Title_Promos_View_Max_Order_By>;
  min?: InputMaybe<Title_Promos_View_Min_Order_By>;
  stddev?: InputMaybe<Title_Promos_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Title_Promos_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Title_Promos_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Title_Promos_View_Sum_Order_By>;
  var_pop?: InputMaybe<Title_Promos_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Title_Promos_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Title_Promos_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "title_promos_view" */
export type Title_Promos_View_Arr_Rel_Insert_Input = {
  data: Array<Title_Promos_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Title_Promos_View_Avg_Fields = {
  __typename?: 'title_promos_view_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "title_promos_view" */
export type Title_Promos_View_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "title_promos_view". All fields are combined with a logical 'AND'. */
export type Title_Promos_View_Bool_Exp = {
  _and?: InputMaybe<Array<Title_Promos_View_Bool_Exp>>;
  _not?: InputMaybe<Title_Promos_View_Bool_Exp>;
  _or?: InputMaybe<Array<Title_Promos_View_Bool_Exp>>;
  access_id?: InputMaybe<Uuid_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  show_in_my_collection?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  template?: InputMaybe<String_Comparison_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
  use_all_titles?: InputMaybe<Boolean_Comparison_Exp>;
  use_by?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "title_promos_view" */
export type Title_Promos_View_Insert_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Title_Promos_View_Max_Fields = {
  __typename?: 'title_promos_view_max_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "title_promos_view" */
export type Title_Promos_View_Max_Order_By = {
  access_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Promos_View_Min_Fields = {
  __typename?: 'title_promos_view_min_fields';
  access_id?: Maybe<Scalars['uuid']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
  expiration_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  use_by?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "title_promos_view" */
export type Title_Promos_View_Min_Order_By = {
  access_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "title_promos_view". */
export type Title_Promos_View_Order_By = {
  access_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  show_in_my_collection?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  use_all_titles?: InputMaybe<Order_By>;
  use_by?: InputMaybe<Order_By>;
};

/** select columns of table "title_promos_view" */
export enum Title_Promos_View_Select_Column {
  /** column name */
  AccessId = 'access_id',
  /** column name */
  Count = 'count',
  /** column name */
  Description = 'description',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  Sku = 'sku',
  /** column name */
  Template = 'template',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UseAllTitles = 'use_all_titles',
  /** column name */
  UseBy = 'use_by'
}

/** select "title_promos_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "title_promos_view" */
export enum Title_Promos_View_Select_Column_Title_Promos_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** select "title_promos_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "title_promos_view" */
export enum Title_Promos_View_Select_Column_Title_Promos_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ShowInMyCollection = 'show_in_my_collection',
  /** column name */
  UseAllTitles = 'use_all_titles'
}

/** aggregate stddev on columns */
export type Title_Promos_View_Stddev_Fields = {
  __typename?: 'title_promos_view_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "title_promos_view" */
export type Title_Promos_View_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Title_Promos_View_Stddev_Pop_Fields = {
  __typename?: 'title_promos_view_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "title_promos_view" */
export type Title_Promos_View_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Title_Promos_View_Stddev_Samp_Fields = {
  __typename?: 'title_promos_view_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "title_promos_view" */
export type Title_Promos_View_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "title_promos_view" */
export type Title_Promos_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Title_Promos_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Title_Promos_View_Stream_Cursor_Value_Input = {
  access_id?: InputMaybe<Scalars['uuid']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['Int']['input']>;
  expiration_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  show_in_my_collection?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  use_all_titles?: InputMaybe<Scalars['Boolean']['input']>;
  use_by?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Title_Promos_View_Sum_Fields = {
  __typename?: 'title_promos_view_sum_fields';
  count?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "title_promos_view" */
export type Title_Promos_View_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Title_Promos_View_Var_Pop_Fields = {
  __typename?: 'title_promos_view_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "title_promos_view" */
export type Title_Promos_View_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Title_Promos_View_Var_Samp_Fields = {
  __typename?: 'title_promos_view_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "title_promos_view" */
export type Title_Promos_View_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Title_Promos_View_Variance_Fields = {
  __typename?: 'title_promos_view_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  expiration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "title_promos_view" */
export type Title_Promos_View_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
};

/** select columns of table "title" */
export enum Title_Select_Column {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

/** select "title_aggregate_bool_exp_bool_and_arguments_columns" columns of table "title" */
export enum Title_Select_Column_Title_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** select "title_aggregate_bool_exp_bool_or_arguments_columns" columns of table "title" */
export enum Title_Select_Column_Title_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  ShowWinner = 'show_winner'
}

/** input type for updating data in table "title" */
export type Title_Set_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Title_Stddev_Fields = {
  __typename?: 'title_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "title" */
export type Title_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Title_Stddev_Pop_Fields = {
  __typename?: 'title_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "title" */
export type Title_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Title_Stddev_Samp_Fields = {
  __typename?: 'title_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "title" */
export type Title_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "title" */
export type Title_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Title_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Title_Stream_Cursor_Value_Input = {
  adults_only?: InputMaybe<Scalars['Boolean']['input']>;
  default_sound_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_free?: InputMaybe<Scalars['Boolean']['input']>;
  last_played?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paywall_id?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  purchase_url?: InputMaybe<Scalars['String']['input']>;
  show_winner?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Title_Sum_Fields = {
  __typename?: 'title_sum_fields';
  position?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "title" */
export type Title_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "title" */
export enum Title_Update_Column {
  /** column name */
  AdultsOnly = 'adults_only',
  /** column name */
  DefaultSoundId = 'default_sound_id',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  IsFree = 'is_free',
  /** column name */
  LastPlayed = 'last_played',
  /** column name */
  Name = 'name',
  /** column name */
  PaywallId = 'paywall_id',
  /** column name */
  Position = 'position',
  /** column name */
  PurchaseUrl = 'purchase_url',
  /** column name */
  ShowWinner = 'show_winner',
  /** column name */
  Sku = 'sku',
  /** column name */
  Subtitle = 'subtitle'
}

export type Title_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Title_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Title_Set_Input>;
  /** filter the rows which have to be updated */
  where: Title_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Title_Var_Pop_Fields = {
  __typename?: 'title_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "title" */
export type Title_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Title_Var_Samp_Fields = {
  __typename?: 'title_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "title" */
export type Title_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Title_Variance_Fields = {
  __typename?: 'title_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "title" */
export type Title_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** columns and relationships of "track" */
export type Track = {
  __typename?: 'track';
  /** An array relationship */
  favorite_by: Array<Favorite>;
  /** An aggregate relationship */
  favorite_by_aggregate: Favorite_Aggregate;
  /** An object relationship */
  file: File;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** A computed field, executes function "track_is_favorite" */
  is_favorite?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  listenings: Array<Listening>;
  /** An aggregate relationship */
  listenings_aggregate: Listening_Aggregate;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['numeric']['output']>;
  positives?: Maybe<Array<Scalars['String']['output']>>;
  silence_end: Scalars['numeric']['output'];
  silence_start: Scalars['numeric']['output'];
  sound_muted: Scalars['Boolean']['output'];
  /** An object relationship */
  title: Title;
  title_id: Scalars['uuid']['output'];
};


/** columns and relationships of "track" */
export type TrackFavorite_ByArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackFavorite_By_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Favorite_Order_By>>;
  where?: InputMaybe<Favorite_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackListeningsArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackListenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Listening_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Listening_Order_By>>;
  where?: InputMaybe<Listening_Bool_Exp>;
};

/** aggregated selection of "track" */
export type Track_Aggregate = {
  __typename?: 'track_aggregate';
  aggregate?: Maybe<Track_Aggregate_Fields>;
  nodes: Array<Track>;
};

export type Track_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Track_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Track_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Track_Aggregate_Bool_Exp_Count>;
};

export type Track_Aggregate_Bool_Exp_Bool_And = {
  arguments: Track_Select_Column_Track_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Track_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Track_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Track_Select_Column_Track_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Track_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Track_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Track_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Track_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "track" */
export type Track_Aggregate_Fields = {
  __typename?: 'track_aggregate_fields';
  avg?: Maybe<Track_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Track_Max_Fields>;
  min?: Maybe<Track_Min_Fields>;
  stddev?: Maybe<Track_Stddev_Fields>;
  stddev_pop?: Maybe<Track_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Track_Stddev_Samp_Fields>;
  sum?: Maybe<Track_Sum_Fields>;
  var_pop?: Maybe<Track_Var_Pop_Fields>;
  var_samp?: Maybe<Track_Var_Samp_Fields>;
  variance?: Maybe<Track_Variance_Fields>;
};


/** aggregate fields of "track" */
export type Track_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Track_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "track" */
export type Track_Aggregate_Order_By = {
  avg?: InputMaybe<Track_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Track_Max_Order_By>;
  min?: InputMaybe<Track_Min_Order_By>;
  stddev?: InputMaybe<Track_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Track_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Track_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Track_Sum_Order_By>;
  var_pop?: InputMaybe<Track_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Track_Var_Samp_Order_By>;
  variance?: InputMaybe<Track_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "track" */
export type Track_Arr_Rel_Insert_Input = {
  data: Array<Track_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Track_On_Conflict>;
};

/** aggregate avg on columns */
export type Track_Avg_Fields = {
  __typename?: 'track_avg_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "track" */
export type Track_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "track". All fields are combined with a logical 'AND'. */
export type Track_Bool_Exp = {
  _and?: InputMaybe<Array<Track_Bool_Exp>>;
  _not?: InputMaybe<Track_Bool_Exp>;
  _or?: InputMaybe<Array<Track_Bool_Exp>>;
  favorite_by?: InputMaybe<Favorite_Bool_Exp>;
  favorite_by_aggregate?: InputMaybe<Favorite_Aggregate_Bool_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_favorite?: InputMaybe<Boolean_Comparison_Exp>;
  listen_count?: InputMaybe<Int_Comparison_Exp>;
  listenings?: InputMaybe<Listening_Bool_Exp>;
  listenings_aggregate?: InputMaybe<Listening_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Numeric_Comparison_Exp>;
  positives?: InputMaybe<String_Array_Comparison_Exp>;
  silence_end?: InputMaybe<Numeric_Comparison_Exp>;
  silence_start?: InputMaybe<Numeric_Comparison_Exp>;
  sound_muted?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<Title_Bool_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "track" */
export enum Track_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrackPkey = 'track_pkey'
}

/** input type for incrementing numeric columns in table "track" */
export type Track_Inc_Input = {
  position?: InputMaybe<Scalars['numeric']['input']>;
  silence_end?: InputMaybe<Scalars['numeric']['input']>;
  silence_start?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "track" */
export type Track_Insert_Input = {
  favorite_by?: InputMaybe<Favorite_Arr_Rel_Insert_Input>;
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  listenings?: InputMaybe<Listening_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  positives?: InputMaybe<Array<Scalars['String']['input']>>;
  silence_end?: InputMaybe<Scalars['numeric']['input']>;
  silence_start?: InputMaybe<Scalars['numeric']['input']>;
  sound_muted?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Title_Obj_Rel_Insert_Input>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Track_Max_Fields = {
  __typename?: 'track_max_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  positives?: Maybe<Array<Scalars['String']['output']>>;
  silence_end?: Maybe<Scalars['numeric']['output']>;
  silence_start?: Maybe<Scalars['numeric']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "track" */
export type Track_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  positives?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Track_Min_Fields = {
  __typename?: 'track_min_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  positives?: Maybe<Array<Scalars['String']['output']>>;
  silence_end?: Maybe<Scalars['numeric']['output']>;
  silence_start?: Maybe<Scalars['numeric']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "track" */
export type Track_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  positives?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "track" */
export type Track_Mutation_Response = {
  __typename?: 'track_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Track>;
};

/** input type for inserting object relation for remote table "track" */
export type Track_Obj_Rel_Insert_Input = {
  data: Track_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Track_On_Conflict>;
};

/** on_conflict condition type for table "track" */
export type Track_On_Conflict = {
  constraint: Track_Constraint;
  update_columns?: Array<Track_Update_Column>;
  where?: InputMaybe<Track_Bool_Exp>;
};

/** Ordering options when selecting data from "track". */
export type Track_Order_By = {
  favorite_by_aggregate?: InputMaybe<Favorite_Aggregate_Order_By>;
  file?: InputMaybe<File_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_favorite?: InputMaybe<Order_By>;
  listen_count?: InputMaybe<Order_By>;
  listenings_aggregate?: InputMaybe<Listening_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  positives?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
  sound_muted?: InputMaybe<Order_By>;
  title?: InputMaybe<Title_Order_By>;
  title_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: track */
export type Track_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "track" */
export enum Track_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Positives = 'positives',
  /** column name */
  SilenceEnd = 'silence_end',
  /** column name */
  SilenceStart = 'silence_start',
  /** column name */
  SoundMuted = 'sound_muted',
  /** column name */
  TitleId = 'title_id'
}

/** select "track_aggregate_bool_exp_bool_and_arguments_columns" columns of table "track" */
export enum Track_Select_Column_Track_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SoundMuted = 'sound_muted'
}

/** select "track_aggregate_bool_exp_bool_or_arguments_columns" columns of table "track" */
export enum Track_Select_Column_Track_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SoundMuted = 'sound_muted'
}

/** input type for updating data in table "track" */
export type Track_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  positives?: InputMaybe<Array<Scalars['String']['input']>>;
  silence_end?: InputMaybe<Scalars['numeric']['input']>;
  silence_start?: InputMaybe<Scalars['numeric']['input']>;
  sound_muted?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Track_Stddev_Fields = {
  __typename?: 'track_stddev_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "track" */
export type Track_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Track_Stddev_Pop_Fields = {
  __typename?: 'track_stddev_pop_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "track" */
export type Track_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Track_Stddev_Samp_Fields = {
  __typename?: 'track_stddev_samp_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "track" */
export type Track_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "track" */
export type Track_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Track_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Track_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['numeric']['input']>;
  positives?: InputMaybe<Array<Scalars['String']['input']>>;
  silence_end?: InputMaybe<Scalars['numeric']['input']>;
  silence_start?: InputMaybe<Scalars['numeric']['input']>;
  sound_muted?: InputMaybe<Scalars['Boolean']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Track_Sum_Fields = {
  __typename?: 'track_sum_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['numeric']['output']>;
  silence_end?: Maybe<Scalars['numeric']['output']>;
  silence_start?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "track" */
export type Track_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** update columns of table "track" */
export enum Track_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Positives = 'positives',
  /** column name */
  SilenceEnd = 'silence_end',
  /** column name */
  SilenceStart = 'silence_start',
  /** column name */
  SoundMuted = 'sound_muted',
  /** column name */
  TitleId = 'title_id'
}

export type Track_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Track_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Track_Set_Input>;
  /** filter the rows which have to be updated */
  where: Track_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Track_Var_Pop_Fields = {
  __typename?: 'track_var_pop_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "track" */
export type Track_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Track_Var_Samp_Fields = {
  __typename?: 'track_var_samp_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "track" */
export type Track_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Track_Variance_Fields = {
  __typename?: 'track_variance_fields';
  /** A computed field, executes function "track_listen_count" */
  listen_count?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  silence_end?: Maybe<Scalars['Float']['output']>;
  silence_start?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "track" */
export type Track_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
  silence_end?: InputMaybe<Order_By>;
  silence_start?: InputMaybe<Order_By>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  id: Scalars['uuid']['output'];
  password: Scalars['String']['output'];
  /** An array relationship */
  sessions: Array<Session>;
  /** An aggregate relationship */
  sessions_aggregate: Session_Aggregate;
  username: Scalars['String']['output'];
};


/** columns and relationships of "user" */
export type UserSessionsArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Session_Order_By>>;
  where?: InputMaybe<Session_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  sessions?: InputMaybe<Session_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "username" */
  UserUsernameUnique = 'user_username_unique'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<Session_Arr_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  sessions_aggregate?: InputMaybe<Session_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  Username = 'username'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "verification" */
export type Verification = {
  __typename?: 'verification';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['String']['output'];
  type?: Maybe<Verification_Type_Enum>;
  type_prisma: Scalars['verification_type_prisma']['output'];
  value: Scalars['String']['output'];
  /** An object relationship */
  verification_type?: Maybe<Verification_Type>;
};

/** aggregated selection of "verification" */
export type Verification_Aggregate = {
  __typename?: 'verification_aggregate';
  aggregate?: Maybe<Verification_Aggregate_Fields>;
  nodes: Array<Verification>;
};

export type Verification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Verification_Aggregate_Bool_Exp_Count>;
};

export type Verification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Verification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Verification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "verification" */
export type Verification_Aggregate_Fields = {
  __typename?: 'verification_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Verification_Max_Fields>;
  min?: Maybe<Verification_Min_Fields>;
};


/** aggregate fields of "verification" */
export type Verification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "verification" */
export type Verification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Verification_Max_Order_By>;
  min?: InputMaybe<Verification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "verification" */
export type Verification_Arr_Rel_Insert_Input = {
  data: Array<Verification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Verification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "verification". All fields are combined with a logical 'AND'. */
export type Verification_Bool_Exp = {
  _and?: InputMaybe<Array<Verification_Bool_Exp>>;
  _not?: InputMaybe<Verification_Bool_Exp>;
  _or?: InputMaybe<Array<Verification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Verification_Type_Enum_Comparison_Exp>;
  type_prisma?: InputMaybe<Verification_Type_Prisma_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  verification_type?: InputMaybe<Verification_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "verification" */
export enum Verification_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationPkey = 'verification_pkey'
}

/** input type for inserting data into table "verification" */
export type Verification_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Verification_Type_Enum>;
  type_prisma?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  verification_type?: InputMaybe<Verification_Type_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Verification_Max_Fields = {
  __typename?: 'verification_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  type_prisma?: Maybe<Scalars['verification_type_prisma']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "verification" */
export type Verification_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  type_prisma?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Verification_Min_Fields = {
  __typename?: 'verification_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  type_prisma?: Maybe<Scalars['verification_type_prisma']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "verification" */
export type Verification_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  type_prisma?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "verification" */
export type Verification_Mutation_Response = {
  __typename?: 'verification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Verification>;
};

/** on_conflict condition type for table "verification" */
export type Verification_On_Conflict = {
  constraint: Verification_Constraint;
  update_columns?: Array<Verification_Update_Column>;
  where?: InputMaybe<Verification_Bool_Exp>;
};

/** Ordering options when selecting data from "verification". */
export type Verification_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_prisma?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  verification_type?: InputMaybe<Verification_Type_Order_By>;
};

/** primary key columns input for table: verification */
export type Verification_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "verification" */
export enum Verification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypePrisma = 'type_prisma',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "verification" */
export type Verification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Verification_Type_Enum>;
  type_prisma?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "verification" */
export type Verification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verification_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Verification_Type_Enum>;
  type_prisma?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "verification_type" */
export type Verification_Type = {
  __typename?: 'verification_type';
  value: Scalars['String']['output'];
  /** An array relationship */
  verifications: Array<Verification>;
  /** An aggregate relationship */
  verifications_aggregate: Verification_Aggregate;
};


/** columns and relationships of "verification_type" */
export type Verification_TypeVerificationsArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};


/** columns and relationships of "verification_type" */
export type Verification_TypeVerifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Verification_Order_By>>;
  where?: InputMaybe<Verification_Bool_Exp>;
};

/** aggregated selection of "verification_type" */
export type Verification_Type_Aggregate = {
  __typename?: 'verification_type_aggregate';
  aggregate?: Maybe<Verification_Type_Aggregate_Fields>;
  nodes: Array<Verification_Type>;
};

/** aggregate fields of "verification_type" */
export type Verification_Type_Aggregate_Fields = {
  __typename?: 'verification_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Verification_Type_Max_Fields>;
  min?: Maybe<Verification_Type_Min_Fields>;
};


/** aggregate fields of "verification_type" */
export type Verification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "verification_type". All fields are combined with a logical 'AND'. */
export type Verification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Verification_Type_Bool_Exp>>;
  _not?: InputMaybe<Verification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Verification_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
  verifications?: InputMaybe<Verification_Bool_Exp>;
  verifications_aggregate?: InputMaybe<Verification_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "verification_type" */
export enum Verification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  VerificationTypePkey = 'verification_type_pkey'
}

export enum Verification_Type_Enum {
  Code = 'code'
}

/** Boolean expression to compare columns of type "verification_type_enum". All fields are combined with logical 'AND'. */
export type Verification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Verification_Type_Enum>;
  _in?: InputMaybe<Array<Verification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Verification_Type_Enum>;
  _nin?: InputMaybe<Array<Verification_Type_Enum>>;
};

/** input type for inserting data into table "verification_type" */
export type Verification_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
  verifications?: InputMaybe<Verification_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Verification_Type_Max_Fields = {
  __typename?: 'verification_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Verification_Type_Min_Fields = {
  __typename?: 'verification_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "verification_type" */
export type Verification_Type_Mutation_Response = {
  __typename?: 'verification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Verification_Type>;
};

/** input type for inserting object relation for remote table "verification_type" */
export type Verification_Type_Obj_Rel_Insert_Input = {
  data: Verification_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Verification_Type_On_Conflict>;
};

/** on_conflict condition type for table "verification_type" */
export type Verification_Type_On_Conflict = {
  constraint: Verification_Type_Constraint;
  update_columns?: Array<Verification_Type_Update_Column>;
  where?: InputMaybe<Verification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_type". */
export type Verification_Type_Order_By = {
  value?: InputMaybe<Order_By>;
  verifications_aggregate?: InputMaybe<Verification_Aggregate_Order_By>;
};

/** primary key columns input for table: verification_type */
export type Verification_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "verification_type_prisma". All fields are combined with logical 'AND'. */
export type Verification_Type_Prisma_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _gt?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _gte?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _in?: InputMaybe<Array<Scalars['verification_type_prisma']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _lte?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _neq?: InputMaybe<Scalars['verification_type_prisma']['input']>;
  _nin?: InputMaybe<Array<Scalars['verification_type_prisma']['input']>>;
};

/** select columns of table "verification_type" */
export enum Verification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "verification_type" */
export type Verification_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "verification_type" */
export type Verification_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verification_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "verification_type" */
export enum Verification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Verification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verification_Type_Bool_Exp;
};

/** update columns of table "verification" */
export enum Verification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypePrisma = 'type_prisma',
  /** column name */
  Value = 'value'
}

export type Verification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Verification_Bool_Exp;
};

export type AccessListFragment = { __typename?: 'access', description?: string | null, expiration?: number | null, expiration_date?: any | null, id: any, name: string, remind_after_subscription?: string | null, show_in_my_collection?: boolean | null, use_all_titles?: boolean | null, use_by?: any | null, subscription?: { __typename?: 'magenta_subscription', name: string } | null, promo?: { __typename?: 'promo', name: string } | null, in_app_purchase?: { __typename?: 'in_app_purchase', name: string } | null, activations: Array<{ __typename?: 'activation', id: any }>, titles: Array<{ __typename?: 'access_titles_view', id?: any | null }> };

export type AccessOneFragment = { __typename?: 'access', description?: string | null, expiration?: number | null, expiration_date?: any | null, id: any, name: string, remind_after_subscription?: string | null, show_in_my_collection?: boolean | null, use_all_titles?: boolean | null, use_by?: any | null, titles: Array<{ __typename?: 'access_titles_view', id?: any | null }> };

export type Update_Access_OverrideMutationVariables = Exact<{
  _set?: InputMaybe<Access_Set_Input>;
  pk_columns: Access_Pk_Columns_Input;
  access_title_delete_bool_exp: Access_Title_Bool_Exp;
  access_title_insert_inputs: Array<Access_Title_Insert_Input> | Access_Title_Insert_Input;
}>;


export type Update_Access_OverrideMutation = { __typename?: 'mutation_root', update_access_by_pk?: { __typename: 'access', description?: string | null, expiration?: number | null, expiration_date?: any | null, id: any, name: string, remind_after_subscription?: string | null, show_in_my_collection?: boolean | null, use_all_titles?: boolean | null, use_by?: any | null } | null, delete_access_title?: { __typename?: 'access_title_mutation_response', affected_rows: number } | null, insert_access_title?: { __typename?: 'access_title_mutation_response', affected_rows: number } | null };

export type ActivationListFragment = { __typename?: 'activation', access_id?: any | null, activated_at: any, code_id?: any | null, email?: string | null, expiration?: any | null, id: any, profile_id?: any | null, promo_id?: any | null, show_in_my_collection?: boolean | null, use_all_titles?: boolean | null, in_app_purchase_activation?: { __typename?: 'in_app_purchase_activation', id: any } | null, profile?: { __typename?: 'profile', email?: string | null, first_name?: string | null, last_name?: string | null } | null, access?: { __typename?: 'access', id: any } | null };

export type ActivationManyFragment = { __typename?: 'activation', access_id?: any | null, activated_at: any, code_id?: any | null, email?: string | null, expiration?: any | null, id: any, profile_id?: any | null, promo_id?: any | null, show_in_my_collection?: boolean | null, use_all_titles?: boolean | null, profile?: { __typename?: 'profile', email?: string | null } | null };

export type CollectionListFragment = { __typename?: 'collection', id: any, name: string, position?: any | null, color: string, titles: Array<{ __typename?: 'collection_titles_view', id?: any | null }> };

export type CollectionOneFragment = { __typename?: 'collection', id: any, name: string, position?: any | null, color: string, titles: Array<{ __typename?: 'collection_titles_view', id?: any | null }> };

export type Update_Collection_OverrideMutationVariables = Exact<{
  _set?: InputMaybe<Collection_Set_Input>;
  pk_columns: Collection_Pk_Columns_Input;
  collection_title_delete_bool_exp: Collection_Title_Bool_Exp;
  collection_title_insert_inputs: Array<Collection_Title_Insert_Input> | Collection_Title_Insert_Input;
}>;


export type Update_Collection_OverrideMutation = { __typename?: 'mutation_root', update_collection_by_pk?: { __typename: 'collection', id: any, color: string, name: string, position?: any | null, titles: Array<{ __typename?: 'collection_titles_view', id?: any | null }> } | null, delete_collection_title?: { __typename?: 'collection_title_mutation_response', affected_rows: number } | null, insert_collection_title?: { __typename?: 'collection_title_mutation_response', affected_rows: number } | null };

export type SoundOneFragment = { __typename?: 'sound', id: any, name: string, position?: any | null, file_id: any, file: { __typename?: 'file', id: any, name: string, path: string, mimetype: string, duration?: any | null, url: string } };

export type TitleListFragment = { __typename?: 'title', adults_only: boolean, default_sound_id?: any | null, description?: string | null, header?: string | null, id: any, is_free: boolean, last_played?: any | null, name: string, paywall_id?: string | null, position?: any | null, purchase_url?: string | null, show_winner: boolean, sku?: string | null, subtitle?: string | null, collections: Array<{ __typename?: 'title_collections_view', id?: any | null }> };

export type TitleOneFragment = { __typename?: 'title', adults_only: boolean, default_sound_id?: any | null, description?: string | null, header?: string | null, id: any, is_free: boolean, last_played?: any | null, name: string, paywall_id?: string | null, position?: any | null, purchase_url?: string | null, show_winner: boolean, sku?: string | null, subtitle?: string | null, collections: Array<{ __typename?: 'title_collections_view', id?: any | null }> };

export type Update_Title_OverrideMutationVariables = Exact<{
  _set?: InputMaybe<Title_Set_Input>;
  pk_columns: Title_Pk_Columns_Input;
  collection_title_delete_bool_exp: Collection_Title_Bool_Exp;
  collection_title_insert_inputs: Array<Collection_Title_Insert_Input> | Collection_Title_Insert_Input;
}>;


export type Update_Title_OverrideMutation = { __typename?: 'mutation_root', update_title_by_pk?: { __typename?: 'title', adults_only: boolean, default_sound_id?: any | null, description?: string | null, header?: string | null, id: any, is_free: boolean, last_played?: any | null, name: string, paywall_id?: string | null, position?: any | null, purchase_url?: string | null, show_winner: boolean, sku?: string | null, subtitle?: string | null, collections: Array<{ __typename?: 'title_collections_view', id?: any | null }> } | null, delete_collection_title?: { __typename?: 'collection_title_mutation_response', affected_rows: number } | null, insert_collection_title?: { __typename?: 'collection_title_mutation_response', affected_rows: number } | null };

export type TrackListFragment = { __typename?: 'track', file_id: any, id: any, name: string, position?: any | null, positives?: Array<string> | null, silence_end: any, silence_start: any, sound_muted: boolean, title_id: any, title: { __typename?: 'title', id: any, name: string } };

export type TrackOneFragment = { __typename?: 'track', file_id: any, id: any, name: string, position?: any | null, positives?: Array<string> | null, silence_end: any, silence_start: any, sound_muted: boolean, title_id: any, file: { __typename?: 'file', id: any, name: string, path: string, mimetype: string, duration?: any | null, url: string } };

export type UploadMutationVariables = Exact<{
  name: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  contentType?: InputMaybe<Scalars['String']['input']>;
}>;


export type UploadMutation = { __typename?: 'mutation_root', upload: { __typename?: 'UploadFilePayload', url: string, file: { __typename?: 'AdminFile', id: string, name: string, path: string, mimetype: string, duration?: number | null } } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login: { __typename?: 'AdminLoginPayload', token: string, user: { __typename?: 'AdminUser', id: string, username: string } } };

export const AccessListFragmentDoc = gql`
    fragment AccessList on access {
  description
  expiration
  expiration_date
  id
  name
  remind_after_subscription
  show_in_my_collection
  use_all_titles
  use_by
  subscription {
    name
  }
  promo {
    name
  }
  in_app_purchase {
    name
  }
  activations {
    id
  }
  titles {
    id
  }
}
    `;
export const AccessOneFragmentDoc = gql`
    fragment AccessOne on access {
  description
  expiration
  expiration_date
  id
  name
  remind_after_subscription
  show_in_my_collection
  use_all_titles
  use_by
  titles {
    id
  }
}
    `;
export const ActivationListFragmentDoc = gql`
    fragment ActivationList on activation {
  access_id
  activated_at
  code_id
  email
  expiration
  id
  profile_id
  promo_id
  show_in_my_collection
  use_all_titles
  in_app_purchase_activation {
    id
  }
  profile {
    email
    first_name
    last_name
  }
  access {
    id
  }
}
    `;
export const ActivationManyFragmentDoc = gql`
    fragment ActivationMany on activation {
  access_id
  activated_at
  code_id
  email
  expiration
  id
  profile_id
  promo_id
  show_in_my_collection
  use_all_titles
  profile {
    email
  }
}
    `;
export const CollectionListFragmentDoc = gql`
    fragment CollectionList on collection {
  id
  name
  position
  color
  titles {
    id
  }
}
    `;
export const CollectionOneFragmentDoc = gql`
    fragment CollectionOne on collection {
  id
  name
  position
  color
  titles {
    id
  }
}
    `;
export const SoundOneFragmentDoc = gql`
    fragment SoundOne on sound {
  id
  name
  position
  file_id
  file {
    id
    name
    path
    mimetype
    duration
    url
  }
}
    `;
export const TitleListFragmentDoc = gql`
    fragment TitleList on title {
  adults_only
  default_sound_id
  description
  header
  id
  is_free
  last_played
  name
  paywall_id
  position
  purchase_url
  show_winner
  sku
  subtitle
  collections {
    id
  }
}
    `;
export const TitleOneFragmentDoc = gql`
    fragment TitleOne on title {
  adults_only
  default_sound_id
  description
  header
  id
  is_free
  last_played
  name
  paywall_id
  position
  purchase_url
  show_winner
  sku
  subtitle
  collections {
    id
  }
}
    `;
export const TrackListFragmentDoc = gql`
    fragment TrackList on track {
  file_id
  id
  name
  position
  positives
  silence_end
  silence_start
  sound_muted
  title_id
  title {
    id
    name
  }
}
    `;
export const TrackOneFragmentDoc = gql`
    fragment TrackOne on track {
  file_id
  id
  name
  position
  positives
  silence_end
  silence_start
  sound_muted
  title_id
  file {
    id
    name
    path
    mimetype
    duration
    url
  }
}
    `;
export const Update_Access_OverrideDocument = gql`
    mutation update_access_override($_set: access_set_input, $pk_columns: access_pk_columns_input!, $access_title_delete_bool_exp: access_title_bool_exp!, $access_title_insert_inputs: [access_title_insert_input!]!) {
  update_access_by_pk(_set: $_set, pk_columns: $pk_columns) {
    description
    expiration
    expiration_date
    id
    name
    remind_after_subscription
    show_in_my_collection
    use_all_titles
    use_by
    __typename
  }
  delete_access_title(where: $access_title_delete_bool_exp) {
    affected_rows
  }
  insert_access_title(objects: $access_title_insert_inputs) {
    affected_rows
  }
}
    `;
export const Update_Collection_OverrideDocument = gql`
    mutation update_collection_override($_set: collection_set_input, $pk_columns: collection_pk_columns_input!, $collection_title_delete_bool_exp: collection_title_bool_exp!, $collection_title_insert_inputs: [collection_title_insert_input!]!) {
  update_collection_by_pk(_set: $_set, pk_columns: $pk_columns) {
    id
    color
    name
    position
    titles {
      id
    }
    __typename
  }
  delete_collection_title(where: $collection_title_delete_bool_exp) {
    affected_rows
  }
  insert_collection_title(objects: $collection_title_insert_inputs) {
    affected_rows
  }
}
    `;
export const Update_Title_OverrideDocument = gql`
    mutation update_title_override($_set: title_set_input, $pk_columns: title_pk_columns_input!, $collection_title_delete_bool_exp: collection_title_bool_exp!, $collection_title_insert_inputs: [collection_title_insert_input!]!) {
  update_title_by_pk(_set: $_set, pk_columns: $pk_columns) {
    adults_only
    default_sound_id
    description
    header
    id
    is_free
    last_played
    name
    paywall_id
    position
    purchase_url
    show_winner
    sku
    subtitle
    collections {
      id
    }
  }
  delete_collection_title(where: $collection_title_delete_bool_exp) {
    affected_rows
  }
  insert_collection_title(objects: $collection_title_insert_inputs) {
    affected_rows
  }
}
    `;
export const UploadDocument = gql`
    mutation upload($name: String!, $duration: Float!, $contentType: String) {
  upload(name: $name, duration: $duration, contentType: $contentType) {
    file {
      id
      name
      path
      mimetype
      duration
    }
    url
  }
}
    `;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    user {
      id
      username
    }
    token
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    update_access_override(variables: Update_Access_OverrideMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Update_Access_OverrideMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Access_OverrideMutation>(Update_Access_OverrideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_access_override', 'mutation', variables);
    },
    update_collection_override(variables: Update_Collection_OverrideMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Update_Collection_OverrideMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Collection_OverrideMutation>(Update_Collection_OverrideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_collection_override', 'mutation', variables);
    },
    update_title_override(variables: Update_Title_OverrideMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Update_Title_OverrideMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Update_Title_OverrideMutation>(Update_Title_OverrideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'update_title_override', 'mutation', variables);
    },
    upload(variables: UploadMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UploadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadMutation>(UploadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upload', 'mutation', variables);
    },
    login(variables: LoginMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;