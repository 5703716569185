import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Admin, Loading, Resource, radiantLightTheme } from 'react-admin';
import './App.css';
import useDataProvider from './hooks/useDataProvider';
import createAuthProvider from './utils/authProvider';
import client from './utils/client';
import resources from './resources';

const authProvider = createAuthProvider(client);

function App() {
  const dataProvider = useDataProvider();

  if (!dataProvider) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={client}>
      <Admin theme={radiantLightTheme} authProvider={authProvider} dataProvider={dataProvider}>
        {resources.map((props) => (
          <Resource key={props.name} {...props} />
        ))}
      </Admin>
    </ApolloProvider>
  );
}

export default App;
