import React from 'react';
import { Datagrid, DeleteButton, TextField } from 'react-admin';
import HNList from '../components/HNList';

export const ProfileList: React.FC<any> = (props) => (
  <HNList {...props}>
    <Datagrid>
      <TextField source="uid" label="UID" />
      <TextField source="firstName" label="First name" />
      <TextField source="lastName" label="Last name" />
      <TextField source="email" label="User email" />
      <DeleteButton />
    </Datagrid>
  </HNList>
);
