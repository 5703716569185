import { stringify } from 'query-string';
import React from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import { validateLastPlayed, validateName, validatePosition, validateUrl } from '../utils/validation';
import DateField from '../components/DateField';

const RelatedTracksButton: React.FC<any> = () => {
  const record = useRecordContext<any>();

  const to = {
    pathname: '/track',
    search: stringify({ page: 1, perPage: 25, filter: JSON.stringify({ title: record?.id }) }),
  };

  return <Button component={Link} to={to} label="Tracks"></Button>;
};

const TitleTitle: React.FC = () => {
  const record = useRecordContext<any>();

  return <span>{record && record.name ? `Title "${record.name}"` : 'Create Title'}</span>;
};

const TitleForm: React.FC<any> = ({ mode, ...props }) => (
  <HNForm {...props}>
    <TextInput label="Title Name" source="name" validate={validateName} />
    <BooleanInput label="Adults only" source="adults_only" />
    <BooleanInput label="Free" source="is_free" />
    <BooleanInput source="show_winner" />
    {mode === 'update' && (
      <ReferenceArrayInput label="Collections" source="collectionsIds" reference="collection">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    )}
    <ReferenceInput label="Default Sound" source="default_sound_id" reference="sound" perPage={10000} allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="position" label="Position" validate={validatePosition} />
    <TextInput source="purchase_url" validate={validateUrl} />
    <TextInput source="subtitle" />
    <TextInput source="sku" label="SKU" />
    <TextInput source="paywall_id" label="Paywall ID" />
    <DateTimeInput
      source="last_played"
      validate={validateLastPlayed}
      parse={(date?: Date) => (dayjs(date).isValid() ? dayjs(date).toDate() : undefined)}
    />
    <TextInput source="header" />
    <TextInput source="description" multiline fullWidth />
  </HNForm>
);

export const TitleList: React.FC<any> = (props) => (
  <HNList {...props} sort={{ field: 'position', order: 'ASC' }}>
    <Datagrid>
      <TextField label="Title Name" source="name" />
      <ReferenceArrayField label="Collections" source="collectionsIds" reference="collection">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="paywall_id" />
      <TextField source="position" />
      <DateField source="last_played" />
      <RelatedTracksButton />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

export const TitleCreate: React.FC<any> = (props) => (
  <Create title={<TitleTitle />} {...props}>
    <TitleForm />
  </Create>
);

export const TitleEdit: React.FC<any> = (props) => (
  <Edit title={<TitleTitle />} {...props}>
    <TitleForm mode="update" />
  </Edit>
);
